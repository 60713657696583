import React, {useState, useEffect} from 'react';
//import { Redirect, Route, useLocation, } from "react-router-dom";
import { Redirect, Route, useLocation, } from "react-router-dom";
import SiteBar from "Apps/Public/SiteBar/SiteBar";
import Home from "Apps/Public/Home/HomePage";
import Axios from "axios";
import Banner from 'bt_modules/Banner';
import Footer from 'Apps/Public/Footer/Footer';
import CategoryList from "Apps/Public/Category/CategoryListPage";
import SearchPage from "Apps/Public/Search/SearchPage";
import CoursePage from "Apps/Public/Course/CoursePage";
import ByCategory from 'Apps/Public/Category/ByCategory';
import CartPage from 'Apps/Public/Cart/CartPage';
import WishPage from 'Apps/Public/Wish/WishPage';
import MyCoursePage from 'Apps/Public/Mycourse/MyCoursePage';
import LearnPage from 'Apps/Public/Learn/LearnPage';
import Setting from 'Apps/Public/Setting/Setting'
import Profile from 'Apps/Public/Profile/Profile'
import Language from 'Apps/Public/Language/Language'
import TermsAndConditions from 'Apps/Public/Terms/TermsAndConditions';
import Verify from './Verify/Verify';
import FindAccount from './FindAccount/FindAccount';
import ResetPassword from './ResetPassword/ResetPassword';
import Loading from 'bt_modules/Loading';
import { useTranslation } from 'react-i18next';
import Pg1success from 'bt_modules/Purchase/PurchaseSuccess';
import Pg1Cancel from 'Apps/Public/Pg1cancel/Pg1CancelPage'
import Pg1fail from './Pg1fail/Pg1failPage';
import ContactUS from './ContactUS/ContactUS';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';

export default function Public() {
    const [accessToken, setAccessToken] = useState("");
    const location = useLocation();
    const [cartData, setCartData] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [wishList, setWishList] = useState([]);
    const [myCourseData, setMyCourseData] = useState([]);
    const [loading, setLoading] = useState(false)
    const { t, i18n } = useTranslation();
    const [userLanguage, setUserLanguage] = useState("")

    useEffect(()=> {
        let isMounted = true;
        const options = {
        url: window.$aaa + "/refresh1",
        method: "POST",
        withCredentials: true,
        headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
        },
        };
        Axios(options).then(response => {
            if (isMounted){
                let lang = localStorage.getItem("lang")
                if(lang){
                    localStorage.setItem("lang", i18n.language)
                    setUserLanguage(lang)
                }
                else{
                    setUserLanguage("")
                }
                if(response.data.status){
                    if(response.data.jid) {
                        setAccessToken(response.data.jid);
                    }else{
                        setAccessToken("");
                    }
                    if(response.data.userInfo) {
                        setUserInfo(response.data.userInfo);
                        i18n.changeLanguage(response.data.userInfo.locale)
                        localStorage.setItem("lang", response.data.userInfo.locale)
                        setUserLanguage(response.data.userInfo.locale)
                    }
                    if(response.data.cartInfo) {
                        setCartData(response.data.cartInfo);
                    }
                    if(response.data.wishInfo) {
                        setWishList(response.data.wishInfo);
                    }
                    if(response.data.myCourseInfo){
                        setMyCourseData(response.data.myCourseInfo)
                    }
                    setLoading(true)
                }else{
                    setLoading(true)
                    setAccessToken("");
                }
            }
        })
        return () => { isMounted = false };
    }, [i18n]);

    return (
        <div>
            {loading? 
                <div>
                    <div style={{ minHeight: 1200}}>
                        {location.pathname !== '/' && <SiteBar accessToken={accessToken} setAccessToken={setAccessToken} cartData={cartData} setCartData={setCartData} userInfo={userInfo} setUserInfo={setUserInfo} wishList={wishList} setWishList={setWishList} setMyCourseData={setMyCourseData} t={t} i18n={i18n}/>}
                        {location.pathname === '/home' && <Banner accessToken={accessToken} setAccessToken={setAccessToken} t={t} i18n={i18n}/>}
                        
                        <div>
                        {userLanguage !== "" ?
                            <div>
                                <Route path="/privacypolicy" component={(props)=> <PrivacyPolicy t={t} i18n={i18n} {...props}/>}/>
                                <Route path="/contactus" component={(props)=> <ContactUS t={t} i18n={i18n} {...props}/>}/>
                                <Route path="/pg1success/:txnId" component={(props)=> <Pg1success accessToken={accessToken} t={t} i18n={i18n} {...props}/>}/>
                                <Route path="/emailVerify/:jwttoken" component={(props)=> <Verify t={t} i18n={i18n} {...props}/>}/>
                                <Route path="/reset_password/:jwttoken" component={(props)=> <ResetPassword t={t} i18n={i18n} {...props}/>}/>
                                <Route path="/searchCourse/:keyWord" component={(props)=> <SearchPage t={t} i18n={i18n} {...props}/>}/>
                                <Route path="/course/:courseId" component={(props)=> <CoursePage accessToken={accessToken} cartData={cartData} setCartData={setCartData} wishList={wishList} setWishList={setWishList} myCourseData={myCourseData} setMyCourseData={setMyCourseData} userInfo={userInfo} t={t} i18n={i18n} {...props}/>} />
                                <Route path="/category/:catId" component={(props)=> <ByCategory t={t} i18n={i18n} {...props} />} />
                                <Route path="/learn/:courseId" component={(props)=> <LearnPage accessToken={accessToken} t={t} {...props} />} />
                                <Route exact path="/pg1cancel" component={(props)=> <Pg1Cancel t={t} {...props}/>}/>
                                <Route exact path="/pg1fail" component={(props)=> <Pg1fail t={t} {...props}/>}/>
                                <Route exact path="/profile" component={(props)=> <Profile userInfo={userInfo} accessToken={accessToken} t={t} {...props} />} />
                                <Route exact path="/settings" component={(props)=> <Setting userInfo={userInfo} accessToken={accessToken} t={t} {...props} />} />
                                <Route exact path="/mycourse" component={(props)=> <MyCoursePage myCourseData={myCourseData} setMyCourseData={setMyCourseData} accessToken={accessToken} t={t} {...props} />} />
                                <Route exact path="/wish" component={(props)=> <WishPage wishList={wishList} setWishList={setWishList} accessToken={accessToken} t={t} {...props} />} />
                                <Route exact path="/cart" component={(props)=> <CartPage cartData={cartData} setCartData={setCartData} accessToken={accessToken} userInfo={userInfo} t={t} i18n={i18n} {...props} />} />
                                <Route exact path="/category" component={()=> <CategoryList t={t} i18n={i18n}/>}/>
                                <Route exact path="/find_account" component={()=> <FindAccount t={t} i18n={i18n}/>}/>
                                <Route exact path="/terms_and_conditions" component={()=> <TermsAndConditions t={t} i18n={i18n}/>}/>
                                <Route exact path="/home" component={() => <Home accessToken={accessToken} setAccessToken={setAccessToken} t={t} i18n={i18n}/>}/>

                            </div>
                            :
                            <Redirect to={{pathname: "/"}}/>
                        }
                        </div>
                        <Route path="/" exact component={() => <Language accessToken={accessToken} userInfo={userInfo} i18n={i18n} t={t} userLanguage={userLanguage}/>}/>
                    </div>
                    <div>
                        {location.pathname !== '/' && <Footer t={t}/>}
                    </div>
                </div>
                :
                <div>
                    <Loading/>
                </div>
                }
        </div>
    );
}
