import React from 'react'
import { Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
      textAlign: "center",
      fontSize: "50px",
    },
    root: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 8
    },
    body1: {
        fontSize: "20px"
    },
    body2: {
        paddingLeft: "2%",
        fontSize: "20px"
    },
    subtitle: {
        fontSize: "25px"
    }
  }));

export default function Lao() {
    const classes = useStyles();

    return (
        <Container className={classes.root} maxWidth='xl' >
            <Typography className={classes.title} gutterBottom>
                Skillgener.com Payment Terms and Conditions
            </Typography>
            <Typography className={classes.body1} variant="body1" gutterBottom>
                When you make a payment to skillgener.com, you agree to the payment terms and conditions as below:
            </Typography>
            
            <Typography className={classes.subtitle} variant="body1" gutterBottom>
                1. Pricing
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • The price of content offered on skillgener.com website may not be exactly the same as the price offered on our mobile applications and price offered on different mobile platform providers may also be different, due to mobile platform provider’s pricing systems and their policies around implementing sales and promotions.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • The price of similar content in different languages may not be exactly the same price, due to there may be the cost of translation from original language to different languages.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • The price of content may be different according to the time of purchase, due to promotions or discount may be applied in some period of time.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • The price of content might be different based on the logged in user account, due to time of purchase, purchased volume, language and location setting of the user account.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • The price displayed in currency based on the language and location setting of the user account.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • If you are a student located in a country where use and sales tax, goods and services tax, or value added tax is applicable to consumer sales, we are responsible for collecting and remitting that tax to the proper tax authorities. Depending on your location, the price you see may include such taxes, or tax may be added at checkout.
            </Typography>
            
            <Typography className={classes.subtitle} variant="body1" gutterBottom>
                2. Payment
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • You agree to pay the fees for content that you purchase, and you authorize us to charge your debit or credit card or process other means of payment according to payment method setting in your account for those fees. We works with payment service providers to offer you the most convenient payment methods in your country and to keep your payment information secure.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • When you make a purchase, you agree not to use an invalid or unauthorized payment method. If your payment method fails and you still get access to the content you are enrolling in, you agree to pay us the corresponding fees within thirty (30) days of notification from us. We reserve the right to disable access to any content for which we have not received adequate payment.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • When you make a purchase in currency different from currency of your bank account or your cards, you agree to accept exchange rate from payment service provider who process the payment.
            </Typography>
            <Typography className={classes.subtitle} variant="body1" gutterBottom>
                3. Rights
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • All contents including but not limitted to images, videoes, files after uploaded to our system, are joint owned by content's owner and skillgener.com.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • After payment for goods has been completed, you'll have the right to access the content, any where, any time and forever.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • We reserve the rights to record, copy and re-distribute the content.
            </Typography>
            
            <Typography className={classes.subtitle} variant="body1" gutterBottom>
                4. Refunds
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • All payment to Skillgener.com are processed online by payment service provider which means the transactions occurred immediately after you press confirm button to purchase or to make payment, that’s why it’s very complex to refund. For that reason Skillgener.com at this stage has no refund policy, user need to go through detail of the course carefully before making purchase.
                We might consider refund policy again when we have enough information on the cost impact when perform refunding.
            </Typography>
        </Container>
    )
}
