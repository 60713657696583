import React, { useEffect, useState } from 'react'
import Axios from "axios";
import { Typography, Grid, makeStyles } from '@material-ui/core';
// import InstructorCourse from "bt_modules/InstructorCourse/InstructorCourse";
import Loading from 'bt_modules/Loading';
import CardListRow from "bt_modules/CardListRow/CardListRow";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '90%',
    },
  }));


export default function Course(props) {
    const classes = useStyles();
    const [courseInfo, setCourseInfo]  = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if(props.accessToken){
        let isMounted = true;
        const options = {
            url: window.$product + "/getInstructorCourse",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "token": props.accessToken,
                "status": 2
            }
        };
        Axios(options).then(response => {
            if(isMounted){
                if(response.data){
                    let courseInfo = []
                        response.data.map(course => (
                            courseInfo.push({
                                "id": course.id,
                                "name": course.name,
                                "img": course.img_id,
                                "price": course.price,
                                "promoPrice": course.promo_price,
                                "description": course.description
                            })
                        ));
                    setCourseInfo(courseInfo);
                    setLoading(false);
                }
            }
        })
        return () => { isMounted = false };
        }
    }, [props.accessToken])

    return (
        <div>
            {!loading ? 
            <div>
                {courseInfo.length > 0 ?
                    <Grid container className={classes.root}>
                        <CardListRow title={props.t("course.5")} cardsInfo={courseInfo} cardLink="/course/" textLen={270} t={props.t}/>
                    </Grid>
                    :
                    <Typography variant="h3" gutterBottom color="primary" style={{marginLeft: "40%"}}>
                        {props.t("course.10")}
                    </Typography>
                    }
            </div>
            :
            <Loading/>
            }
        </div>
    )
}
