import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import VideoUnpublish from './ContentVideoPlayer';
import { Container, Button } from '@material-ui/core';
import ReactPlayer from 'react-player';
//import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Axios from "axios";
import AlertConfirm from 'bt_modules/AlertDialog/AlertConfirm';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import LoadingImg from '../../Loading'
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
    card: {
        backgroundColor: '#E2E2E2',
        width: '100%'
    },
    cardFile: {
        backgroundColor: '#E2E2E2',
        width: '100%',
        padding: 2
    },
    rootFile: {
        margin: 2,
    },
    // loading: {
    //     position: "absolute",
    //     top: 0,
    //     bottom: 0,
    //     right: 0,
    //     left: 0,
    // },
});

export default function ContentUnpublish(props) {
    const classes = useStyles();
    const [openDialogVDOUnpublish, setOpenDialogVDOUnpublish] = useState(false);
    const [videoFile, setVideoFile] = useState();
    const [lesssonID, setLesssonID] = useState()
    const [disableButton, setDisableButton] = useState(false)
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleCancel = () => {
        setOpen(false);
        setLoading(false);
    }

    const OpenVDOUnpublish = () => {
        setOpenDialogVDOUnpublish(true);
    }

    const ViewPlay = (lesson_id, video) => {
        setVideoFile(video)
        setLesssonID(lesson_id)
        //props.setHasSectionID()
    }

    const DeleteVDO = (lesson_id, folder_id) => {
        if(folder_id){
            setDisableButton(true)
            setLoading(true);
            const options = {
                url: window.$product + "/deleteInstructorVdo",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "folder_id": folder_id,
                    "lesson_id": lesson_id,
                    "token": props.accessToken
                }
            };
            Axios(options).then(res => {
                if(res.data.status === 1){
                    const deleteVDO = props.courseUnpublishVDO.filter(deleted => deleted.id !== lesson_id);
                    props.setCourseUnpublishVDO(deleteVDO);
                    setLoading(false)
                    setOpen(false)
                    setDisableButton(false)
                }
            })
        }else{
            setOpen2(true)
            setLoading(false)
            setOpen(false)
        }
        

    }

    const DeleteFile = (resource_id, file_id) => {
        if(file_id){
            setDisableButton(true)
            setLoading(true);
            const options = {
                url: window.$product + "/deleteInstructorResource",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "file_id": file_id,
                    "resource_id": resource_id,
                    "token": props.accessToken
                }
            };
            Axios(options).then(res => {
                if(res.data.status === 1){
                    const deleteFile = props.courseUnpublishFile.filter(deleted => deleted.id !== resource_id);
                    props.setCourseUnpublishFile(deleteFile);
                    setLoading(false)
                    setOpen(false)
                    setDisableButton(false)
                }
            })
        }
        
    }

    const handleClick = () =>{
        setOpen2(false)
    }

    const hasSection = (arr, sectionID) => arr.some(e => e.section_id === sectionID);

    if (props.courseUnpublishVDO.length > 0 || props.courseUnpublishFile.length > 0) {
        if (hasSection(props.courseUnpublishVDO, props.sectionID) || hasSection(props.courseUnpublishFile, props.sectionID)) {
            return (
                <div>
                    <Container>
                        {props.courseUnpublishVDO.length > 0 &&
                            <div>
                                {props.courseUnpublishVDO.map((video) => (
                                    <div key={video.id}>
                                        {video.section_id === props.sectionID &&
                                            <div>
                                                {!video.cloud_folder_id ?
                                                    <div>
                                                        <Card className={classes.card}>
                                                            <Grid container>
                                                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                                                    <Button style={{ textTransform: 'none' }}>
                                                                        <Container>
                                                                            <div style={{ padding: 4, textAlign: 'center', marginLeft: '4%', marginTop: '1%' }}>
                                                                                <Typography component="h6" variant="subtitle2" color="primary">
                                                                                    {props.t("lesson.3")}
                                                                                </Typography>
                                                                            </div>
                                                                        </Container>
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={8} lg={8} style={{padding: 10 }}>
                                                                    <CardActionArea className={classes.rootFile}>
                                                                        <Typography component="h5" variant="h6" color="primary" style={{ marginTop: '-1%' }}>
                                                                            {video.name}
                                                                        </Typography>
                                                                        <Typography component="h6" variant="subtitle2" color="primary">
                                                                            {video.duration}
                                                                        </Typography>
                                                                    </CardActionArea>
                                                                </Grid>

                                                                {props.editable &&
                                                                    <Grid item md={1} sm={12} lg={1} style={{padding: 10 }}>
                                                                        <Button>
                                                                            <DeleteIcon style={{ fontSize: '150%' }} onClick={() => { handleOpen() }} />
                                                                        </Button>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Card><br/>
                                                    </div>
                                                    :
                                                    <div>
                                                        <Card className={classes.card}>
                                                            <Grid container>
                                                                <Grid item xs={12} sm={12} md={3} lg={3} >
                                                                    <Button style={{ textTransform: 'none' }} onClick={() => { ViewPlay(video.id, video.cloud_name); OpenVDOUnpublish(); }}>
                                                                        <Container>
                                                                            <div style={{ padding: 4, textAlign: 'center', marginLeft: '4%', marginTop: '1%' }}>
                                                                                {video.cloud_name && <ReactPlayer width="100%" height="100%" url={window.$playback + '/viewInstructorVideo/' + props.accessToken + "/" + video.id + "/" + video.cloud_name} />}
                                                                            </div>
                                                                        </Container>
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={8} lg={8} style={{  padding: 10 }}>
                                                                    <CardActionArea className={classes.rootFile} onClick={() => { ViewPlay(video.id, video.cloud_name); OpenVDOUnpublish(); }}>
                                                                        <Typography component="h5" variant="h6" color="primary" style={{ marginTop: '-1%' }}>
                                                                            {video.name}
                                                                        </Typography>
                                                                        <Typography component="h6" variant="subtitle2" color="primary">
                                                                            {video.duration}
                                                                        </Typography>
                                                                    </CardActionArea>
                                                                </Grid>

                                                                {props.editable &&
                                                                    <Grid item md={1} sm={12} lg={1} style={{  padding: 10 }}>
                                                                        <Button>
                                                                            <DeleteIcon style={{ fontSize: '150%' }} onClick={() => { handleOpen() }} />
                                                                        </Button>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Card><br />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <AlertConfirm open={open} handleConfirm={() => { DeleteVDO(video.id, video.cloud_folder_id) }} loadingImg={<LoadingImg />} loading={loading} disableButton={disableButton} handleCancel={handleCancel} text={props.t("delete.2")} button1={props.t("ok.1")} button2={props.t("cancel.1")} />
                                    </div>
                                ))}
                            </div>
                        }

                        {props.courseUnpublishFile.length > 0 &&
                            <div>
                                <br/>
                                {props.courseUnpublishFile.map((file) => (
                                    <div key={file.id} style={{ padding: 2 }}>
                                        {file.section_id === props.sectionID &&
                                            <div>
                                                <Card className={classes.cardFile}>
                                                    <Grid container>
                                                        <Grid item xs={10} sm={10} md={11} lg={11}>
                                                            <CardActionArea style={{ padding: 15 }}>
                                                                <Typography>{file.orig_filename}</Typography>
                                                            </CardActionArea>
                                                        </Grid>
                                                        <Grid item xs={2} sm={1} md={1} lg={1}>
                                                            {props.editable && <Button>
                                                                <DeleteIcon style={{ fontSize: '200%' }} onClick={()=> {handleOpen()}} />
                                                            </Button>}
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </div>
                                        }
                                        <AlertConfirm open={open} handleConfirm={() => { DeleteFile(file.id, file.cloud_id) }} loadingImg={<LoadingImg />} loading={loading} disableButton={disableButton} handleCancel={handleCancel} text={props.t("delete.2")} button1={props.t("ok.1")} button2={props.t("cancel.1")} />
                                    </div>
                                ))}
                                <br />
                            </div>
                        }
                    </Container>
                    {/* {loading &&
                        <div className={classes.loading}>
                            <LoadingImg />
                        </div>
                    } */}
                    <AlertDialog open={open2} handleClick={handleClick} text={props.t("delete.4")} button={props.t("ok.1")}/>
                    <VideoUnpublish openDialogVDOUnpublish={openDialogVDOUnpublish} setOpenDialogVDOUnpublish={setOpenDialogVDOUnpublish} setVideoFile={setVideoFile} videoFile={videoFile} lesssonID={lesssonID} accessToken={props.accessToken}/>
                </div>
            )
        } else {
            return (
                <div>
                    {props.t("info.5")}
                </div>
            )
        }
    } else {
        return (
            <div>
                {props.t("info.5")}
            </div>
        )
    }
}