import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Lao from 'ImgLib/Flag/Lao.png'
import English from 'ImgLib/Flag/English.png'
import Thai from 'ImgLib/Flag/Thai.png'
import Vietnamese from 'ImgLib/Flag/Vietnamese.png'
import {Redirect} from "react-router-dom";
import Axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Language(props) {
    const classes = useStyles();

    const handleLanguage = (lan) => {
        if(props.accessToken){
            const options = {
                url: window.$aaa + "/updateLocale",
                method: "POST",
                headers:{
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data:{
                    "token": props.accessToken,
                    "locale": lan
                }
            };
            Axios(options).then(response => {
                if(response.data.status){
                    localStorage.setItem("lang", lan)
                    props.i18n.changeLanguage(lan)
                    window.location = '/home'
                }
            })
        }else{
            localStorage.setItem("lang", lan)
            props.i18n.changeLanguage(lan)
            window.location = '/home'
        }
    }

    return (
        <div className={classes.root}>
            {!props.userLanguage ?
            <Grid container style={{ marginTop: "15%"}}>
                <Grid item xs={3} >
                    <div style={{textAlign: "center"}}>
                        <img src={English} alt="" style={{width: "50%", cursor: "pointer"}} onClick={()=> handleLanguage("en")}/>
                        <Typography variant="h4">English</Typography>
                    </div>
                </Grid>
                <Grid item xs={3} >
                <div style={{textAlign: "center"}}>
                    <img src={Thai} alt=""style={{width: "50%", cursor: "pointer"}} onClick={()=> handleLanguage("th")}/>
                    <Typography variant="h4">ภาษาไทย</Typography>
                    </div>
                </Grid>
                <Grid item xs={3} >
                <div style={{textAlign: "center"}}>
                    <img src={Vietnamese} alt=""style={{width: "50%", cursor: "pointer"}} onClick={()=> handleLanguage("vn")}/>
                    <Typography variant="h4">Tiếng Việt</Typography>
                </div>
                </Grid>
                <Grid item xs={3} >
                    <div style={{textAlign: "center"}}>
                        <img src={Lao} alt="" style={{width: "50%", cursor: "pointer"}} onClick={()=> handleLanguage("la")}/>
                        <Typography variant="h4">ພາສາລາວ</Typography>
                    </div>
                </Grid>
            </Grid>
            :
            <div>
                <Redirect
                    to={{
                    pathname: "/home",
                    }}
                />
            </div>
            }
        </div>
    )
}
