import React from 'react'
import { Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
      textAlign: "center",
      fontSize: "50px",
      //padding: "2%"
    },
    root: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 8
    },
    body1: {
        fontSize: "20px"
    },
    body2: {
        paddingLeft: "2%",
        fontSize: "20px"
    },
    subtitle: {
        fontSize: "25px"
    }
  }));

export default function Lao() {
    const classes = useStyles();

    return (
        <Container className={classes.root} ><br/>
            <Typography className={classes.title} gutterBottom>
                ກົດລະບຽບ, ເງື່ອນໄຂ ແລະ ນະໂຍບາຍ ຂອງ skillgener.com
            </Typography>
            <Typography className={classes.body1} variant="body1" gutterBottom>
                ເມື່ອທ່ານດໍາເນີນການຊໍາລະຄ່າສິນຄ້າຫຼືບໍລິການຂອງພວກເຮົາ ແມ່ນຖືວ່າທ່ານຍອມຮັບ ເງື່ອນໄຂ ແລະ ກົດລະບຽບ ດັ່ງລຸ່ມນີ້:
            </Typography>
            
            <Typography className={classes.subtitle} variant="body1" gutterBottom>
                1. ລາຄາ
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ຫຼັກສູດຂອງ skillgener.com ທີ່ສາມາດເຂົ້າເຖິງໄດ້ຫຼາຍຊ່ອງທາງ ອາດມີລາຄາທີ່ບໍ່ເທົ່າກັນ ຍົກຕົວຢ່າງເຊັ່ນ: ສັ່ງຊື້ຫຼັກສູດໃນແອັບມືຖື ອາດມີລາຄາສູງກວ່າໃນເວັບໄຊ້ ຫຼື ສັ່ງຊື້ຫຼັກສູດໃນແອັບມືຖືຄ້າຍ A ອາດມີລາຄາສູງກວ່າຄ້າຍ B ເຊິ່ງຂຶ້ນກັບໂຄງສ້າງລາຄາ ທີ່ພວກເຮົາອາດຕ້ອງໄດ້ຈ່າຍໃຫ້ກັບບັນດາຄ້າຍອຸປະກອນນັ້ນໆ.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ລາຄາຂອງຫຼັກສູດທີ່ມີເນື້ອໃນຄ້າຍຄືກັນ ແຕ່ເປັນໃນພາສາຕ່າງກັນອາດມີລາຄາບໍ່ເທົ່າກັນ ເນື່ອງຈາກອາດມີມູນຄ່າຕົ້ນທຶນເພີ່ມເຕີມໃນການແປຈາກຫຼັກສູດຕົ້ນສະບັບໄປເປັນພາສາອື່ນ.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ລາຄາຂອງຫຼັກສູດໃນແຕ່ລະຊ່ວງເວລາອາດບໍ່ເທົ່າເດີມ ເພາະພວກເຮົາອາດມີການເຮັດໂປຼໂມຊັນ, ມອບສ່ວນຫຼຸດ ຫຼື ມີກິດຈະກໍາອື່ນໆ ເພື່ອສະໜັບສະໜຸນການຂາຍເປັນໄລຍະ.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ລາຄາທີ່ລະບົບສະແດງໃຫ້ແຕ່ລະບັນຊືຜູ້ໃຊ້ ອາດບໍ່ເທົ່າກັນ ເຊິ່ງອາດຂຶ້ນກັບຊ່ວງເວລາໃນການສັ່ງຊື້, ຍອດສັ່ງຊື້, ການຕັ້ງຄ່າທາງພາສາ ແລະ ທາງທີ່ຕັ້ງ ຂອງບັນຊີຜູ້ໃຊ້ນັ້ນໆ.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ການສະແດງລາຄາແມ່ນສະແດງເປັນສະກຸນເງິນ ຕາມການຕັ້ງຄ່າທາງທີ່ຕັ້ງຂອງບັນຊີຜູ້ໃຊ້.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ການຕັ້ງຄ່າທາງທີ່ຕັ້ງຂອງບັນຊີຜູ້ໃຊ້ ແມ່ນມີຜົນກັບການລວມພາສີ/ອາກອນເຂົ້າໃນລາຄາທີ່ສະແດງ ເຊິ່ງອີງຕາມກົດໝາຍ ພາສີ/ອາກອນ ຂອງແຕ່ລະທີ່ຕັ້ງ ເຊັ່ນ: ບາງທີ່ຕັ້ງ ພາສີ/ອາກອນ ອາດຖືກສະແດງລວມກັບລາຄາຫຼັກສູດເລີຍ ຫຼື ບາງທີ່ຕັ້ງອາດຕ້ອງສະແດງຕອນດໍາເນີນການຊໍາລະ.
            </Typography>
            
            <Typography className={classes.subtitle} variant="body1" gutterBottom>
                2. ການຊໍາລະ
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ທ່ານເຫັນດີໃນການຊໍາລະຄ່າຫຼັກສູດ ທີ່ທ່ານສັ່ງຊື້ ແລະ ທ່ານອະນຸຍາດໃຫ້ພວກເຮົາດໍາເນີນການ ຕັດເງິນຈາກບັນຊີທະນາຄານ ຫຼື ບັດເຄຼດິດ ຫຼື ບັດເດບິດ ຫຼື ຈາກຊ່ອງການຊໍາລະອື່ນໆ ທີ່ທ່ານໄດ້ຕັ້ງຄ່າໄວ້ໃນບັນຊີຜູ້ໃຊ້ຂອງທ່ານ ຕາມຈໍານວນການສັ່ງຊື້ນັ້ນໆ.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ພວກເຮົາໄດ້ປະສານກັບບັນດາຜູ້ໃຫ້ບໍລິການຊໍາລະສະສາງ ໃນຂອບເຂດທີ່ຕັ້ງຂອງທ່ານ ເພື່ອສະໜອງຊ່ອງທາງການຊໍາລະທີ່ສະດວກສໍາລັບທ່ານ ແລະ ເກັບຮັກສາຂໍ້ມູນທຸລະກໍາຂອງທ່ານ ໃຫ້ປອດໄພ.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ເມື່ອທ່ານດໍາເນີນການຊໍາລະ ທ່ານເຫັນດີທີ່ຈະບໍ່ນໍາໃຊ້ຊ່ອງທ່ານການຊໍາລະທີ່ບໍໄດ້ຮັບອະນຸຍາດ ແລະ ຜິດຕໍ່ລະບຽບກົດໝາຍ.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ເມື່ອການດໍາເນີນການຊໍາລະຂອງທ່ານບໍ່ສໍາເລັດ ຫຼື ເປັນການຊໍາລະທີ່ບໍ່ຖືກລະບຽບກົດໝາຍ ແຕ່ທ່ານໄດ້ຮັບສິດໃນການເຂົ້າເຖິງຫຼັກສູດຂອງພວກເຮົາແລ້ວ, ພວກເຮົາຂໍສະຫງວນສິດ ໃນການຖອນສິດການເຂົ້າເຖິງນັ້ນ.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ເມື່ອທ່ານຊໍາລະຄ່າຫຼັກສູດໃນສະກຸນເງິນ ທີ່ບໍ່ຕົງກັບສະກຸນເງິນຂອງ ບັນຊີທະນາຄານ ຫຼື ຊ່ອງທ່ານຊໍາລະອື່ນໆຂອງທ່ານ ແມ່ນທ່ານຍອມຮັບອັດຕາແລກປ່ຽນຂອງຜູ້ໃຫ້ບໍລິການຊໍາລະສະສາງ ທີ່ກ່ຽວຂ້ອງກັບທຸລະກໍານັ້ນໆ.
            </Typography>

            <Typography className={classes.subtitle} variant="body1" gutterBottom>
                3. ສິດ
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ເນື້ອຫາຕ່າງໆ ບໍ່ວ່າຈະເປັນໃນຮູບແບບ ຮູບພາບ,​ ວີດີໂອ,​ ຟາຍເອກະສານ ໆລໆ, ເມື່ອຖືກອັບໂຫຼດຂຶ້ນເທິງລະບົບພວກເຮົາແລ້ວ ແມ່ນຖືວ່າເປັນກໍາມະສິດຮ່ວມລະຫວ່າງເຈົ້າຂອງເນື້ອຫາ ແລະ skillgener.com
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ຫຼັງຈາກທີ່ທ່ານໄດ້ຊໍາລະຄ່າສິນຄ້າແລ້ວ ທ່ານຈະໄດ້ຮັບສິດໃນການເຂົ້າເຖິງສິນຄ້ານັ້ນ ເຊິ່ງທ່ານສາມາດເຂົ້ານໍາໄດ້ທຸກທີ່ທຸກເວລາ ແລະ ຕະຫຼອດໄປ.
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ພວກເຮົາຂໍສະຫງວນສິດ ໃນການບັນທືກ, ສໍາເນົາ ແລະ ເຜີຍແຜ່ ເນື້ອຫາຂອງສິນຄ້າ ໃຫ້ກັບບຸກຄົນອື່ນ.
            </Typography>
            
            <Typography className={classes.subtitle} variant="body1" gutterBottom>
                4. ການສົ່ງເງິນຄືນ
            </Typography>
            <Typography className={classes.body2} variant="body1" gutterBottom>
                • ເນື່ອງຈາກທຸລະກໍາທັງໝົດຜ່ານລະບົບຂອງ skillgener.com ແມ່ນເຊື່ອມຕໍ່ແບບອອນລາຍ ກັບບັນດາຜູ້ໃຫ້ບໍລິການຊໍາລະສະສາງ ເຊິ່ງໝາຍຄວາມວ່າທຸລະກໍາຈະເກີດຂຶ້ນທັນທີທັນໃດ ຫຼັງຈາກທີ່ທ່ານກົດຢືນຢັນການຊໍາລະ ດັ່ງນັນຈິງເປັນການຍາກຕໍ່ການສົ່ງເງິນຄືນ. ດັ່ງນັ້ນ skillgener.com ຈິງບໍ່ມີນະໂຍບາຍໃນການສົ່ງເງິນຄືນ ຜູ້ໃຊ້ຈໍາເປັນຕ້ອງສຶກສາລາຍລະອຽດ ຂອງຫຼັກສູດໃຫ້ດີກ່ອນສັ່ງຊື້ ແລະ ຊໍາລະຄ່າຫຼັກສູດ.
                    ໃນອານາຄົດເມື່ອພວກເຮົາມີປະສົບການ ຮູ້ໄດ້ເຖິງມູນຄ່າຂອງຜົນກະທົບໃນການສົ່ງເງິນຄືນໄດ້ ກໍອາດພິດຈາລະນານະໂຍບາຍນີ້ຄືນໃໝ່.
            </Typography><br/>
        </Container>
    )
}
