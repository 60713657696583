import React from "react";
import { makeStyles, Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core";
import ReactImageAppear from 'react-image-appear';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 180,
        borderRadius: 10,
        backgroundColor: theme.palette.secondary.light,
    },
    media: {
        maxHeight: 100,
        objectFit: "contain",
        width: "100%",
        backgroundColor: theme.palette.secondary.light,
        padding: 10,
    },
    content: {
        textAlign: "center",
        backgroundColor: theme.palette.secondary.light,
        //color: "black",
        paddingBottom: 40,
        height: 80
    },
}));

export default function Category(props) {
    const classes = useStyles();

    return (
        <div>
            <NavLink  to={"/category/"+props.iconInfo.id} style={{textDecoration: "none"}} key={props.iconInfo.id}>
                <Card className={classes.card} >
                    <CardActionArea>
                        <CardMedia>
                        <ReactImageAppear 
                        className={classes.media}
                        src={"./Icon/" + props.iconInfo.cat_img}
                        animation="zoomIn"
                        />
                        </CardMedia>
                        <CardContent className={classes.content}>
                            <Typography>
                                {props.t(props.iconInfo.cat_name + ".1")}
                            </Typography>
                            <Typography>
                                ({props.iconInfo.countCourse})
                            </Typography>
                        </CardContent>
                    </CardActionArea >
                </Card>
            </NavLink>
        </div>
    );
}