import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button} from '@material-ui/core';
import {Typography, Grid} from '@material-ui/core';
import Popover from 'material-ui-popup-state/HoverPopover'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import { NavLink } from 'react-router-dom';
import CircleIcon from "bt_modules/CircleIcon/CircleIcon";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
        margin: theme.spacing(0),
        },
        cursor: "pointer",
    },
    image: {
        width: '30%',
        [theme.breakpoints.up('sm')]: {
            width: '110%', 
        },
    }
}));

export default function CourseListPopUp(props) {
    const classes = useStyles();

    return (
        <PopupState variant="popover" popupId="demoPopover">
            {popupState => (
                <div >
                    <div className={classes.root} {...bindHover(popupState)} >
                        <NavLink to={props.linkTo} style={{textDecoration: "none"}}>
                            {props.iconImg ? 
                                <CircleIcon iconImg={props.iconImg} badge={props.popupData.length} />
                                :
                                <CircleIcon badge={props.popupData.length} />
                            }
                        </NavLink>
                    </div>
                    <Popover {...bindPopover(popupState)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}} transformOrigin={{ vertical: 'top', horizontal: 'center'}} disableRestoreFocus>
                        <div style={{ maxWidth: 300, maxHeight: 350, minWidth: 250, marginTop: 5, marginBottom: 5, margin: 10 }}>
                            {props.popupData.length > 0 ?
                                props.popupData.map((popup)=>(
                                    <Grid container spacing={3} key={popup.id} style={{maxHeight: 200}}>
                                        <Grid item xs={12} sm={4}>
                                            <div>
                                                <img alt="#" src={window.$product + "/getCourseImg/"+popup.img_id} className={classes.image}/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <div >
                                                <Typography variant="caption" >{`${String(popup.name).substring(0, 30)}`}{String(popup.name).length > 30 && '...'}</Typography><br/>
                                                <Typography variant="caption">{popup.publisher}</Typography><br/>
                                                <Typography variant="caption">{Number(popup.price).toLocaleString()} {props.t(popup.currency)}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                ))
                                :
                                <Typography style={{textAlign: 'center'}}> {props.emptyText} </Typography>
                            }
                            <hr/>
                            <NavLink to={props.linkTo} style={{color:"#fff", margin:10, textDecoration: "none"}} onClick={()=> {popupState.close()}}>
                                <Button variant="contained" color="primary" style={{ width: '85%', height: '10%', margin: 10}}>
                                    {props.goToText}
                                </Button>
                            </NavLink>
                        </div>
                    </Popover>
                </div>
            )}
        </PopupState>
    )
}