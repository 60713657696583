import React from 'react';
import {Dialog, Button, DialogActions,  Grid, Typography, makeStyles, DialogTitle} from '@material-ui/core';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        top: 50,
        backgroundColor: '#C0C0C0',
    }
});

export default function AlertDialog(props) {
    const classes = useStyles();

return (
    <div>
        <Dialog
            open={props.open}
            classes={{
                paper: classes.dialog
            }}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title"/>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" style={{marginBottom: '2%', textAlign: 'center'}}>
                        {props.text}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions style={{ justifyContent: 'center'}}>
                        <Button onClick={props.handleClick} variant="contained" color="primary" autoFocus style={{ marginBottom: '3%'}} >
                            {props.button}
                        </Button>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    </div>
);
}
