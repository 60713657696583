import React from 'react';
import { IconButton , makeStyles, Grid, Paper, Typography, Button} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router";
import NumberFormat from 'react-number-format';
import ImageUpload from "./ImageUpload"
import CreateCourseContent from './ContentUpload/CreateCourseContent'
import EditIcon from '@material-ui/icons/Edit';
import Dialog from './Dialog';
import ShowMoreText from 'react-show-more-text';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginLeft: "5%"
    },
    paper: {
        padding: theme.spacing(1),
        margin: '1%',
        maxWidth: "90%",
    },
    image: {
        backgroundColor: theme.palette.secondary.light,
        color: "#817C7B",
        padding: theme.spacing(1)
    },
    img: {
        margin: 'auto',
        display: 'block',
        width: '100%'
    },
    description: {
        padding: theme.spacing(1)
    },
    textColor: {
        color: theme.palette.secondary.light,
        marginTop:'0.5%'
    },
    titleStyle: {
        padding: '0.5%',
        paddingLeft: '1%',
        maxWidth: '92%', 
        fontWeight: 'lighter', 
        backgroundColor: theme.palette.primary.main
    }
}));

function ManageProduct(props) {
    const classes = useStyles();

    if(props.item.length > 0 ){
        return (
            <div className={classes.root}>
                <div className={classes.titleStyle}>
                    <Typography variant="h5" gutterBottom className={classes.textColor}>{props.title}</Typography>
                </div>
                
                <Paper className={classes.paper}>
                    {props.item.map((row) => (
                        <Grid container key={row.id} justifyContent="space-between">
                            <Grid item xs={5} sm={3} md={3} lg={2} align='center' className={classes.image} >
                                {row.img_id ? 
                                        <div>
                                            {row.img_id && <img className={classes.img} alt="complex" src={window.$product + "/getCourseImg/" +  row.img_id} />}
                                        </div>
                                        :
                                        <div>
                                            <Typography >
                                                {props.t("course.12")}
                                            </Typography>
                                            <Typography >
                                                {props.t("size.1")}
                                            </Typography>
                                            <Typography >
                                                140px x 160px
                                            </Typography>
                                        </div>
                                }
                                {props.editable && <ImageUpload courseID={props.match.params.id} img_id={row.img_id} uploadTitle={props.t("profile.1")} accessToken={props.accessToken} t={props.t}/>}
                            </Grid>
                            <Grid item container xs={6} sm={8} md={8} lg={9} direction="column" className={classes.description}>
                                <Grid item>
                                    <Typography variant="h5">
                                        {props.t("course.8")}: {row.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {row.price > 0 ? 
                                        <NumberFormat value={row.price} displayType={'text'} thousandSeparator={true} suffix={' ກີບ'} renderText={value => <div>ລາຄາ:  {value}</div>} /> 
                                    :
                                        <Typography onClick={props.handleClickOpen}>
                                            {props.t("price.2")}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item>
                                    <Typography >
                                        {props.t("description.1")}: 
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <ShowMoreText lines={5} more={""} less={""}>
                                        {ReactHtmlParser(row.description)}
                                    </ShowMoreText>
                                </Grid>
                            </Grid>
                            <Grid item container xs={1} sm={1} md={1} lg={1} justifyContent="flex-end">
                                <Grid item>
                                    {props.editable && 
                                        <IconButton onClick={()=> props.setOpen(true)}>
                                            <EditIcon/>
                                        </IconButton>
                                    }
                                </Grid>
                                
                            </Grid>        
                        </Grid>
                    ))}
                </Paper>
                
                <CreateCourseContent 
                    courseID={props.match.params.id} 
                    addNewSection={props.addNewSection} 
                    setAddNewSection={props.setAddNewSection}
                    videoList={props.videoList}
                    setVideoList={props.setVideoList}
                    fileList={props.fileList}
                    setFileList={props.setFileList}
                    editable={props.editable}
                    accessToken={props.accessToken}
                    t={props.t}
                />
                <br/>
                    <NavLink to="/instructor/unpublished_course" style={{ textDecoration: "none" }} >
                        <Button variant="contained" color="primary" >
                            {props.submitButton}
                        </Button>
                    </NavLink>
                <br/><br/>
                {props.editable && <Dialog open={props.open} handleEdit={props.handleEdit} handleChange={props.handleChange} handleClose={props.handleClose} edit={props.edit} setEdit={props.setEdit} t={props.t}/>}
            </div>
        );
    }else{
        return (
            <div>
                <Typography variant="h3" gutterBottom>{props.t("info.5")}</Typography>
            </div>
        );
    }
}

export default withRouter(ManageProduct)





