import React, { useEffect, useState } from 'react';
import InfoCard from "bt_modules/Dashboard/InfoCard";
import InfoCardWallet from "bt_modules/Dashboard/InfoCardWallet";
import GridContainer from "bt_modules/Dashboard/Grid/GridContainer.js";
import GridItem from "bt_modules/Dashboard/Grid/GridItem.js";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TodayIcon from '@material-ui/icons/Today';
import NumberFormat from 'react-number-format';
import Axios from "axios";
import Loading from 'bt_modules/Loading';
import { Grid } from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import GroupIcon from '@material-ui/icons/Group';


export default function Dashboard(props) {
    const [courseCount, setCourseCount] = useState();
    const [studentCount, setStudentCount] = useState();
    const [loading, setLoading] = useState(false)
    const [sumPriceYesterday, setSumPriceYesterday] = useState()
    const [sumPriceToday, setSumPriceToday] = useState()
    const [sumPriceMonth, setSumPriceMonth] = useState()

    useEffect(() => {
        if (props.accessToken) {
            let isMounted = true;
            const options = {
                url: window.$report + "/getAllInstructorStats",
                method: "POST",
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    token: props.accessToken
                }
            };
            Axios(options).then(response => {
                if (isMounted) {
                    setCourseCount(response.data.course)
                    setStudentCount(response.data.student)
                    setSumPriceYesterday(response.data.sumPriceYesterday)
                    setSumPriceToday(response.data.sumPriceToday)
                    setSumPriceMonth(response.data.sumPriceMonth)
                    setLoading(false);
                }
            })
            return () => { isMounted = false };
        }
    }, [props.accessToken])
  
    return (
        <div>
            {!loading ?
                <div style={{padding: '2%'}}>
                    <Grid style={{ marginRight: "5%", padding: '2%' , backgroundColor: '#B4B4B4', borderRadius: 8}}>
                    <div style={{ marginBottom: '2%', padding: '0.5%', paddingLeft: '2%'}}/>
                        <GridContainer >
                            <GridItem xs={12} sm={12} md={12}>
                                <InfoCardWallet
                                    withdraw="withdraw"
                                    color="success"
                                    title={"ຍອດເງິນຄົງເຫຼືອ"}
                                    value={<NumberFormat value={props.userInfo.wallet} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("currencies.1")} renderText={value => <div>{value.toLocaleString()}</div>} />}
                                    icon={<AccountBalanceWalletIcon />}
                                    link={"/instructor/withdraw"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="danger"
                                    title={"ຍອດຂາຍມື້ວານນີ້"}
                                    value={<NumberFormat value={sumPriceYesterday} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("currencies.1")} renderText={value => <div>{value.toLocaleString()}</div>} />}
                                    icon={<CalendarTodayIcon />}
                                    link={"/instructor/yesterdaySales"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="rose"
                                    title={"ຍອດຂາຍມື້ນີ້"}
                                    value={<NumberFormat value={sumPriceToday} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("currencies.1")} renderText={value => <div>{value.toLocaleString()}</div>} />}
                                    icon={<TodayIcon />}
                                    link={"/instructor/todaySales"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="warning"
                                    title={"ຍອດຂາຍພາຍໃນເດືອນນີ້"}
                                    value={<NumberFormat value={sumPriceMonth} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("currencies.1")} renderText={value => <div>{value.toLocaleString()}</div>} />}
                                    icon={<DateRangeIcon />}
                                    link={"/instructor/monthySales"}
                                    t={props.t}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer >
                            <GridItem xs={12} sm={6} md={6}>
                                <InfoCard 
                                    color="info"
                                    title={props.t("course.3")}
                                    value={<NumberFormat value={courseCount} displayType={'text'} thousandSeparator={true} suffix={' '+props.t("course.3")} renderText={value => <div>{value}</div>} />}
                                    icon={<MenuBookIcon />}
                                    link={"/instructor/published_course"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <InfoCard 
                                    color="primary"
                                    title={props.t("student.1")}
                                    value={<NumberFormat value={studentCount} displayType={'text'} thousandSeparator={true} suffix={' '+ props.t("person.1")} renderText={value => <div>{value}</div>} />}
                                    icon={<GroupIcon />}
                                    link={"/instructor"}
                                    t={props.t}
                                />
                            </GridItem>
                        </GridContainer>
                    </Grid>
                </div>
                :
                <Loading />
            }
        </div>
    )
}