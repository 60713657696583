import React, { useState } from 'react';
import {Container, Typography, Grid, makeStyles, Button, Tooltip, Toolbar } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import NumberFormat from 'react-number-format';
import Axios from 'axios';
import {Favorite} from '@material-ui/icons';
import ShareIconList from 'bt_modules/PopupItemList/ShareIconList';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import AlertDialog2 from 'bt_modules/AlertDialog/AlertDialog';
import AlertDialog3 from 'bt_modules/AlertDialog/AlertDialog';
import ShowMoreText from 'react-show-more-text';
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.secondary.light,
        marginTop: '0.4%'
    },
    
    button: {
        marginTop: '1%',
        width: '50%',
        //marginLeft: '50%'
    },
    descArea: {
        fontSize: '120%'
    },
    discount: {
        textDecoration: "line-through", 
        //color: '#797D7F', 
        color: theme.palette.secondary.dark,
        minWidth: '10', 
        display: 'flex', 
        justifyContent: 'center'
    },
    butonCart: {
        //color: theme.palette.background.default
        backgroundColor: theme.palette.background.default,
    },
    wishListColor: {
        color: theme.palette.secondary.dark
    },
    wishListedColor: {
        color: theme.palette.error.main
    }
}));

export default function ProductActionsPanel(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)
    const [cartMesg, setCartMesg] = useState("")
    const [wishMesg, setWishMesg] = useState("")
    
    const handleClick = () => {
        setOpen(false)
    }

    const handleClick2 = () => {
        setOpen2(false)
    }

    const handleClick3 = () => {
        setOpen3(false)
    }

    const handleCart = () => {
        if(props.accessToken){
            const options = {
                url: window.$product + "/addToCart",
                method: "POST",
                headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
                },
                data:{
                    "course_id" : props.productId,
                    "token": props.accessToken,
                }
            };
            Axios(options).then((res)=>{
                if(res.data.status === 1) {
                    props.setCartData(res.data.cartInfo);
                }else if(res.data.status === 2){
                    setCartMesg(props.t("cart.5"));
                    setOpen2(true)
                }else{
                    setCartMesg(props.t("error.1"));
                    setOpen2(true)
                }
            });
        }else{
            setOpen(true)
        }
    }
   
    const handleWishList = () => {
        if(props.accessToken){
            const options = {
                url: window.$product + "/addToWishList",
                method: "POST",
                headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
                },
                data:{
                    "course_id" : props.productId,
                    "token": props.accessToken
                }
            };
            Axios(options).then((res)=>{
                if(res.data.status === 1) {
                    props.setWishList(res.data.wishInfo);
                }else if(res.data.status === 2){
                    setWishMesg(props.t("wishlist.8"));
                }else{
                    setWishMesg(props.t("error.1"));
                }
            });
        }else{
            setOpen(true)
        }
    }

    const CheckWishList = (id) => {
        if(props.wishList.length > 0) {
            return props.wishList.some(wish => Number(id) === Number(wish.id));
        }else{
            return false;
        }
        
    }

    const CheckCart = (id) => {
        if(props.cartData.length > 0) {
            return props.cartData.some(cart => Number(id) === Number(cart.id));
        }else{
            return false;
        }
        
    }

    const CheckMyCourse = (id) => {
        if(props.myCourseData.length > 0){
            return props.myCourseData.some(mycourse => Number(id) === Number(mycourse.id));
        }else{
            return false;
        }
    }

    const handleBuynow = () => {
        if(props.accessToken){
            props.setRenderComp("pay");
        }else{
            setOpen(true)
        }
        
    }

    return (
        <div>
            <Grid className={classes.root} >
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={9} style={{ marginTop: '5%'}}>
                            <Typography variant="h5" gutterBottom>
                                {props.productName}
                            </Typography>
                            <ShowMoreText className={classes.descArea} lines={3} more={""} less={""}>
                                {ReactHtmlParser(props.productDescription)}
                            </ShowMoreText>
                            <div style={{display: "flex",marginTop: 25 }}>
                                <Typography variant="subtitle1" color="primary">{props.productSellerText} : &nbsp;{props.productSellerName}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={3} style={{marginTop: 30}} align='center'>
                            <div>
                                <div>
                                {props.productPromoPrice > 0 &&
                                    <Typography variant="h5" className={classes.discount}>
                                        <NumberFormat value={props.productPrice} displayType={'text'} thousandSeparator={true} suffix={' '+ props.t(props.productCurrency)} renderText={value => <div>{value}</div>} />
                                    </Typography>
                                }
                                </div>
                                
                                <div>
                                    {props.productPromoPrice > 0 ?
                                        <Typography variant="h5" style={{color: '#FF0000', minWidth: '10', display: 'flex', justifyContent: 'center'}}>
                                            <LocalOfferIcon style={{marginTop: '2%'}}/> <NumberFormat value={props.productPromoPrice} displayType={'text'} thousandSeparator={true} suffix={' '+ props.t(props.productCurrency)} renderText={value => <div>{value}</div>} />
                                        </Typography>
                                        :
                                        <Typography variant="h5" style={{color: '#FF0000', minWidth: '10', display: 'flex', justifyContent: 'center'}}>
                                            <LocalOfferIcon style={{marginTop: '2%'}}/> <NumberFormat value={props.productPrice} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t(props.productCurrency)} renderText={value => <div>{value}</div>} />
                                        </Typography>
                                    }
                                </div>

                                <div>
                                    {CheckMyCourse(props.productId) ? 
                                        <div className={classes.button} >
                                            <NavLink to={"/learn/"+props.productId} style={{ textDecoration: "none"}}>
                                                <Button variant="contained" fullWidth color="primary" >
                                                    {props.t("course.2")}
                                                </Button>
                                            </NavLink>
                                        </div>
                                        :
                                        <div className={classes.button} >
                                            {!CheckCart(props.productId) ?
                                            <div>
                                                <Button variant="contained" className={classes.butonCartHas} fullWidth onClick={()=> handleCart()}>
                                                    {props.t("cart.4")}
                                                </Button>
                                            </div>
                                            :
                                            <Button disabled variant="contained" fullWidth>
                                                {props.t("cart.5")}
                                            </Button>
                                            }
                                        </div>
                                        }
                                    <div className={classes.button}>
                                        {!CheckMyCourse(props.productId) &&
                                            <Button variant="contained" color="primary" fullWidth onClick={handleBuynow} >
                                                {props.t("buy.1")}
                                            </Button>
                                            // :
                                            // <Button variant="contained" color="secondary" fullWidth >
                                            //     {props.t("share.1")}
                                            // </Button>
                                        }
                                    </div>
                                </div>
                            </div> 
                        </Grid>
                    </Grid><br/><br/>
                    <Toolbar>
                        {CheckWishList(props.productId) ? 
                            <Tooltip title={<Typography>{props.t("wishlist.7")}</Typography>}>
                                <Favorite className={classes.wishListedColor} fontSize='large' onClick={()=>props.delWishLish(props.productId)}/>
                            </Tooltip>
                            :
                            <Tooltip title={<Typography>{props.t("wishlist.6")}</Typography>}>
                                <Favorite fontSize='large' onClick={()=>handleWishList()} className={classes.wishListColor} />
                            </Tooltip>
                        }
                        &nbsp;&nbsp;&nbsp;<ShareIconList shareLink={"https://www.skillgener.com/course/"+props.productId} />
                    </Toolbar>
                </Container><br/>
            </Grid>
            <AlertDialog open={open} handleClick={handleClick} text={props.t('login.4')} button={props.t('ok.1')}/>
            <AlertDialog2 open={open2} handleClick={handleClick2} text={cartMesg} button={props.t('ok.1')}/>
            <AlertDialog3 open={open3} handleClick={handleClick3} text={wishMesg} button={props.t('ok.1')}/>
        </div>
    )
}
