import React from 'react';
import Popover from 'material-ui-popup-state/HoverPopover';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import Logout from "./LogOut";
import { MenuItem, Avatar, Box, Typography, Divider, ListItemIcon } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactImageAppear from 'react-image-appear';
import { AllInbox, Dashboard, Settings, Autorenew, Person } from '@material-ui/icons';
import CircleIcon from "bt_modules/CircleIcon/CircleIcon";

const useStyles = makeStyles((theme) => ({
    cart: {
        display: 'flex',
    },
    img: {
        height: "100%",
        width: "100%"
    },
}));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        minWidth: 280,
        '&:focus': {
            backgroundColor: theme.palette.secondary.dark,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function ProfileMenu(props) {
    const classes = useStyles();

    return (
        <PopupState variant="popover" popupId="demoPopover">
        {popupState => (
            <div >
                <div className={classes.cart} {...bindHover(popupState)}>
                    {props.imgID ?
                        <CircleIcon iconImg={<ReactImageAppear className={classes.img} src={window.$aaa + "/getUserImg/" + props.accessToken +'/'+ props.imgID} animation="zoomIn"/>}/>
                    :
                        <CircleIcon iconImg={<Person />} />
                    } 
                </div>
                <Popover {...bindPopover(popupState)} anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} transformOrigin={{vertical: 'top', horizontal: 'center'}} disableRestoreFocus>
                    <div style={{ margin: 10, maxWidth: 250, maxHeight: 350 }}>
                        <NavLink to={"/profile"} style={{ color: "#fff", textDecoration: "none", minWidth: 150 }}>
                            <StyledMenuItem style={{maxHeight:100}}>
                                <ListItemIcon>
                                    {props.imgID ? 
                                    <Avatar alt="#" src={window.$aaa + "/getUserImg/" + props.accessToken +'/'+ props.imgID} />
                                    :
                                    <Avatar >
                                        {props.firstname.charAt(0)}
                                    </Avatar>
                                }
                                </ListItemIcon>
                                <Box container="true"  alignItems="center" justifyContent="flex-start" direction="row">
                                    <Typography color="textPrimary" gutterBottom variant="subtitle1">
                                        {props.firstname}
                                    </Typography>
                                    <Typography color="textPrimary" gutterBottom variant="body2">
                                        {props.email}
                                    </Typography>
                                </Box>
                            </StyledMenuItem>
                        </NavLink>
                        <NavLink to={"/instructor"}  style={{textDecoration: "none" }} onClick={()=> {popupState.close()}}>
                            <StyledMenuItem>
                                <Dashboard color="primary" style={{marginRight: "20"}} />
                                <Typography variant="body2" color="primary">{props.t("home.2")}</Typography>
                            </StyledMenuItem>
                        </NavLink>
                        <Divider />
                        <NavLink to={"/instructor/unpublished_course"}  style={{textDecoration: "none" }} onClick={()=> {popupState.close()}}>
                            <StyledMenuItem>
                                <Autorenew color="primary" style={{marginRight: "20"}} />
                                <Typography variant="body2" color="primary">{props.t("course.16")}</Typography>
                            </StyledMenuItem>
                        </NavLink>
                        <Divider />
                        <NavLink to={"/instructor/published_course"}  style={{textDecoration: "none" }} onClick={()=> {popupState.close()}}>
                            <StyledMenuItem>
                                <AllInbox color="primary" style={{marginRight: "20"}} />
                                <Typography variant="body2" color="primary">{props.t("course.5")}</Typography>
                            </StyledMenuItem>
                        </NavLink>
                        <Divider />
                        <NavLink to={"/settings"}  style={{textDecoration: "none" }} onClick={()=> {popupState.close()}}>
                            <StyledMenuItem>
                                <Settings color="primary" style={{marginRight: "20"}} />
                                <Typography variant="body2" color="primary">{props.t("setting.1")}</Typography>
                            </StyledMenuItem>
                        </NavLink>
                        <Divider />
                        <StyledMenuItem>
                            <Logout t={props.t} popupState={popupState}/>
                        </StyledMenuItem>
                    </div>
                </Popover>
            </div>
        )}
    </PopupState>
    )
}


