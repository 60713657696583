import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import ProfileMenu from "bt_modules/ProfileMenu/ProfileMenu";
import PopupItemList from "bt_modules/PopupItemList/PopupItemList";
import CircleIcon from "bt_modules/CircleIcon/CircleIcon";
import { ShoppingCartOutlined, Favorite as FavoriteIcon, CategoryOutlined, ShopTwoOutlined, VideoCallOutlined, Home } from '@material-ui/icons';
import Auth from 'bt_modules/Authen/Auth';
import Search from 'bt_modules/Search/Search.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  SiteTitleWrap: {
    display: "flex",
    flexDirection: "row",
  },
  SiteTitle: {
    width: "100%",
    marginRight: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  Home: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  sectionCategory: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

export default function Navbar(props) {
  const classes = useStyles();

  return (
    <div>
      <AppBar position="static">
        <Toolbar>

          <NavLink to={"/home"} style={{ textDecoration: "none" }}>
            {props.imgPath ?
              <div className={classes.SiteTitleWrap}>
                <img src={props.imgPath} alt="" className={classes.SiteTitle} />
              </div>
              :
              <div className={classes.SiteTitleWrap}>
                <h1 className={classes.SiteTitle} >{props.t("title.1")}</h1>
              </div>
            }
          </NavLink>

          <Search placeHolder={props.t("Searching") + "..."} width={"35%"} searchRoute={"/searchCourseName"} searchUrl="/searchCourse/" selectUrl="/course/" t={props.t} i18n={props.i18n} />
          
          <div className={classes.Home}><CircleIcon iconImg={<Home />} linkTo="/home" /></div>

          <div className={classes.sectionCategory}>
            <CircleIcon iconImg={<CategoryOutlined color="primary"/>} tooltip={props.t("Categories.1")} linkTo="/category" />
          </div>

          <div  className={classes.root} />
          
          <div className={classes.sectionDesktop}>
            {props.accessToken && <CircleIcon iconImg={<VideoCallOutlined color="primary" />} tooltip={props.t("Instructor.1")} linkTo="/instructor" />}
            {props.accessToken && <CircleIcon iconImg={<ShopTwoOutlined color="primary" />} tooltip={props.t("Mycourse.1")} linkTo="/mycourse" />}
            {props.accessToken && <PopupItemList popupData={props.cartData} iconImg={<ShoppingCartOutlined color="primary" />} linkTo={"/cart"} goToText={props.t("cart.1")} emptyText={props.t("cart.2")} t={props.t} />}
            {props.accessToken && <PopupItemList popupData={props.wishList} iconImg={<FavoriteIcon color="primary" />} linkTo={"/wish"} goToText={props.t("wishlist.1")} emptyText={props.t("wishlist.2")} t={props.t} />}
          </div>

          {!props.accessToken && <Auth
            setAccessToken={props.setAccessToken}
            logo={window.$img + "/logo.png"}
            loginRoute="/login"
            signupRoute="/signup"
            setUserInfo={props.setUserInfo}
            setWishList={props.setWishList}
            setCartData={props.setCartData}
            setMyCourseData={props.setMyCourseData}
            i18n={props.i18n}
            t={props.t}
          />}

          {props.accessToken && <ProfileMenu
            accessToken={props.accessToken}
            setAccessToken={props.setAccessToken}
            setCartData={props.setCartData}
            userFirstname={props.userFirstname}
            userImg={props.userImg}
            userEmail={props.userEmail}
            setUserInfo={props.setUserInfo}
            setWishList={props.setWishList}
            t={props.t}
          />}

        </Toolbar>
      </AppBar>
    </div>
  );
}
