import React, { useEffect, useState } from 'react';
import InfoCard from "bt_modules/Dashboard/InfoCard";
import GridContainer from "bt_modules/Dashboard/Grid/GridContainer.js";
import GridItem from "bt_modules/Dashboard/Grid/GridItem.js";
import {MenuBook, Today, Receipt, People, Face, Category, AccountBalanceWallet} from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import Axios from "axios";
import Loading from 'bt_modules/Loading';
import { Grid } from '@material-ui/core';

export default function Dashboard(props) {
    const [adminCourse, setAdminCourse] = useState()
    const [yesIncome, setYesIncome] = useState()
    const [monIncome, setMonIncome] = useState()
    const [member, setMember] = useState()
    const [instructor, setInstructor] = useState()
    const [category, setCategory] = useState()
    const [countWithdraw, setCountWithdraw] = useState(0)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;
        const options = {
            url: window.$report + "/getAllAdminStats",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                token: props.accessToken
            }
        };
        Axios(options).then(response => {
            if (isMounted) {
                if(response.data){
                    if(response.data.course){
                        setAdminCourse(response.data.course)
                    }
                    if(response.data.yesIncome){
                        let yesIncomeInfo = [];
                        response.data.yesIncome.forEach((data) => {
                            if(data.promo_price > 0){
                                yesIncomeInfo.push({
                                    price: data.promo_price
                                })
                            }else{
                                yesIncomeInfo.push({
                                    price: data.price
                                })
                            }
                            }
                        )
                        let yesSum = yesIncomeInfo.reduce((prev,next)=> Number(prev) + Number(next.price), 0 )
                        setYesIncome(yesSum)
                    }
                    if(response.data.monIncome){
                        let monIncomeInfo = [];
                        response.data.monIncome.forEach((data) => {
                            if(data.promo_price > 0){
                                monIncomeInfo.push({
                                    price: data.promo_price
                                })
                            }else{
                                monIncomeInfo.push({
                                    price: data.price
                                })
                            }
                            }
                        )
                        let monSum = monIncomeInfo.reduce((prev,next)=> Number(prev) + Number(next.price), 0 )
                        setMonIncome(monSum)
                    }
                    if(response.data.member){
                        setMember(response.data.member)
                    }
                    if(response.data.instructor){
                        setInstructor(response.data.instructor)
                    }
                    if(response.data.cat){
                        setCategory(response.data.cat)
                    }
                    if (response.data.countWithdraw){
                        setCountWithdraw(response.data.countWithdraw)
                    }
                    setLoading(false);
                }
            }
        })
        return () => { isMounted = false };
    }, [props.accessToken])

    return (
        <div>
            {!loading ?
                <div>
                    <Grid >
                        <GridContainer >
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="success"
                                    title={props.t("ຄຳຮ້ອງຂໍຖອນເງິນ")}
                                    value={<NumberFormat value={countWithdraw || 0} displayType={'text'} thousandSeparator={true} suffix={' ຄຳຮ້ອງ'} renderText={value => <div>{value}</div>} />}
                                    icon={<AccountBalanceWallet />}
                                    link={"/admin/approvewithdraw"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="danger"
                                    title={props.t("course.13")}
                                    value={<NumberFormat value={adminCourse || 0} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("course.3")} renderText={value => <div>{value}</div>} />}
                                    icon={<MenuBook />}
                                    link={"/admin/approvecourse"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="rose"
                                    title={props.t("ລາຍຮັບມື້ວານ")}
                                    value={<NumberFormat value={yesIncome || 0} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("currencies.1")} renderText={value => <div>{value}</div>} />}
                                    icon={<Receipt />}
                                    link={"/admin"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="warning"
                                    title={props.t("ລາຍຮັບເດືອນນີ້")}
                                    value={<NumberFormat value={monIncome || 0} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("currencies.1")} renderText={value => <div>{value}</div>} />}
                                    icon={<Today />}
                                    link={"/admin"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="primary"
                                    title={props.t("ຈຳນວນສະມາຊິກ")}
                                    value={<NumberFormat value={member || 0} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("person.1")} renderText={value => <div>{value}</div>} />}
                                    icon={<People />}
                                    link={"/admin"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="success"
                                    title={props.t("ຈຳນວນອາຈານ")}
                                    value={<NumberFormat value={instructor || 0} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("person.1")} renderText={value => <div>{value}</div>} />}
                                    icon={<Face />}
                                    link={"/admin"}
                                    t={props.t}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <InfoCard
                                    color="info"
                                    title={props.t("ເພີ່ມໝວດໝູ່")}
                                    value={<NumberFormat value={category || 0} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("ໝວດໝູ່")} renderText={value => <div>{value}</div>} />}
                                    icon={<Category />}
                                    link={"/admin"}
                                    t={props.t}
                                />
                            </GridItem>
                            
                        </GridContainer>
                    </Grid>
                </div>
                :
                <Loading />
            }
        </div>
    )
}