import React, {useState} from 'react';
import { makeStyles, Grid, Paper, Typography, IconButton, Tooltip, Button } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { NavLink } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {Delete as DeleteIcon, Edit as EditIcon} from '@material-ui/icons';
import ShowMoreText from 'react-show-more-text';
import Axios from "axios";
import AlertDialog2 from 'bt_modules/AlertDialog/AlertDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    paper: {
        padding: 20,
        spacing: 20
    },
    image: {
        marginRight: 40,
    },
    img: {
        //margin: 'auto',
        //display: 'block',
        //maxWidth: '100%',
        //maxHeight: '100%',
        width: '100%',
        height: '100%',
        marginLeft: '17%'
    },
    icon: {
        paddingLeft: 40,
    },
    bgBlur: {
        position: 'absolute',
        width: '77%', 
        marginTop: '-6.7%', 
        borderRadius: 8, 
        height: '14%',
        boxShadow: '0 15px 25px rgba(129, 124, 124, 0.2)',
        background: 'rgba(255,255,255,0.5)',
        backdropFilter: 'blur(1px)',
        marginLeft: '-1.5%'
    }
}));

export default function InstructorCourseCard(props) {
    const classes = useStyles();
    const [message, setMessage] = useState("");
    const [open3, setOpen3] = useState(false);

    const handleClick3 = () => {
        setOpen3(false)
        const options = {
            url: window.$product + "/getInstructorCourse",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "token": props.accessToken,
                "status": 0
            }
        };
        Axios(options).then(response => {
                if (response.data) {
                    props.setCourseInfo(response.data);
                }
        })
    }

    const handleSubmit = (courseID) => {
        const options = {
            url: window.$product + "/submitCourse",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "course_id": courseID,
                "token": props.accessToken
            }
        };
        Axios(options).then(response => {
            if(response.data.status){
                setMessage(props.t("success.2"))
                setOpen3(true)
            }
        })
    }

    return (
        <div className={classes.root}>
            {props.courseInfo.map(row => (
                <div key={row.id}>
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={2} lg={2} align='center'>
                                <ButtonBase className={classes.image}>
                                    {row.img_id && <img className={classes.img} alt="complex" src={window.$product + "/getCourseImg/" +  row.img_id} />}
                                </ButtonBase>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} container direction="column">
                                <Grid item>
                                    <Typography gutterBottom variant="subtitle1">
                                        {row.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography component={'span'} variant="body2" color="textSecondary">
                                        {props.t("description.1")}:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <ShowMoreText lines={3} more={""} less={""}>
                                            {ReactHtmlParser(row.description)}
                                    </ShowMoreText>
                                </Grid>
                            </Grid>

                            {props.status === 1 ? 
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} align='center' className={classes.bgBlur}>
                                    <Typography variant="h6" style={{marginTop: '4%'}}>{props.t("unpublish.3")}</Typography>
                                </Grid>
                            </Grid>
                            :null}

                            {props.status === 0 ? 
                            <>
                            <Grid item xs={12} sm={12} md={2} lg={2} container justifyContent='flex-end' direction="row">
                                <Grid item>
                                    {props.manage && 
                                        <NavLink to={"/instructor/course/"+row.id} style={{ textDecoration: "none" }}>
                                            <Tooltip title={props.t("edit.2")}><IconButton style={{marginTop: '-5%'}}><EditIcon/></IconButton></Tooltip>
                                        </NavLink>
                                    }
                                </Grid>
                                <Grid item>
                                    {props.handleDelete &&
                                        <Tooltip title={props.t("delete.1")}><IconButton onClick={()=> props.handleDelete(row.id)} style={{marginTop: '-5%'}}><DeleteIcon /></IconButton></Tooltip>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}  style={{textAlign: 'end', marginRight: '1%'}}>
                                    <Button variant="contained" color="primary" onClick={()=>{handleSubmit(row.id);}}>
                                        {props.t("unpublish.2")}
                                    </Button>
                                </Grid>
                            </Grid>
                            </>
                            :null}
                        </Grid>
                    </Paper>
                    <br/>
                </div>
            ))}

            {props.status === 0 ? 
                <AlertDialog2 open={open3} handleClick={handleClick3} text={message} button={props.t("ok.1")}/>
                :null
            }
        </div>
    );
}
