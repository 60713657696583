import React, {useState, useEffect} from 'react';
import IconGridList from "bt_modules/IconGrid/IconGridList";
import Axios from "axios";
import Loading from 'bt_modules/Loading';
import Pagination from '@material-ui/lab/Pagination';
import { Grid } from '@material-ui/core';

export default function CategoryListPage(props) {
    const [allCat, setAllCat] = useState([]);
    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        window.scrollTo(0, 0)
        let isMounted = true;
        const options = {
            url: window.$product + "/getAllCatInfoCountCourse",
            method: "POST",
            withCredentials: true,
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "start": '0',
                "stop": '10',
                language: props.i18n.language
            }
        };
        Axios(options).then(response => {
            if (isMounted){
                setAllCat(response.data.course);
                setPage(Math.round(response.data.countCat.CountCat / 10))
                setLoading(false);
            }
        })
        return () => { isMounted = false };
    },[props.i18n.language]);

    const handleChange = (event, value) => {
        let start = (value * 10) - 10;
        const options = {
            url: window.$product + "/getAllCatInfoCountCourse",
            method: "POST",
            withCredentials: true,
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "start": start,
                "stop": '10',
                language: props.i18n.language
            }
        };
        Axios(options).then(response => {
            setAllCat(response.data.course);
        })
    };

    return (
        <div>
            {!loading ? 
                <div>
                    <IconGridList iconsInfo={allCat} titleText={props.t("Categories.1")} t={props.t}/>
                    <Grid container
                        direction="row-reverse"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <br/><br/> <br/>
                        <Pagination count={page} color="primary" onChange={handleChange} />
                    </Grid>
                </div>
                :
                <Loading/>
            }
        </div>
    )
}

