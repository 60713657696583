import React, { useState } from 'react';
import {IconButton, Grid, Typography, Paper, Button, Container, Collapse} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Axios from "axios";
import {ExpandMore, ExpandLess} from '@material-ui/icons';
import {useForm} from "react-hook-form";
import UploadVideo from './UploadVideo';
import UploadFile from './UploadFile';
import Progress from "./Progress";
import CancelIcon from '@material-ui/icons/Cancel';
import UploadType from './UploadType';
import SectionInfo from './SectionInfo'
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: '1%',
        maxWidth: "90%"
    },
}));

export default function SectionsContent(props) {
    const classes = useStyles();
    const [videoName, setVideoName] = useState();
    const [fileName, setFileName] = useState();
    const [fileType, setFileType] = useState();
    const [fileUploadDate, setFileUploadDate] = useState();
    const [videoTableData, setVideoTableData] = useState([]);
    const [fileTableData, setFileTableData] = useState([]);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [contentType, setContentType] = useState("");
    const [duration, setduration] = useState(null);
    const [permission, setPermission] = useState();
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")

    const handleClick = () => {
        setOpen(false)
    }

    const {register, handleSubmit} = useForm();

    const loadVideo = file => new Promise((resolve, reject) => {
        try {
            let video = document.createElement('video')
            video.preload = 'metadata'
    
            video.onloadedmetadata = function () {
                resolve(this)
            }
    
            video.onerror = function () {
                reject("Invalid video. Please select a video file.")
            }
    
            video.src = window.URL.createObjectURL(file)
        } catch (e) {
            reject(e)
        }
    })

    function format(s) {
        var m = Math.floor(s / 60);
        m = (m >= 10) ? m : "0" + m;
        s = Math.floor(s % 60);
        s = (s >= 10) ? s : "0" + s;
        return m + ":" + s;
    }

    const GetDuration = async e => {
        var file = e.target.files[0];
        if(file){
            var fileIsMp4 = (file.type === "video/mp4");
            if (fileIsMp4) {
                const video = await loadVideo(e.target.files[0]);
                setduration(format(video.duration));
            }
        }
    }

    const GetInFoFileVDO =  e => {
        var file = e.target.files[0];
        if(file){
            var fileIsMp4 = (file.type === "video/mp4");
            if (fileIsMp4) {
                let today = new Date();
                let newArray = [];
                let temdata ={
                    filename : e.target.files[0].name,
                    typeVDO  : e.target.files[0].type,
                    dateVDO  : String(today.getDate()).padStart(2, '0') + "/"+ String(today.getMonth()+1).padStart(2, '0') +"/"+today.getFullYear(),
                }
                newArray.push(temdata);
                setVideoTableData(newArray);
            } else {
                document.getElementById("myform").reset();
                setMessage(props.t("doc.5"));
                setOpen(true)
            }
        }else{
            document.getElementById('myform').reset();
        }
    }

    const GetInFoFile = (e) => {
        var type = e.target.files[0].name.split('.').pop();
        let items = ['pdf', 'doc','docx', 'jpg', 'png', 'xls', 'xlsx','ppt','pptx'];
        const hasValue = items.some(e => e === type);
        if(e.target.files[0]){
            if(hasValue){
                setFileName(e.target.files[0].name);
                setFileType(e.target.files[0].type);
                
                let today = new Date();
                let date= String(today.getDate()).padStart(2, '0') + "/"+ String(today.getMonth()+1).padStart(2, '0') +"/"+today.getFullYear();
                setFileUploadDate(date);
                let newArray = [];
                let temdata ={
                    filename : e.target.files[0].name,
                    typeFile  : e.target.files[0].type,
                    dateFile  : String(today.getDate()).padStart(2, '0') + "/"+ String(today.getMonth()+1).padStart(2, '0') +"/"+today.getFullYear(),
                }
                newArray.push(temdata);
                setFileTableData(newArray);
            }else{
                document.getElementById('myfile').reset();
                setMessage(props.t("doc.6"));
                setOpen(true)
            }
        }else{
            document.getElementById('myfile').reset();
        }
    }

    const OpenLecture = (id) => {
        var newArr = [...props.addNewSection];
        newArr.map((obj) => {
            if(obj.id === id){
                if(obj.open === true){
                    obj.open = false;
                    obj.openContentTypeVDO = false;
                }
                else{
                    obj.open = true;
                    obj.openContentTypeVDO = false;
                }
            }else{
                obj.open = false;
                obj.openContentTypeVDO = false;
            }
            return obj;
        })
        props.setAddNewSection(newArr);
        setVideoTableData([])
        setFileTableData([])
        var element = document.getElementById('myform');
        var element2 = document.getElementById('myfile');
        if (element !== null && element.value === '') {
            document.getElementById("myform").reset();            
        }
        if (element2 !== null && element2.value === '') {
            document.getElementById("myfile").reset();
        }
    }

    const onSubmit = (sdata) => {
        let x = []
        let lessonNum = 0

        for (var i = 0; i < props.videoList.length; i++) {
            if (props.videoList[i].section_id === props.sectionID){
                x.push(props.videoList[i])
            }
        }
        
        if(x.length > 0){
            if(x[x.length - 1].lesson_num > 0){
                lessonNum = x[x.length - 1].lesson_num
            }
        }

        if(sdata.file1.length > 0){
            if(videoName){
                if(permission){
                    setUploadPercentage(25)
                    const formData = new FormData();
                    formData.append("file1", sdata.file1[0]);
                    formData.append("nameVDO", videoName);
                    formData.append("duration", duration);
                    formData.append("sectionID", props.sectionID);
                    formData.append("sectionNum", props.sectionNum);
                    formData.append("courseID", props.courseID);
                    formData.append("permission", permission);
                    formData.append("lesson_num", lessonNum + 1);
                    formData.append("token", props.accessToken);
                    const options = {
                        url: window.$streaming + "/upToTemp",
                        method: "POST",
                        headers:{
                        "Accept": "application/json",
                        "content-Type": "application/json;charset-UTF-8"
                        },
                        data: formData,                    
                    };
                    Axios(options).then(response => {
                        setUploadPercentage(50)
                        if(response.data.uploadStatus === 1){
                            setUploadPercentage(100)
                            const options3 = {
                                url: window.$streaming + "/upToGdrive",
                                method: "POST",
                                headers:{
                                    "Accept": "application/json",
                                    "content-Type": "application/json;charset-UTF-8"
                                    },
                                data: {
                                    "fileName": response.data.filename,
                                    "token": props.accessToken,
                                    "courseID": props.courseID
                                }
                            };
                            Axios(options3)
                            const options2 = {
                                url: window.$product + "/selectInstructorCourseLesson",
                                method: "POST",
                                headers: {
                                    "Accept": "application/json",
                                    "content-Type": "application/json;charset-UTF-8"
                                },
                                data: {
                                    "courseID": props.courseID,
                                    "token": props.accessToken
                                }
                            };
                            Axios(options2).then(response => {
                                props.setVideoList(response.data);
                                setVideoTableData([]);
                                setTimeout(() => setUploadPercentage(0), 10);
                                setPermission('');
                                document.getElementById("myform").reset();
                                setMessage(props.t("lesson.4"));
                                setOpen(true);
                                
                            })
                        }
                        else{
                            setMessage(props.t("upload.4"));
                            setOpen(true)
                        }
                    })
                }else{
                    setMessage(props.t("permission.2"));
                    setOpen(true)
                }
            }else{
                setMessage(props.t("doc.7"));
                setOpen(true)
            }
        }
        else{
            setMessage(props.t("doc.6"));
            setOpen(true)
        }
    }
    
    const onSubmitFile = (file) => {
        if(file.pdf.length > 0){
            if(fileName){
                const formData = new FormData();
                formData.append("pdf", file.pdf[0]);
                formData.append("nameFile", fileName);
                formData.append("typeFile", fileType);
                formData.append("dateFile", fileUploadDate);
                formData.append("sectionID", props.sectionID);
                formData.append("sectionNum", props.sectionNum);
                formData.append("courseID", props.courseID);
                formData.append("token", props.accessToken);
                const options = {
                    url: window.$streaming + "/upload109",
                    method: "POST",
                    headers:{
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                    },
                    data: formData,
                };
                Axios(options).then(response => {
                    const options = {
                        url: window.$product + "/selectInstructorCourseResource",
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "content-Type": "application/json;charset-UTF-8"
                        },
                        data: {
                            "courseID": props.courseID,
                            "token": props.accessToken
                        }
                    };
                    Axios(options).then(response => {
                        props.setFileList(response.data);
                        document.getElementById('myfile').reset();
                        setFileTableData([]);
                    })
                })
            }else{
                setMessage(props.t("doc.8"));
                setOpen(true)
            }
        }else{
            setMessage(props.t("doc.6"));
            setOpen(true)
        }
    }

    const handleContentTypeVideo = (sectionID) => {
        let newArr = props.addNewSection.map((obj) => {
            if(obj.id === sectionID){
                    obj.openContentTypeVDO = true;
                    setContentType("Video");
                    setVideoName("");
                
            }else{
                obj.openContentTypeVDO = false;
            }
            return obj;
        })
        props.setAddNewSection(newArr);
        setVideoTableData([])
        setFileTableData([])
    }

    const handleContentTypeVideoSlide = (sectionID) => {
        let newArr = props.addNewSection.map((obj) => {
            if(obj.id === sectionID){
                    obj.openContentTypeVDO = true;
                    setContentType("VideoSlide");
                    setVideoName("");
                
            }else{
                obj.openContentTypeVDO = false;
            }
            return obj;
        })
        props.setAddNewSection(newArr);
    }

    const handleClose = (sectionID) => {
        let newArr = props.addNewSection.map((obj) => {
            obj.openContentTypeVDO = false;              
            return obj;
        })
        props.setAddNewSection(newArr);
        setContentType("");
        setVideoName("");
        setVideoTableData([])
        setFileTableData([])
        var element = document.getElementById('myform');
        var element2 = document.getElementById('myfile');
        if (element !== null && element.value === '') {
            document.getElementById("myform").reset();            
        }
        if (element2 !== null && element2.value === '') {
            document.getElementById("myfile").reset();
        }
    }

    const DeleteRowVDO = (VDOfilename) => {
        const deleteVDO = videoTableData.filter(deleted => deleted.filename !== VDOfilename);
        setVideoTableData(deleteVDO);
        document.getElementById('myform').reset();
    }

    const DeleteRowFile = (file) => {
        const deleteFile = fileTableData.filter(deleted => deleted.filename !== file);
        setFileTableData(deleteFile);
        document.getElementById('myfile').reset();
    }

    const handleExpand = (id) => {
        let arr = [...props.addNewSection];
        arr.map((data)=>{
            if(data.id === id){
                if(data.openSec === true){
                    data.openSec = false;
                }
                else{
                    data.openSec = true;
                }
            }else if(data.openSec === true){
                data.openSec = true;
            }else{
                data.openSec = false;
            }
            return data;
        })
        props.setAddNewSection(arr);
    }

    return (
        <div>
            <Grid>
                <Paper className={classes.paper} >
                    <Grid container>
                        <Grid item container lg={10}>
                            <Typography variant="h5" style={{fontWeight: 'bold'}}>
                                {props.t("lesson.2")}: {props.section}
                            </Typography><br/>
                        </Grid>
                            <Grid item container lg={1}>
                                <IconButton onClick={()=> {handleExpand(props.sectionID);}} style={{marginLeft: '50%'}}>
                                    {props.openSec ? <ExpandLess style={{marginLeft: '-6%', textAlign: 'center', fontSize: '200%'}}/> : <ExpandMore style={{marginLeft: '10%', textAlign: 'center', fontSize: '200%'}}/> } 
                                </IconButton>
                            </Grid >
                            <Grid item container justifyContent="flex-end" lg={1}>
                                <IconButton onClick={()=> props.handleDeteteSection(props.sectionID)}>
                                    <DeleteIcon style={{ fontSize: '170%' }}/>
                                </IconButton>
                            </Grid>
  
                    </Grid>

                    <Collapse in={props.openSec} timeout="auto">
                        <SectionInfo 
                        courseUnpublishVDO={props.videoList} 
                        setCourseUnpublishVDO={props.setVideoList} 
                        courseUnpublishFile={props.fileList} 
                        setCourseUnpublishFile={props.setFileList} 
                        sectionID={props.sectionID}
                        editable={props.editable}
                        accessToken={props.accessToken}
                        t={props.t}
                        /><br/>

                        {props.open ?
                            <>
                                <Container style={{backgroundColor: '#E2E2E2', borderRadius: 8, boxShadow: '0.5px'}}><br/>
                                    <div>
                                        <Grid container spacing={4}>
                                            {props.openContentTypeVDO ?
                                                <div style={{width: '100%'}}>
                                                    {contentType === "Video" ? (
                                                    <div>
                                                        <IconButton onClick={()=> {handleClose(props.sectionID);}} style={{marginLeft: '97%'}}><CancelIcon/></IconButton>
                                                        <Container>
                                                            <div>
                                                                <h1>{props.t("upload.2")}</h1>
                                                                <form id="myform" onSubmit={handleSubmit(onSubmit)} noValidate>
                                                                    <input id="file1" type="file" name="file1" multiple required style={{display: 'none'}} onChange={(e)=>{GetInFoFileVDO(e);GetDuration(e);}} ref={register}/>
                                                                    <Grid container>
                                                                        <Grid item xs={12} style={{display: 'flex'}}>
                                                                            <Grid item xs={2}>
                                                                                <label htmlFor="file1">
                                                                                    <Button variant="contained" color="primary" component="span" style={{textTransform: 'none', width: '100%'}}>
                                                                                        {props.t("choose.1")}
                                                                                    </Button>
                                                                                </label><br/><br/>
                                                                            </Grid>&nbsp;
                                                                        </Grid>
                                                                    </Grid>
                                                                    <UploadVideo t={props.t} permission={permission} setVideoName={setVideoName} videoName={videoName} setPermission={setPermission} sectionID={props.sectionID} arrayDataVDO={videoTableData} setArrayDataVDO={setVideoTableData} DeleteRowVDO={DeleteRowVDO}/><br/>
                                                                    <Button variant="contained" color="primary" type="submit" style={{textTransform: 'none'}}>
                                                                        {props.t("upload.1")}
                                                                    </Button>
                                                                </form>
                                                                {uploadPercentage > 0 && <Progress percentage={uploadPercentage} />}
                                                            </div><br/>
                                                        </Container>
                                                    </div>
                                                    ):
                                                    <div>
                                                        <IconButton onClick={()=> {handleClose(props.sectionID);}} style={{marginLeft: '97%'}}><CancelIcon/></IconButton>
                                                        <Container>
                                                        <h1>{props.t("upload.3")}</h1>
                                                            <form id="myfile" onSubmit={handleSubmit(onSubmitFile)} noValidate>
                                                                <input id="pdf" type="file" name="pdf" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                                                                multiple required style={{display: 'none'}} onChange={(e)=>{GetInFoFile(e)}} ref={register}/>
                                                                <Grid container>
                                                                    <Grid item xs={12} style={{display: 'flex'}}>
                                                                        <Grid item xs={2}>
                                                                            <label htmlFor="pdf">
                                                                                <Button variant="contained" color="primary" component="span" style={{textTransform: 'none', width: '100%'}}>
                                                                                    {props.t("choose.2")}
                                                                                </Button>
                                                                            </label><br/><br/>
                                                                        </Grid>&nbsp;
                                                                    </Grid>
                                                                </Grid>
                                                                <UploadFile fileName={fileName} setFileName={setFileName} arrayDataFile={fileTableData} DeleteRowFile={DeleteRowFile} t={props.t}/><br/>
                                                                <Button variant="contained" color="primary" type="submit" style={{textTransform: 'none'}}>
                                                                    {props.t("upload.1")}
                                                                </Button>
                                                            </form>   
                                                        </Container>
                                                    </div>}
                                                </div>
                                            :
                                                <div style={{width: '100%'}}>
                                                    <IconButton onClick={()=> {OpenLecture(props.sectionID);}} style={{marginLeft: '97%'}}><CancelIcon/></IconButton>
                                                    <Grid item xs={12}>
                                                        <UploadType handleContentTypeVideo={handleContentTypeVideo} handleContentTypeVideoSlide={handleContentTypeVideoSlide} sectionID={props.sectionID} t={props.t}/>
                                                    </Grid>
                                                </div>
                                            }
                                        </Grid><br/><br/>
                                    </div>
                                </Container><br/><br/>
                                
                            </>
                            :
                            props.editable && 
                            <Button variant="contained" color="primary"  onClick={()=> {OpenLecture(props.sectionID);}}>
                                + {props.t("add.3")}
                            </Button>
                        }
                    </Collapse>
                </Paper>
            </Grid>
            <AlertDialog open={open} handleClick={handleClick} text={message} button={props.t("ok.1")}/>
        </div>
    )
}