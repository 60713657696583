import React from 'react'
import { Typography, TextField,  Dialog, DialogActions, DialogContent, DialogTitle, Button} from '@material-ui/core';
import { Editor } from "@tinymce/tinymce-react";
import InputAdornment from '@material-ui/core/InputAdornment';

export default function Dialogs(props) {
    const TextEditorChange = (value) => {
        props.setEdit({
            ...props.edit,
            description: value
        })
    }
    return (
        <div>
            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{props.t("edit.2")}</DialogTitle>
                    <DialogContent>                
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label={props.t("course.8")}
                            value= {props.edit.name || ''}
                            onChange = {props.handleChange}
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="price"
                            name="price"
                            label={props.t("price.3")}
                            value= {props.edit.price || ''}
                            onChange = {props.handleChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{props.t(props.edit.currency)}</InputAdornment>,
                            }}
                            fullWidth
                        />
                        <br/><br/>
                        <Typography variant="h5" gutterBottom>{props.t("description.1")}</Typography>
                        <Editor
                            apiKey="2fyxgq4jzeklqq6t7y96at0fcd52q4a1kbrpf8x46ya49gk4"
                            init={{
                                height: 220,
                                plugins: [
                                    "preview help",
                                ],
                                toolbar1: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify preview|",
                            }}
                            value={props.edit.description}
                            onEditorChange={TextEditorChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleClose} color="primary">
                            {props.t("cancel.1")}
                        </Button>
                        <Button onClick={()=> {props.handleEdit()}} color="primary" >
                            {props.t("finish.1")}
                        </Button>
                    </DialogActions>
                </Dialog>
        </div>
    )
}
