import React from 'react';
import {Dialog} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

import ReactPlayer from 'react-player';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
    const handleClose = () => {
        props.setOpenDialogVideoTrailer(false);
        props.setVideoTrailer("")
    };

    return (
    <div style={{backgroundColor: 'red',}}>
        <Dialog
            open={props.openDialogVideoTrailer}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth={false}
        >
        
            {props.videoTrailer && props.lessonID ?
                    <ReactPlayer 
                        url={ window.$playback + '/viewSampleVideo/' + props.lessonID + '/' + props.videoTrailer }
                        playing={props.openDialogVideoTrailer}
                        controls
                        progressInterval={10}
                        config={{ file: { 
                            attributes: {
                                controlsList: 'nodownload',
                                onContextMenu: e => e.preventDefault()
                            }    
                        }}}
                    />
                    :null           
            }
        
        </Dialog>
    </div>
    );
}