import React from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function TextEditor(props) {
    return (
        <div>
            <Editor
            apiKey="2fyxgq4jzeklqq6t7y96at0fcd52q4a1kbrpf8x46ya49gk4"
            init={{
                height: 220,
                toolbar1: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify |",
            }}
                value={props.value.description}
                onEditorChange={props.onEditorChange}
            />
        </div>
    )
}
//qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc