import React, { useState, useEffect } from "react";
import CardListCarousel from "bt_modules/Carousel/CardListCarousel";
import Axios from "axios";
import IconGridList from "bt_modules/IconGrid/IconGridList";
import { makeStyles, Container} from "@material-ui/core";
import Loading from "bt_modules/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20
    },
}));

export default function HomePage(props) {
    const classes = useStyles();
    const [featuredCoursesInfo, setFeaturedCoursesInfo] = useState([]);
    const [featuredCatInfo, setFeaturedCatInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(()=>{
        window.scrollTo(0, 0)
        let isMounted = true;
        const options = {
            url: window.$product + "/getFeaturedInfo",
            method: "POST",
            withCredentials: true,
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                language: props.i18n.language
            }
        };
        Axios(options).then(response => {
            if (isMounted){
                if(response.data.catInfo){
                    setFeaturedCatInfo(response.data.catInfo);
                }
                if(response.data.courseInfo){
                    let courseData = []
                    response.data.courseInfo.map(course => (
                        courseData.push({
                            "id":course.id,
                            "name":course.name,
                            "img":course.img_id,
                            "price":course.price,
                            "promoPrice": course.promo_price,
                            "discount": course.discount,
                            "sellerName":course.firstname+" "+course.lastname,
                            "currency": course.currency
                        })
                    ));
                    setFeaturedCoursesInfo(courseData);
                }
                setLoading(false);
            }
        })
        return () => { isMounted = false };
    },[props.i18n.language]);

    return (
        <Container className={classes.root}>
            {!loading ? 
            <div>
                <CardListCarousel cardsData={featuredCoursesInfo} titleText={props.t("course.1")} cardLink="/course/" t={props.t}/>
                <IconGridList iconsInfo={featuredCatInfo} titleText={props.t("Categories.2")} t={props.t}/>
                <div style={{marginTop: '4%'}}/>
            </div>
            :
            <Loading/>
            }
        </Container>
    )
}
