import React, { useEffect, useState } from 'react'
import Axios from "axios";
import { Typography } from '@material-ui/core';
import InstructorCourse from "bt_modules/InstructorCourse/InstructorCourse"
import Loading from 'bt_modules/Loading';
import AlertConfirm from 'bt_modules/AlertDialog/AlertConfirm';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

export default function Course(props) {
    const [courseInfo, setCourseInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [courseID, setCourseID] = useState()
    const [courseReload, setCourseReload] = useState(true)
    const [promptText, setPromptText] = useState("")

    useEffect(() => {
        if (props.accessToken) {
            let isMounted = true;
            const options = {
                url: window.$product + "/getInstructorCourse",
                method: "POST",
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "token": props.accessToken,
                    "status": 0
                }
            };
            Axios(options).then(response => {
                if (isMounted) {
                    if (response.data) {
                        setCourseInfo(response.data);
                        setLoading(false);
                    }
                }
            })
            return () => { isMounted = false };
        }
    }, [props.accessToken])

    const handleConfirm = () => {
        const options = {
            url: window.$product + "/deleteInstructorCourse",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "course_id": courseID,
                "token": props.accessToken
            }
        };
        Axios(options).then(response => {
            if (response.data.status) {
                let newArray = [...courseInfo];
                const del = newArray.filter(update => update.id !== courseID);
                setCourseInfo(del);
                setCourseReload(false);
                window.location.reload()
            }
        })
    }

    const handleCancel = () => {
        setOpenConfirm(false)
    }

    const handleOk = () => {
        setOpenDialog(false)
    }

    const handleDelete = (id) => {
        setCourseID(id);

        const options = {
            url: window.$product + "/prepareDelInsCourse",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "course_id": id
            }
        };
        Axios(options).then(response => {
            if (response.data) {
                switch(response.data.processLes) {
                    case 1:
                        setPromptText(props.t("delete.4"));
                        setOpenDialog(true);
                      break;
                    case 0:
                      switch(response.data.empty) {
                        case 1:
                            setPromptText(props.t("delete.2"));
                            setOpenConfirm(true);
                            break;
                        case 0:
                            setPromptText(props.t("delete.3"));
                            setOpenConfirm(true);
                            break;
                        default:
                            setPromptText("Unexpected Error");
                            setOpenDialog(true);
                      }
                      break;
                      default:
                        setPromptText("Unexpected Error");
                        setOpenDialog(true);
                  }
            }else{
                setPromptText("No response from server");
                setOpenDialog(true);
            }
        })
    }

    return (
        <div>
            {!loading ?
                <div>
                    {courseInfo.length > 0 ?
                        <div>
                            {courseReload === true ?
                                <div>
                                    <InstructorCourse accessToken={props.accessToken} title={props.t("course.16")} courseInfo={courseInfo} setCourseInfo={setCourseInfo} handleDelete={handleDelete} manage={true} t={props.t} status={0} />
                                    <AlertConfirm open={openConfirm} handleConfirm={handleConfirm} handleCancel={handleCancel} text={promptText} button1={props.t("delete.1")} button2={props.t("cancel.1")} />
                                    <AlertDialog open={openDialog} handleClick={handleOk} text={promptText} button={props.t("ok.1")} />
                                </div>
                                :
                                <Loading />
                            }
                        </div>
                        :
                        <Typography variant="h3" gutterBottom color="primary" style={{ marginLeft: "40%", marginTop: '20%' }}>
                            {props.t("course.10")}
                        </Typography>
                    }
                </div>
                :
                <Loading />
            }
        </div>
    )
}