import React, {useEffect, useState} from 'react'
import {makeStyles, Typography} from '@material-ui/core';
import Axios from 'axios';
import AdminListGrid from 'bt_modules/AdminCard/AdminListGrid'
import Loading from 'bt_modules/Loading';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
        textAlign: 'center', 
        padding: 40
    },

}));

export default function ApproveCourse(props) {
    const classes = useStyles();
    const [courseList, setCourseList] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(props.accessToken){
            let isMounted = true;
            const options2 = {
                url: window.$product + "/getAdminCourse",
                method: "POST",
                headers:{
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data:{
                    "status": 1,
                    "token": props.accessToken
                }
            };
            Axios(options2).then(response => {
                if(isMounted){
                    if(response.data.courseInfo){
                        setCourseList(response.data.courseInfo)
                        setLoading(false);
                    }else{
                        setLoading(false);
                    }
                }
            })
            return () => { isMounted = false };
        }
    }, [props.accessToken])

    return (
        <div>
            {!loading ? 
            <div>
                {courseList.length > 0 ?
                <div>
                    <AdminListGrid accessToken={props.accessToken} courseList={courseList} t={props.t} setCourseList={setCourseList}/>
                </div>
                :
                <div className={classes.root}>
                    <Typography variant="h4">{props.t("course.10")}</Typography>
                </div>
                }
            </div>
            :
            <Loading/>
            }
        </div>
    )

}
