import React from "react";
import Carousel from "bt_modules/Carousel";
import {Grid, Container, Typography, makeStyles} from "@material-ui/core";
import ProductCard from "bt_modules/ProductCard/ProductCard";
import "bt_modules/Carousel/styles.scss";

const useStyles = makeStyles((theme) => ({
  root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.light,
      width: '100.2%'
  },
  rootAll: {
    width: "100%", 
    margin: "auto", 
    marginTop: '5%'
  },
  container: {
    marginTop: '1%',
  },
  textStyle: {
    padding: '0.5%',
    paddingLeft: '3%', 
    fontWeight: 'lighter'
  }
}));

export default function CardListCarousel(props) {
  const classes = useStyles();
  const firstSafeIndex = props.cardsData.indexOf("");

  return (
    <div>
      <Container className={classes.rootAll} >
        <Grid className={classes.root}>
          <Typography variant="h5" className={classes.textStyle}>
            {props.titleText}
          </Typography>
        </Grid>
        </Container>
      <div className={classes.container}>
        <Carousel splitIndex={firstSafeIndex}>
          {props.cardsData.map((card) => (
            <div key={card.id}>
            <ProductCard card={card} cardLink={props.cardLink} t={props.t}/>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}


