import React, {useState, useEffect} from 'react';
import VideoPlayer from "bt_modules/VideoPlayer/VideoPlayer";
import Axios from "axios";
import Loading from 'bt_modules/Loading';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

export default function LearnPage(props) {
    const [sectionsInfo, setSectionsInfo] = useState([]);
    const [lessonsInfo, setLessonsInfo] = useState([]);
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [videoToken, setVideoToken] = useState()

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.accessToken){
            let isMounted = true;
            const options = {
                url: window.$product + "/getPlaylistInfo",
                method: "POST",
                headers:{
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "courseID": props.match.params.courseId,
                    "accessToken": props.accessToken
                },
            }
            Axios(options).then((res) => {
                if(isMounted){
                    if(res.data.status){
                        if(res.data.videoToken){
                            setVideoToken(res.data.videoToken)
                        }
                        setSectionsInfo(res.data.sections);
                        setLessonsInfo(res.data.lessons);
                        setResources(res.data.resources);
                        setLoading(false);
                    }else{
                        setMessage(props.t("info.5"))
                        setOpen(true)
                    }
                }
            });
            return () => { isMounted = false };
        }else{
            setMessage(props.t("login.4"))
            setOpen(true)
        }
    }, [props])

    return (
        <div>
            {!loading ?
            <VideoPlayer
                courseID={props.match.params.courseId}
                sectionsInfo={sectionsInfo}
                setSectionsInfo={setSectionsInfo}
                lessonsInfo={lessonsInfo}
                setLessonsInfo={setLessonsInfo}
                resources={resources}
                setResources={setResources}
                videoToken={videoToken}
                {...props}
            />
            :
            <div>
                <AlertDialog open={open} handleClick={handleClick} text={message} button={props.t("ok.1")}/>
                <Loading/>
            </div>
            }
        </div>
    )
}
