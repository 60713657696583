import React from 'react'
import { Button, Container, Typography, makeStyles } from '@material-ui/core'
import Cancel from "../../ImgLib/cancel.png"
//import Axios from 'axios';
import { NavLink } from 'react-router-dom';
export default function CancelPage(props) {

    const classes = useStyles();


        return (
            <Container>

                <div className={classes.root}>

                    <div className={classes.contentTittle}>
                            <img src={Cancel} alt="" className={classes.imgCheck}/>
                        <div className={classes.imgCheckPosition}/>
                        <Typography variant="h3" className={classes.typoH3}>{props.t("cancel.2")}</Typography>
                    </div>
                    
                    <div style={{marginTop: '2%'}}/>

                        
                        <div className={classes.content}>
                            {/* <Button style={{textTransform: 'none'}} variant="contained">{props.t("info.14")}</Button> */}
                            <div className={classes.typoH6Position2}/>
                            <NavLink to={"/home"} style={{textDecoration: "none"}}>
                                <Button style={{textTransform: 'none'}} variant="contained">{props.t("info.15")}</Button>
                            </NavLink>
                        </div>

                    <div style={{marginTop: '5%'}}/>
                </div>
            </Container>
        )
    }


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.light,  
        marginTop: '2%',
         padding: '0.2%',
         width: '70%',
         borderRadius: 8,
         marginLeft: '15%'
    },
    contentTittle: {
        display: 'flex', 
        justifyContent: 'center', 
        marginTop: '5%',
        //backgroundColor: 'pink',
    },
    imgCheck: {
        width: '6%', 
        height: '2%'
    },
    imgCheckPosition: {
        marginLeft: '0.5%'
    },
    typoH3: {
        color: theme.palette.error.main,
        marginTop: '0.5%'
    },
    content: {
        display: 'flex', 
        justifyContent: 'center'
    },
    typoH6: {
        color: theme.palette.primary.main
    },
    typoH6Position1: {
        marginLeft: '1%'
    },
    typoH6Position2: {
        marginLeft: '2%'
    },


}));