import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Typography} from '@material-ui/core';
import EditPassword from './EditPassword'
import LoadingAuth from 'bt_modules/Authen/LoadingAuth';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default function Dialogs(props) {
    const classes = useStyles();

    const onClose = () => {
        if(!props.loading){
            props.setOpen(false)
        }
    }   
    
    return (
        <div>
            <Dialog open={props.open} onClose={onClose} aria-labelledby="form-dialog-title" scroll="body">
                <DialogTitle id="form-dialog-title" className={classes.center}></DialogTitle>
                <DialogContent>
                    {props.loading ?
                        <LoadingAuth/>
                        :
                        null
                    }
                    <DialogContentText className={classes.center} color="primary">
                        {props.DialogContentText}
                    </DialogContentText>
                    <Typography color="error">{props.message}</Typography>
                    {props.id === 'password' && 
                        <EditPassword 
                            value={props.value} 
                            onChange={props.onChange} 
                            isCurrentPasswordValidated={props.isCurrentPasswordValidated}
                            isNewPasswordValidated={props.isNewPasswordValidated}
                            isConfirmPasswordValidated={props.isConfirmPasswordValidated}
                            loading={props.loading}
                            t={props.t}
                        />
                    }
                    <div><br/></div>
                    <div className={classes.center}>
                        <Button disabled={props.loading} variant="outlined" color="primary" onClick={props.handleEdit}>{props.t("edit.2")}</Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={props.loading} onClick={()=> window.location = '/find_account'}>
                        {props.t("password.3")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
