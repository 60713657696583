import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button'
import Axios from "axios";
import Loading from 'bt_modules/Loading';
import AlertConfirm from 'bt_modules/AlertDialog/AlertConfirm';
//import Loading from 'bt_modules/Loading';


const StyledTableCell = withStyles((theme) => ({
    head: {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.secondary.dark,
        //color: theme.palette.common.white,
        color: theme.palette.secondary.light
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //marginRight: "5%"
        padding: '2%'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        fontSize: 26,
        marginTop: 20,
        marginBottom: 20
    },
    table: {
        minWidth: 700,
    },
    titleContent: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: 20,
        color: theme.palette.secondary.light,
        paddingLeft: '2%',
        padding: '0.5%'
    },
    bg: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
        width: '95%',
        paddingLeft: '2%'
    }
}));

export default function ApproveWithdraw(props) {
    const history = useHistory();
    const classes = useStyles();
    const [approveWD, setApproveWD] = useState([]);
    const [rejectID, setRejectID] = useState()
    const [openApproveDialog, setOpenApproveDialog] = useState(false)
    const [openRejectDialog, setOpenRejectDialog] = useState(false)
    const [withdrawID, setWithdrawID] = useState()
    const [remarks, setRemarks] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (props.accessToken) {
            let isMounted = true;
            const options = {
                url: window.$report + "/getAllAdminStats",
                method: "POST",
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "status": 0,
                    token: props.accessToken
                }
            };
            Axios(options).then(response => {
                if (isMounted) {
                    //console.log(response.data.sumPriceYesIncome[0])
                    setApproveWD(response.data.approvewithdraw)
                    setLoading(false);
                }
            })
            return () => { isMounted = false };
        }
    }, [props.accessToken])

    const accept = () => {
        const options = {
            url: window.$payment + "/approveWithdraw",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                withdraw_id: withdrawID,
                token: props.accessToken,
                status: 1
            }
        };
        Axios(options).then(response => {
            if(response.data.status === 1){
                let newArray = [...approveWD];
                const del = newArray.filter(update => update.id !== withdrawID);
                setApproveWD(del);
                setWithdrawID("")
                setOpenApproveDialog(false)
            }
        })
    }

    const reject = () => {
        const options = {
            url: window.$payment + "/rejectWithdraw",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                reject_id: rejectID,
                token: props.accessToken,
                status: 2,
                remarks: remarks
            }
        };
        Axios(options).then(response => {
            if(response.data.status === 1){
                let newArray = [...approveWD];
                const del = newArray.filter(update => update.id !== rejectID);
                setApproveWD(del);
                setRejectID("")
                setRemarks("")
                setOpenRejectDialog(false)
            }
        })
    }

    const handlereject = (reject_id) => {
        setRejectID(reject_id)
        setOpenRejectDialog(true)
    }

    const handleConfirm = (withdraw_id) => {
        setOpenApproveDialog(true)
        setWithdrawID(withdraw_id)
    }

    const handleClose = () => {
        setOpenApproveDialog(false)
    }

    const handleChangeRejDial = (e) => {
        setRemarks(e.target.value)
    }

    return (
        <div>
            {!loading ? 
            <Grid className={classes.root}>
                <Button onClick={() => history.goBack()} variant="contained" color="primary">
                    <div style={{ width: '100%', borderRadius: 8,display: 'flex'}} >
                        <ArrowBackIcon style={{textAlign: 'center'}}/>ກັບຄືນ
                    </div>
                </Button><br/><br/>   

                <div className={classes.titleContent}>
                    <Typography variant="h4"> ລາຍລະອຽດຖອນເງິນ  </Typography>
                </div>
                <div style={{padding: '2%', marginTop: '-2%'}}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">ຊື່ ແລະ ນາມສະກຸນ</StyledTableCell>
                                <StyledTableCell align="center">ເວລາຂໍຖອນເງິນ</StyledTableCell>
                                <StyledTableCell align="center">ຈຳນວນ&nbsp;(ກີບ)</StyledTableCell>
                                <StyledTableCell align="center">Acctions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {approveWD.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="center">{row.firstname} {row.lastname}</StyledTableCell>
                                    <StyledTableCell align="center">{row.balance}</StyledTableCell>
                                    <StyledTableCell align="center">{row.date}</StyledTableCell>
                                    <StyledTableCell align="center">
                                    <Button onClick={()=> handleConfirm(row.id)} variant="contained" color="primary">ຍອມຮັບ</Button>
                                    <Button onClick={()=> handlereject(row.id)} variant="contained" style={{marginLeft: '2%'}}>ປະຕິເສດ</Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <AlertConfirm open={openApproveDialog} handleConfirm={accept} handleCancel={handleClose} text={"ຢືນຢັນການອະນຸມັດ"} button1={"ອະນຸມັດ"} button2={props.t("cancel.1")}/>
                <AlertConfirm 
                    open={openRejectDialog}
                    handleConfirm={reject} 
                    handleCancel={()=> setOpenRejectDialog(false)} 
                    text={"ຢືນຢັນການປະຕິເສດ"} 
                    button1={"ປະຕິເສດ"} 
                    button2={props.t("cancel.1")}
                    useTextField={true}
                    label={"ໝາຍເຫດ"}
                    value={remarks}
                    onChange={handleChangeRejDial}
                />
                </div>
            </Grid>
            :
            <Loading/>
            }  
        </div>
    );
}
