import React, {useState, useEffect} from 'react'
import { Container, Grid} from '@material-ui/core';
import Axios from 'axios';
import AccountSetting from 'bt_modules/AccountSetting/AccountSetting'
import ProfileInputField from 'bt_modules/ProfileInputField/ProfileInputField';
//import PaymentMethod from 'bt_modules/PaymentMethod/PaymentMethod'
import Loading from 'bt_modules/Loading';
import ProfileImage from 'bt_modules/ProfileImage/ProfileImage';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import AlertDialog2 from 'bt_modules/AlertDialog/AlertDialog';
import AlertDialog4 from 'bt_modules/AlertDialog/AlertDialog';
import AlertDialog5 from 'bt_modules/AlertDialog/AlertDialog';

export default function Setting(props) {
    const [values, setValues] = useState({});
    const [biography, setBiography] = useState();
    const [message, setMessage] = useState("");
    const [isCurrentPasswordValidated, setIsCurrentPasswordValidated] = useState(false)
    const [isNewPasswordValidated, setIsNewPasswordValidated] = useState(false)
    const [isConfirmPasswordValidated, setIsConfirmPasswordValidated] = useState(false)
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open4, setOpen4] = useState(false)
    const [open5, setOpen5] = useState(false)

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    }

    const handleClick2 = () => {
        setOpen2(false)
        window.location.reload()
    }

    const handleClick4 = () => {
        setOpen4(false)
        window.location.reload()
    }

    const handleClick5 = () => {
        setOpen5(false)
        window.location.reload()
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.accessToken){
            let isMounted = true;
            if(isMounted){
                localStorage.setItem("lang", props.userInfo.locale)
                setValues({
                    firstName: props.userInfo.firstname,
                    lastName: props.userInfo.lastname,
                    phone: props.userInfo.phone,
                    state: props.userInfo.state,
                    twitter: props.userInfo.twitter,
                    website: props.userInfo.website,
                    facebook: props.userInfo.facebook,
                    linkedin: props.userInfo.linkedin,
                    youtube: props.userInfo.youtube,
                    biography: props.userInfo.biography,
                    email: props.userInfo.email,
                    locale: props.userInfo.locale,
                })
                setBiography(props.userInfo.biography)
                setLoading(false);
            }
            return () => { isMounted = false };
        }else{
            setOpen(true)
        }
    }, [props.userInfo, props.accessToken])

    const handleChange = (event) => {
        setValues({
        ...values,
        [event.target.name]: event.target.value
        });
    };

    const handleUpdate = () => {
        const options = {
            url: window.$aaa + "/updateUserInfo",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                token: props.accessToken,
                firstname: values.firstName,
                lastname: values.lastName,
                phone: values.phone,
                state: values.state,
                biography: values.biography,
                twitter: values.twitter,
                website: values.website,
                facebook: values.facebook,
                linkedin: values.linkedin,
                youtube: values.youtube,
                locale: values.locale
            }
        };
        Axios(options).then(response => {
            if(response.data.status){
                setOpen2(true)
            }
        })
    }

    const handleEditPassword = () => {
        if(values.currentPassword && values.confirmPassword && values.newPassword){
            setMessage("")
            if(String(values.currentPassword).length > 5){
                setIsCurrentPasswordValidated(false)
                if(String(values.newPassword).length > 5){
                    setIsNewPasswordValidated(false)
                    if(String(values.confirmPassword).length > 5){
                        setIsConfirmPasswordValidated(false)
                        if(values.newPassword === values.confirmPassword){
                            setLoading2(true)
                            const options = {
                                url: window.$aaa + "/updatePassword",
                                method: "POST",
                                withCredentials: true,
                                headers: {
                                    "Accept": "application/json",
                                    "content-Type": "application/json;charset-UTF-8"
                                },
                                data: {
                                    token: props.accessToken,
                                    currentPassword: values.currentPassword,
                                    newPassword: values.newPassword
                                }
                            }
                            Axios(options).then(response => {
                                if(response.data.status){
                                    switch(response.data.status){
                                        case 1:
                                            setLoading2(false) 
                                            setOpen4(true)
                                            break;
                                        case 2:
                                            setMessage(props.t("password.2"))
                                            setLoading2(false)
                                            break;
                                        case 3:
                                            setMessage(props.t("error.1")) 
                                            setLoading2(false)
                                            break;
                                        default:
                                            break;
                                    }
                                }else{
                                    setMessage(props.t("error.1"))
                                    setLoading2(false)
                                }
                            })
                        }else{
                            setMessage(props.t("password.11"))
                        }
                    }else{
                        setIsConfirmPasswordValidated(true)
                    }
                }else{
                    setIsNewPasswordValidated(true)
                }  
            }else{
                setIsCurrentPasswordValidated(true)
            }   
        }else{
            setMessage(props.t("info.8"))
        }
    }

    return (
        <div>
            {!loading ? 
            <div>
                <Container maxWidth={false}>
                    <Grid item lg={12} >
                        <ProfileImage
                            img_id={props.userInfo.img_id}
                            firstname={props.userInfo.firstname}
                            accessToken={props.accessToken}
                            setOpen={setOpen5}
                            t={props.t}
                        />
                    </Grid>
                    <br/>
                    <Grid item lg={12} >
                        <AccountSetting 
                            title={props.t("setting.3")}
                            values={values}
                            onChange={handleChange}
                            handleEditPassword={handleEditPassword}
                            message={message}
                            isCurrentPasswordValidated={isCurrentPasswordValidated}
                            isNewPasswordValidated={isNewPasswordValidated}
                            isConfirmPasswordValidated={isConfirmPasswordValidated}
                            loading={loading2}
                            t={props.t}
                        />
                    </Grid>
                    <br/>
                    <Grid item lg={12}>
                        <ProfileInputField 
                            values={values}
                            setValues={setValues}
                            onChange={handleChange}
                            onUpdate={handleUpdate}
                            title={props.t("setting.2")}
                            textEditorDefaultValue={biography}
                            t={props.t}
                        />
                    </Grid>
                    <br/>
                    <Grid item lg={12} >
                        {/* <PaymentMethod 
                            title={props.t("setting.4")}
                            t={props.t}
                        /> */}
                    </Grid>
                </Container>
                <AlertDialog2 open={open2} handleClick={handleClick2} text={props.t("edit.5")} button={props.t("ok.1")}/>
                <AlertDialog4 open={open4} handleClick={handleClick4} text={props.t("edit.5")} button={props.t("ok.1")}/>
                <AlertDialog5 open={open5} handleClick={handleClick5} text={props.t("edit.5")} button={props.t("ok.1")}/>
            </div>
            :
            <div>
            <AlertDialog open={open} handleClick={handleClick} text={props.t("login.4")} button={props.t("ok.1")}/>
            <Loading/>
            </div>
            }
        </div>
    )
}
