import React, { useState, useEffect } from 'react';
import Popover from 'material-ui-popup-state/HoverPopover';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import { CategoryOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CircleIcon from "bt_modules/CircleIcon/CircleIcon";
import { MoreVert as PersonIcon } from '@material-ui/icons';
import LoginPopup from "./LoginPopup";
import SignupPopup from "./SignupPopup";
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    flex: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    img: {
        height: "100%",
        width: "100%"
    },
    moreMenu: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
    iconMore: {
        backgroundColor: theme.palette.secondary.light,
        width: '41px',
        height: '41px'
    }
}));


export default function Auth(props) {
    const classes = useStyles();
    const [openSignup, setOpenSignup] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const [loginRoute, setLoginRoute] = useState("/login");
    const [signupRoute, setSignupRoute] = useState("/signup");

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (props.loginRoute) {
                setLoginRoute(props.loginRoute);
            }
            if (props.signupRoute) {
                setSignupRoute(props.signupRoute);
            }
        }
        return () => { isMounted = false };
    }, [props.loginRoute, props.signupRoute])

    return (
        <div className={classes.root}>
            <div className={classes.flex}>
                <LoginPopup setAccessToken={props.setAccessToken} setOpenSignup={setOpenSignup} open={openLogin} setOpen={setOpenLogin} logo={props.logo} loginRoute={loginRoute} setUserInfo={props.setUserInfo} setWishList={props.setWishList} setCartData={props.setCartData} setMyCourseData={props.setMyCourseData} i18n={props.i18n} t={props.t} />
                <SignupPopup open={openSignup} setOpen={setOpenSignup} setOpenLogin={setOpenLogin} logo={props.logo} signupRoute={signupRoute} t={props.t} i18n={props.i18n}/>
            </div>
            <PopupState variant="popover" popupId="demoPopover">
                {popupState => (
                    <div >
                        <div className={classes.moreMenu} {...bindHover(popupState)}>
                            <IconButton className={classes.iconMore}>
                                <PersonIcon color="primary"/>
                            </IconButton>
                        </div>
                        <Popover {...bindPopover(popupState)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} disableRestoreFocus>
                                <div onClick={()=> {popupState.close()}}>
                                    <CircleIcon iconImg={<CategoryOutlined />} tooltip={props.t("Categories.1")} linkTo="/category"/>
                                </div>
                                <div onClick={()=> {popupState.close()}}>
                                    <LoginPopup setAccessToken={props.setAccessToken} setOpenSignup={setOpenSignup} open={openLogin} setOpen={setOpenLogin} logo={props.logo} loginRoute={loginRoute} setUserInfo={props.setUserInfo} setWishList={props.setWishList} setCartData={props.setCartData} setMyCourseData={props.setMyCourseData} i18n={props.i18n} t={props.t}/>
                                </div>
                                <div onClick={()=> {popupState.close()}}>
                                    <SignupPopup open={openSignup} setOpen={setOpenSignup} setOpenLogin={setOpenLogin} logo={props.logo} signupRoute={signupRoute} t={props.t} />
                                </div>
                        </Popover>
                    </div>
                )}
            </PopupState>
        </div>
    )
}


