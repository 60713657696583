import React, {useState, useEffect} from 'react'
import { Box, Button, Card, CardContent, TextField, Grid, CardHeader} from '@material-ui/core';
import Axios from "axios";
import jwt_decode from 'jwt-decode';
import Loading from 'bt_modules/Loading';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog'
import AlertDialog2 from 'bt_modules/AlertDialog/AlertDialog'

export default function ResetPassword(props) {
    const [reset, setReset] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [conPassword, setConPassword] = useState("")
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [message, setMessage] = useState("")

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    }

    const handleClick2 = () => {
        setOpen2(false)
    }

    useEffect(() => {
        let isMounted = true;
        let decoded = jwt_decode(props.match.params.jwttoken);
        var today = new Date();
        if(decoded.exp * 1000 < today.getTime()){
            setMessage("This link is expired")
            setOpen(true)
        }else{
            const options = {
                url: window.$aaa + "/checkResetToken",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "jwttoken": props.match.params.jwttoken
                }
            };
            Axios(options).then(response => {
                if(isMounted){
                    if(response.data.status){
                        switch(response.data.status){
                            case 1:
                                setReset(true)
                                break;
                            case 2:
                                setMessage("This link is invalid")
                                setOpen(true)
                                break;
                            default:
                                break;
                        }
                    }else{
                        setMessage(props.t("error.1"))
                        setOpen(true)
                    }
                }
            })
            return () => { isMounted = false };
        }
    }, [props])

    const handleResetPassword = () => {
        if(newPassword && conPassword){
            if(newPassword.length >= 6){
                if(newPassword === conPassword){
                    const options = {
                        url: window.$aaa + "/resetPassword",
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "content-Type": "application/json;charset-UTF-8"
                        },
                        data: {
                            "jwttoken": props.match.params.jwttoken,
                            "newPassword": newPassword,
                        }
                    };
                    Axios(options).then(response => {
                        //console.log(response.data)
                        if(response.data.status){
                            setMessage(props.t("success.1"))
                            setOpen(true)
                        }
                    })
                }else{
                    setMessage(props.t("password.11"))
                    setOpen2(true)
                }
            }else{
                setMessage(props.t("password.6"))
                setOpen2(true)
            }
        }else{
            setMessage(props.t("info.8"))
            setOpen2(true)
        }
    }

    return (
        <div>
            {reset ? 
                <div>
                <Card>
                    <CardHeader
                        style={{ textAlign: 'center'}}
                        subheader={props.t("password.10")}
                        title={props.t("password.9")}
                    />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={3}/>
                            <Grid item md={6} xs={9} >
                                <TextField
                                    fullWidth
                                    label={props.t("password.7")}
                                    name={"new_password"}
                                    value={newPassword}
                                    onChange={(e)=> setNewPassword(e.target.value)}
                                    required
                                    variant="outlined"
                                    type="password"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={3}/>
                            <Grid item md={6} xs={9} >
                                <TextField
                                    fullWidth
                                    label={props.t("password.8")}
                                    name={"confirm_password"}
                                    value={conPassword}
                                    onChange={(e)=> setConPassword(e.target.value)}
                                    required
                                    variant="outlined"
                                    type="password"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Box
                        display="flex"
                        justifyContent="center"
                        p={2}
                    >
                        <Button color="primary" variant="contained" onClick={handleResetPassword}>
                            {props.t("reset.1")}
                        </Button>
                    </Box>
                </Card>
                <AlertDialog open={open} handleClick={handleClick} text={message} button={props.t("ok.1")}/>
                <AlertDialog2 open={open2} handleClick={handleClick2} text={message} button={props.t("ok.1")}/>
                </div>
                :
                <div>
                    <AlertDialog open={open} handleClick={handleClick} text={message} button={props.t("ok.1")}/>
                    <Loading/>
                </div>
            }
        </div>
    )
}
