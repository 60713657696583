import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {TextField, MenuItem} from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        //backgroundColor: theme.palette.common.black,
        //backgroundColor: '#36454F',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function UploadFileVDOSection(props) {
    const classes = useStyles();
    const categories = [{id : 1, cat_name: "ເສຍເງິນ", value : "0"}, {id: 2, cat_name: "ຟຣີ", value: "1"}];

    const handleVDOName = (e) => {
        props.setVideoName(e.target.value);
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table" size="small">
                    <TableHead>

                        <TableRow>
                            <StyledTableCell align="left">{props.t("doc.1")}</StyledTableCell>
                            <StyledTableCell align="center">{props.t("doc.2")}</StyledTableCell>
                            <StyledTableCell align="center">{props.t("permission.1")}</StyledTableCell>
                            <StyledTableCell align="center">{props.t("date.1")}</StyledTableCell>
                            <StyledTableCell align="center">{props.t("status.1")}</StyledTableCell>
                            <StyledTableCell align="center">{props.t("cancel.1")}</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {props.arrayDataVDO.map((VDO =>
                        <StyledTableRow key={VDO.filename}>
                                <StyledTableCell align="left">
                                    <TextField
                                        style={{width:"70%"}}
                                        variant="outlined"
                                        value={props.videoName}
                                        onChange={handleVDOName}
                                    />
                                </StyledTableCell>
                            <StyledTableCell align="center">{VDO.typeVDO}</StyledTableCell>
                            <StyledTableCell align="center">
                                <TextField
                                    style={{width:"70%"}}
                                    name="select"
                                    label="ເລືອກ"
                                    value = {props.permission || ''}
                                    onChange = {(e)=> props.setPermission(e.target.value)}
                                    select
                                    variant="outlined"
                                >
                                    {categories.map((row, index) => (
                                        <MenuItem key={index} value={row.value}>
                                            {row.cat_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </StyledTableCell>
                            <StyledTableCell align="center">{VDO.dateVDO}</StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center">
                            <IconButton onClick={()=>props.DeleteRowVDO(VDO.filename)}>
                                <DeleteIcon/>
                            </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </TableBody>
                    
                </Table>
            </TableContainer>
        </div>
    );
}