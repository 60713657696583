import React from 'react'
import RecieptReport from '../Payment/RecieptReport'

export default function Detail(props) {
  return (
    <div>
      <RecieptReport
        accessToken={props.accessToken}
        userInfo={props.userInfo}
        t={props.t}
      />
    </div>
  )
}
