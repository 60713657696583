import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ProductCardCategory from "bt_modules/ProductCard/ProductCardCategory";


const useStyles = makeStyles((theme)=>({
    root: {
        marginTop: "8vh",
        marginBottom: "3%",
        width: "82%",
        margin: "auto",
    },
    title: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: 30,
        color: theme.palette.secondary.light,
        padding: '0.5%',
        paddingLeft: '3%', 
        fontWeight: 'lighter'
    }
}));

export default function CardListGridCategory(props){
    const classes = useStyles();
    // console.log(props.cardsData);
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h5"> {props.listTitle} </Typography>
            </div>
            <Grid spacing={3} container direction="row" alignItems="center" >
                {props.cardsData.map(card => (
                    //<Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={card.id}>
                    <Grid item xs={6} sm={4} md={3} xl={2} key={card.id}>
                        <ProductCardCategory card={card} cardLink={props.cardLink} t={props.t}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

