import React from 'react'
import PaymentCancel from 'bt_modules/Purchase/PaymentCancel'

export default function PaymentCanclePage(props) {
    return (
        <div>
            <PaymentCancel {...props}/>
        </div>
    )
}
