import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: "20%"
    },
}));

export default function Register(props) {
    const classes = useStyles();

    const handleTeacher = () => {
        const options = {
            url: window.$aaa + "/teacher",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "token": props.accessToken
            }
        };
        Axios(options).then(response => {
            if(response.data){
                window.location.reload()
            }
        })
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className={classes.paper}>
                        <Typography variant="h2" component="h2" gutterBottom>
                            {props.t("Instructor.3")}
                        </Typography>
                        <Button variant="contained" color="primary" onClick={handleTeacher}>
                            {props.t("Instructor.2")}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
