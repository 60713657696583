import React, {useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import ProductDescription from '../ProductDescription/ProductDescription';
import SellerDetail from '../SellerDetail/SellerDetail';
import ProductContent from '../ProductContent/ProductContent';
import ProductActionsPanel from '../ProductActionPanel/ProductActionsPanel';
import CheckoutForm from "bt_modules/Checkout/CheckoutForm";

export default function ProductPage(props) {

    const [renderComp, setRenderComp] = useState("main");
    const [amount, setAmount] = useState(0);
    
    useEffect(() => {
        let isMounted = true;
        if(isMounted){
            props.productPromoPrice> 0 ?
                setAmount(props.productPromoPrice)
                :
                setAmount(props.productPrice)
        }
        return () => { isMounted = false };
    }, [props.productPromoPrice, props.productPrice])
    
    return (
        <div>
            {renderComp==="main" && 
                <ProductActionsPanel
                    productId={props.productId}
                    productName={props.productName} 
                    productDescription={props.productDescription}
                    productSellerId={props.productSellerId} 
                    productSeller={props.productSeller} 
                    productPrice={props.productPrice}
                    productPromoPrice={props.productPromoPrice}
                    productDiscount={props.productDiscount} 
                    productSellerText={props.productSellerText}
                    productCurrency={props.productCurrency}
                    setCartData={props.setCartData}
                    setWishList={props.setWishList}
                    setRenderComp={setRenderComp}
                    t={props.t}
                    {...props} 
                />
            }

            {renderComp==="main" &&
                <Grid style={{marginTop: 20}}>
                    <ProductDescription productDescription={props.productDescription} t={props.t}/><br/>
                    <ProductContent 
                        sectionsCount={props.sectionsCount}
                        lessonsCount={props.lessonsCount}
                        productSectionsInfo={props.productSectionsInfo}
                        setProductSectionsInfo={props.setProductSectionsInfo}
                        productLessonsInfo={props.productLessonsInfo}
                        setProductLessonsInfo={props.setProductLessonsInfo}
                        t={props.t}
                    /><br/>
                    <SellerDetail 
                        //productSellerFirstname={props.productSellerFirstname} 
                        //productSellerLastname={props.productSellerLastname}
                        productSellerName={props.productSellerName}
                        productSellerImg={props.productSellerImg}
                        productSellerBio={props.productSellerBio}
                        userImgToken={props.userImgToken}
                        t={props.t}
                    />
                </Grid>
            }

            {renderComp==="pay" && 
                <CheckoutForm
                setRenderComp={setRenderComp}
                productId={props.productId}
                payAmount={amount}
                discount={props.productDiscount}
                t={props.t}
                {...props}
                />
            }

        </div>
    )
}