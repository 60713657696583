import React, {useState} from 'react'
import { Editor } from "@tinymce/tinymce-react";
import ReactHtmlParser from "react-html-parser";
import { Button, Grid, Typography} from '@material-ui/core';

export default function TextEditor(props) {
    const [edit, setEdit] = useState(false)

    const handleEdit = () => {
        setEdit(true);
    }

    const handleCancel = () => {
        props.setValues({...props.values, biography: props.defaultValue});
        setEdit(false);
    }

    const handleEditorChange = (content) => {
        props.setValues({...props.values, biography: content});
    };

    return (
        <div>
            <Typography variant="h6" style={{padding: 10}}>{props.t("history.1")}</Typography>
            {edit ? 
                <div>
                    <Editor
                        apiKey="2fyxgq4jzeklqq6t7y96at0fcd52q4a1kbrpf8x46ya49gk4"
                        init={{
                            height: 220,
                            plugins: [
                                    "preview help",
                            ],
                            toolbar1: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify preview|",
                        }}
                        value={props.values.biography}
                        onEditorChange={handleEditorChange}
                    /><br/>
                    <Button color="primary" variant="contained" onClick={handleCancel}>{props.t("cancel.1")}</Button>
                </div>:
                <div>
                    <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <div>
                            {props.values.biography && ReactHtmlParser(props.values.biography)}
                        </div>
                    </Grid>
                    <Button color="primary" variant="contained" onClick={handleEdit}>{props.t("edit.2")}</Button>
                </div>
            }
        </div>
    )
}
