import React from 'react'
import {makeStyles} from '@material-ui/core';
import Lao from 'bt_modules/TermsAndConditions/Lao'
import English from 'bt_modules/TermsAndConditions/English'

const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: theme.palette.secondary.light,
    }
}));

export default function TermsAndConditions(props) {
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            
            {
                props.i18n.language === 'la'  ?
                    <Lao/>
                    :
                    <English/>
            }
            <br/>
        </div>
    )
}
