import React, {useEffect, useState} from 'react';
import Axios from "axios";
import CardListGridCategory from "bt_modules/CardListGrid/CardListGridCategory";
import Loading from 'bt_modules/Loading';
import Pagination from '@material-ui/lab/Pagination';
import { Grid } from '@material-ui/core';

export default function ByCategory(props) {

    const [coursesData, setCoursesData] = useState([]);
    const [catName, setCatName] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState();

    useEffect(()=> {
        window.scrollTo(0, 0)
        if(props.match.params.catId){
            let isMounted = true;
            const options = {
                url: window.$product + "/getCoursesByCat",
                method: "POST",
                headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
                },
                data:{
                    "catId": props.match.params.catId,
                    "start": '0',
                    "stop": '10',
                    "language": props.i18n.language
                }
            };
            Axios(options).then(response => {
                if(isMounted){
                    let countPage = Math.round(response.data.countPage / 10);
                    setPage(countPage)
                    if(response.data.catName){
                        setCatName(response.data.catName.cat_name);
                    }
                    let courseData = []
                    if(response.data.coursesData){
                        response.data.coursesData.map(course => (
                            courseData.push({
                                "id":course.id,
                                "name":course.name,
                                "img":course.img_id,
                                "price":course.price,
                                "currency":course.currency,
                                "promoPrice":course.promo_price,
                                "sellerName":course.firstname+" "+course.lastname,
                            })
                        ));
                    }
                    setCoursesData(courseData);
                    setLoading(false);
                }
            })
            return () => { isMounted = false };
        }
    }, [props.match.params.catId, props.i18n.language]); 
    
    const handleChange = (event, value) => {
        let start = (value * 10) - 10;
        const options = {
            url: window.$product + "/getCoursesByCat",
            method: "POST",
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data:{
                "catId": props.match.params.catId,
                "start": start,
                "stop": '10',
                "language": props.i18n.language
            }
        };
        Axios(options).then(response => {
            if(response.data.catName){
                setCatName(response.data.catName.cat_name);
            }
            
            let courseData = []
            if(response.data.coursesData){
            response.data.coursesData.map(course => (
                courseData.push({
                    "id":course.id,
                    "name":course.name,
                    "img":course.img_id,
                    "price":course.price,
                    "currency":course.currency,
                    "promoPrice":course.promo_price,
                    "sellerName":course.firstname+" "+course.lastname
                })
            ));
            }
            
            setCoursesData(courseData);
        })
    };

    return (
        <div>
            {!loading ? 
            <div>
                {coursesData.length > 0 &&
                <div>
                    {/* {JSON.stringify(coursesData)} */}
                    <CardListGridCategory cardsData={coursesData} listTitle={props.t("Categories.3")+props.t(catName+".1")} t={props.t} cardLink="/course/"/>
                    <Grid container
                        direction="row-reverse"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Pagination count={page} color="primary" onChange={handleChange}/>
                    </Grid>
                </div>
                }
            </div>
            :
            <Loading/>
            }
        </div>
    );
};
