import React, { useState, useEffect } from 'react';
import Axios from "axios";

export default function Test() {
    const [testData, setTestData] = useState([]);

    useEffect(()=>{
        const options = {
            url: window.$api + "/encrypt2",
            method: "POST",
            withCredentials: true,
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                //"access_key":$access_key,
                //"profile_id":$profile_id,
                //"transaction_uuid":uniqid(),
                //"signed_date_time":gmdate("Y-m-d\TH:i:s\Z"),
                "locale":"en",
                "transaction_type":"authorization",
                //"reference_number":(int)(rand(0, 999999)),
                "currency":"lak",
                //"device_fingerprint_id":$sid,
                "amount":100,
                "bill_to_address_country":"LA",
                "bill_to_forename":"FirstName",
                "bill_to_surname":"LastName",
                "bill_to_email":"test@bcel.com.la",
                "bill_to_phone":"2055005511",
                "bill_to_address_city":"Vientiane",
                "bill_to_address_line1":"1/23 Somewhere",
                "bill_to_address_postal_code":"01000",
                "merchant_secure_data1":"special message 1",
                "merchant_secure_data2":"special data 2",
                "merchant_secure_data3":"special data 3",

                //$params['signed_field_names'] = '';
                //$params['signed_field_names'] = implode(',', array_keys($params));
                //$params['signature'] = signParams($params, $secret_key);
            }
        };
        Axios(options).then(response => {
            setTestData(response.data);
            // alert(JSON.stringify(response.data));
        })
    },[]);

    // const HandleTest = () => {
    //     alert(JSON.stringify(testData));
    // }

    const ListArea = (props) => {

        return (
            <div>
                {/* {props.listData.map((key,value) => (
                    <div key={key}><input name={key} defaultValue={value}></input></div>
                ))} */}
                {/* {Object.entries(props.listData).map(([key, value]) => {
                    // Pretty straightforward - use key for the key and value for the value.
                    // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                    <div key={key}><input name={key} defaultValue={value}></input></div>
                    })
                } */}

                {
                  		Object.entries(props.listData).map(([keyName, value])  => {
                    		return (
                      			// <li key={keyName}>
                				// 	{keyName}{value}
                                //     {/* {console.log(OBJECTHERE[keyName])} */}
                          		// </li>
                                <div key={keyName}><label>{keyName}</label>: <input name={keyName} defaultValue={value}></input></div>
                    		)
                		})
                }

            </div>
        )
    }
    
    return (
        <div>

            <div><h1>Payment Testing</h1></div>
            {/* {testData.map(field => (
                <dive><input type='text' name='amount' value={field.amount} /></dive>
            ))} */}
            <div>
                <form action='https://testsecureacceptance.cybersource.com/oneclick/pay' method='post'>
                    <ListArea listData={testData} setTestData={setTestData}/>
                    <div><input type='submit' value='Go'/></div>
                </form>
            </div>
            {/* <script src="https://h.online-metrix.net/fp/check.js?org_id=k8vif92e&amp;session_id=<?=$merchant_id . $sid?>" type="text/javascript"></script> */}
            
        </div>
    )
}
