import React from 'react'
import {TextField} from '@material-ui/core';

export default function TextInput(props) {
    return (
        <div>
            <TextField
                fullWidth
                label={props.label}
                name={props.name}
                onChange={props.onChange}
                required
                value={props.value || ''}
                variant="outlined"
                disabled={props.disabled}
                type={props.type}
            />
        </div>
    )
}
