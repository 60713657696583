import React, {useEffect, useState} from 'react';
import Axios from "axios";
import Loading from 'bt_modules/Loading';
import CardRowCart from "bt_modules/ShopingCart/CardRowCart";
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import AlertConfirm from 'bt_modules/AlertDialog/AlertConfirm';

export default function CartPage(props) {
    const [coursesInfo, setCoursesInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [courseID, setCourseID] = useState()

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    }

    const handleConfirm = () => {
        const options = {
            url: window.$product + "/delCartItem",
            method: "POST",
            withCredentials: true,
            headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "token": props.accessToken,
                "courseId": courseID
            }
        };
        Axios(options).then(response => {
            if(response.data.status){
                const newCartData = props.cartData.filter((cart) => cart.id !== courseID);
                props.setCartData(newCartData);
                //setOpen2(false)
            }
        })
    }

    const handleCancel = () => {
        setOpen2(false)
    }

    useEffect(()=> {
        window.scrollTo(0, 0)
        if(props.accessToken){
            let isMounted = true;
            const options = {
                url: window.$product + "/getCartDataDescription",
                method: "POST",
                headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
                },
                data:{
                    "token": props.accessToken
                }
            };
            Axios(options).then(response => {
                if(isMounted){
                    if(response.data){  
                        let courseInfo = []
                        response.data.map(course => (
                            courseInfo.push({
                                "id": course.id,
                                "name": course.name,
                                "img": course.img_id,
                                "price": course.price,
                                "promoPrice": course.promo_price,
                                "discount": course.discount,
                                "description": course.description,
                                "currency": course.currency,
                                "sellerName": course.firstname+" "+course.lastname,
                                "sellerId": course.user_id
                            })
                        ));
                        setCoursesInfo(courseInfo);
                        setLoading(false);
                    }
                }
            })
            return () => { isMounted = false };
        }else{
            setOpen(true)
        }
    }, [props.accessToken]);

    const handleDelete = (id) => {
        setCourseID(id)
        setOpen2(true)
    }

    return (
    <div>
        {props.accessToken ? 
            <div>
                {!loading ?
                    <div>
                        <CardRowCart cardsInfo={coursesInfo} handleDelete={handleDelete} receiverAccount={"123456789"} t={props.t} {...props} />
                        <AlertConfirm open={open2} handleConfirm={handleConfirm} handleCancel={handleCancel} text={props.t("delete.2")} button1={props.t("ok.1")} button2={props.t("cancel.1")}/>
                    </div>
                    :
                    <Loading />
                }
            </div>
            :
            <div> 
                <AlertDialog open={open} handleClick={handleClick} text={props.t("login.4")} button={props.t("ok.1")}/>
                <Loading /> 
            </div>
        }
    </div>
    )
}
