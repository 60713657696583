import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Toolbar} from '@material-ui/core';
import { NavLink } from "react-router-dom";
//import CircleIcon from "bt_modules/CircleIcon/CircleIcon";
//import ProfileMenu from "bt_modules/ProfileMenu/ProfileMenu";
//import PopupItemList from "bt_modules/PopupItemList/PopupItemList";
//import { ShoppingCartOutlined, Favorite as FavoriteIcon, CategoryOutlined, ShopTwoOutlined, VideoCallOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    SiteTitleWrap: {
        display: "flex",
        flexDirection: "row",
    },
    SiteTitle: {
        width: "100%",
        marginRight: theme.spacing(2),
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
}));

export default function Navbar(props) {
    const classes = useStyles();

    return (
        <div style={{ paddingBottom: 70}}>
        <AppBar position="fixed" color="primary">
            <Toolbar>
            <NavLink to={"/home"} style={{ textDecoration: "none"}}>
                {props.imgPath ? 
                <div className={classes.SiteTitleWrap}>
                    <img src={props.imgPath} alt="" className={classes.SiteTitle} />
                </div>
                :
                <div className={classes.SiteTitleWrap}>
                    <h1 className={classes.SiteTitle} >ຫົວຂໍ້ເວັບໄຊ້</h1>
                </div>
                }
            </NavLink>
            
            <div className={classes.grow} />

            {/*props.accessToken && <ProfileMenu 
                setAccessToken={props.setAccessToken}
                setCartData={props.setCartData}
                userImg={props.userImg}
                userFirstname={props.userFirstname}
                userEmail={props.userEmail}
                setUserInfo={props.setUserInfo}
                setWishList={props.setWishList} 
            />*/}
            </Toolbar>
        </AppBar>
        </div>
    );
}

