import React, {useState} from 'react'
import { Box, Button, Card, CardContent, TextField, Grid, CardHeader} from '@material-ui/core';
import Axios from "axios";
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

export default function Reset(props) {
    const [email, setEmail] = useState("")
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")

    const handleClick = () => {
        setOpen(false)
    }

    const handleSendEmail = () => {
        if(email){
            const options = {
                url: window.$aaa + "/sendResetEmail",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "email": email
                }
            };
            Axios(options).then(response => {
                if(response.data.emailVerify === 1){
                    setMessage(props.t("email.3"))
                    setOpen(true)
                }else{
                    setMessage(props.t("error.1"))
                    setOpen(true)
                }
            })
        }else{
            setMessage(props.t("info.8"))
            setOpen(true)
        }
    }

    return (
        <div>
            <Card>
                <CardHeader
                    style={{ textAlign: 'center'}}
                    subheader={props.t("account.3")}
                    title={props.t("account.2")}
                />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={3}/>
                        <Grid item md={6} xs={9} >
                            <TextField
                                fullWidth
                                label={props.t("email.1")}
                                name="email"
                                value={email}
                                onChange={(e)=> setEmail(e.target.value)}
                                required
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Box
                    display="flex"
                    justifyContent="center"
                    p={2}
                >
                    <Button color="primary" variant="contained" onClick={handleSendEmail}>
                        {props.t("send.1")}
                    </Button>
                </Box>
            </Card>
            <AlertDialog open={open} handleClick={handleClick} text={message} button={props.t("ok.1")}/>
        </div>
    )
}
