import React, {useLayoutEffect, useState} from 'react';
import ProductPage from "bt_modules/ProductPage/ProductPage";
import Axios from 'axios';
import Loading from 'bt_modules/Loading';

export default function CoursePage(props) {
    const [coursesInfo, setCoursesInfo] = useState([]);
    const [productSectionsInfo, setProductSectionsInfo] = useState([]);
    const [productLessonsInfo, setProductLessonsInfo] = useState([]);
    const [sectionsCount, setSectionsCount] = useState();
    const [lessonsCount, setLessonsCount] = useState();
    const [loading, setLoading] = useState(true);
    
    useLayoutEffect(()=> {
        window.scrollTo(0, 0)
        let isMounted = true;
        if(props.match.params.courseId){
        const options = {
            url: window.$product + "/getCourseInfoCourseContent",
            method: "POST",
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data:{
                "courseId": props.match.params.courseId,
                "language": props.i18n.language
            }
            };
            Axios(options).then(response => {
                if (isMounted){
                    if(response.data.status){
                        if(response.data.courseInfo){
                            setCoursesInfo(response.data.courseInfo);
                        }
                        setSectionsCount(response.data.sectionsCount);
                        setLessonsCount(response.data.lessonsCount)
                        if(response.data.sectionsInfo){
                            let sectionsInfo = [];
                            response.data.sectionsInfo.map(sectionInfo => (
                                sectionsInfo.push({
                                    "sectionId":sectionInfo.id,
                                    "sectionName": sectionInfo.section_name})
                            ));
                            setProductSectionsInfo(sectionsInfo)
                        }
                        if(response.data.lessonsInfo){    
                            let lessonsInfo = []
                            response.data.lessonsInfo.map(lessonInfo => (
                                lessonsInfo.push({
                                    "subSectionId": lessonInfo.id,
                                    "subSectionName": lessonInfo.name,
                                    "subSectionParentID": lessonInfo.section_id,
                                    "subSectionFreeItem": lessonInfo.free_lesson,
                                    "subSectionDuration": lessonInfo.duration,
                                    "subSectionCloudName": lessonInfo.cloud_name
                                })
                            ));
                            setProductLessonsInfo(lessonsInfo);
                        }
                        setLoading(false);
                    }
                }
            })
        }
        
        return () => { isMounted = false };
    }, [props.match.params.courseId, props.i18n.language]);

    const delWishLish = (id) => {
        const options = {
            url: window.$product + "/delWishItem",
            method: "POST",
            withCredentials: true,
            headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "token": props.accessToken,
                "courseId": id
            }
        };
        Axios(options).then(response => {
            if(response.data.status) {
                const newWishData = props.wishList.filter((wish) => Number(wish.id) !== Number(id));
                props.setWishList(newWishData);
            }
        })
    }

    return (
        <div>
            {!loading ? 
                <ProductPage
                productId={props.match.params.courseId}
                productName={coursesInfo.name}
                productDescription={coursesInfo.description}
                productSellerText={props.t("publisher.1")}
                productPrice={coursesInfo.price}
                productPromoPrice={coursesInfo.promo_price}
                productDiscount={coursesInfo.discount}
                productSellerId = {coursesInfo.publisher_id}
                productSellerName = {coursesInfo.firstname+" "+coursesInfo.lastname}
                productSellerImg={coursesInfo.img_id}
                productSellerBio={coursesInfo.biography}
                productCurrency={coursesInfo.currency}
                userImgToken={coursesInfo.userImgToken}
                sectionsCount={sectionsCount}
                lessonsCount={lessonsCount}
                productSectionsInfo={productSectionsInfo}
                setProductSectionsInfo={setProductSectionsInfo}
                productLessonsInfo={productLessonsInfo}
                setProductLessonsInfo={setProductLessonsInfo}
                setCartData={props.setCartData}
                setWishList={props.setWishList}
                delWishLish={delWishLish}
                receiverAccount={"123456789"}
                t={props.t}
                {...props}
                />
                :
                <Loading />
            }
            
        </div>
    )
}
