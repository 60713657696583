import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import FileUpload from "./FileUpload"

export default function AlertDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
                <AddAPhotoIcon />
            </IconButton>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography style={{ textAlign: "center", fontSize: 22 }} >
                        {props.uploadTitle}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <FileUpload handleClose={handleClose} courseID={props.courseID} img_id={props.img_id} accessToken={props.accessToken} t={props.t}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}
