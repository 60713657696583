import React, { useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Drawer, MenuItem, CssBaseline, Typography, ListItem, ListItemIcon, AppBar, Toolbar, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import Logo from './Logo.js';
import InstructorMenu from "./InstructorMenu";
import HelpIcon from '@material-ui/icons/Help';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import HomeIcon from '@material-ui/icons/Home';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    bg:{
        backgroundColor: theme.palette.primary.main,
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: theme.palette.secondary.light,
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.secondary.light,
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    Button: {
        marginLeft: theme.spacing(2),
        width: 150,
        fontSize: 16,
        display: 'flex',
    },
}));

function Teacher(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(null);

    const handleDrawerClose = () => {
        setOpen(null);
    };

    const handleDrawerOpen = (event) => {
        if (open !== event.currentTarget) {
            setOpen(event.currentTarget);
        }
    };

    return (
        <Grid className={classes.root}>
            <CssBaseline />
                <Grid className={classes.root}>
                    <Grid
                        onMouseEnter={handleDrawerOpen}
                        onMouseLeave={handleDrawerClose}>
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: open,
                                    [classes.drawerClose]: !open,
                                }),
                            }}
                        >
                            {open ?
                                <NavLink to="/instructor" style={{ textDecoration: "none" }} >
                                    <MenuItem style={{ marginLeft: -10}} className={classes.bg} >
                                        <Logo />
                                    </MenuItem>
                                </NavLink>
                                :
                                <NavLink to="/instructor" style={{ textDecoration: "none" }} >
                                    <MenuItem style={{ marginLeft: -10, height: '160%' }} className={classes.bg}>
                                        <Logo />
                                    </MenuItem>
                                </NavLink>
                            }

                            {open ?
                                <div>

                                    <NavLink to="/instructor" style={{ textDecoration: "none" }}>
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <HomeIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("home.3")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>

                                    <NavLink to="/instructor/createcourse" style={{ textDecoration: "none" }}>
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <AddIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("course.4")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>

                                    <NavLink to="/instructor/unpublished_course" style={{ textDecoration: "none" }} >
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <AutorenewIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("course.16")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>

                                    <NavLink to="/instructor/penddingcourse" style={{ textDecoration: "none" }} >
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <HourglassEmptyIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("unpublish.1")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>

                                    <NavLink to="/instructor/published_course" style={{ textDecoration: "none" }} >
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <AllInboxIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("course.5")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>

                                    <NavLink to="/instructor/help" style={{ textDecoration: "none" }} >
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <HelpIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("course.6")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>
                                </div>
                                :
                                <div style={{marginTop: '20%'}}>
                                    
                                    <NavLink to="/instructor" style={{ textDecoration: "none" }}>
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <HomeIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("course.3")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>
                                    
                                    <NavLink to="/instructor/createcourse" style={{ textDecoration: "none" }}>
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <AddIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("course.4")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>

                                    <NavLink to="/instructor/unpublished_course" style={{ textDecoration: "none" }} >
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <AutorenewIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("course.16")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>

                                    <NavLink to="/instructor/penddingcourse" style={{ textDecoration: "none" }} >
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <HourglassEmptyIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("unpublish.1")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>

                                    <NavLink to="/instructor/published_course" style={{ textDecoration: "none" }} >
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <AllInboxIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("course.5")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>

                                    <NavLink to="/instructor/help" style={{ textDecoration: "none" }} >
                                        <MenuItem>
                                            <ListItem style={{ marginLeft: -15 }} component="div">
                                                <ListItemIcon>
                                                    <HelpIcon color="primary" />
                                                </ListItemIcon>
                                                <Typography color="primary" >
                                                    {props.t("course.6")}
                                                </Typography>
                                            </ListItem>
                                        </MenuItem>
                                    </NavLink>
                                </div>
                            }
                        </Drawer>
                    </Grid>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <AppBar color="primary">
                            <Toolbar className={classes.toolbar}>
                            <NavLink to="/home" style={{ textDecoration: "none" }} >
                                <Button className={classes.button}>
                                    <Typography color="secondary" noWrap >
                                        {props.t("home.1")}
                                    </Typography>
                                </Button>
                                </NavLink>
                                <InstructorMenu firstname={props.firstname} email={props.email} imgID={props.imgID} accessToken={props.accessToken} t={props.t}/>
                            </Toolbar>
                        </AppBar>
                    </main>
                </Grid> 
        </Grid>
    );
}

export default withRouter(Teacher)



