import React from 'react';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import InstructorCourseCard from "./InstructorCourseCard"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '95%',
    },
    boxTitle: {
        fontWeight: 'lighter', 
        backgroundColor: theme.palette.primary.main
    },
    text: {
        color: theme.palette.secondary.light, 
        marginTop:'0.5%'
    }
  }));

const InstructorCourse = (props) => {
    const classes = useStyles();

    return (
        <Grid className={classes.root}>
            <Container maxWidth={false}>
                <Grid container direction="column" justifyContent="center" alignItems="center" >
                    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.boxTitle}>
                        <Typography variant="h4" gutterBottom className={classes.text}> {props.title}</Typography>
                    </Grid>
                    <br/>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <InstructorCourseCard accessToken={props.accessToken} status={props.status} courseInfo={props.courseInfo} setCourseInfo={props.setCourseInfo} handleDelete={props.handleDelete} manage={props.manage} t={props.t}/>
                    </Grid>
                </Grid>
            </Container>
        </Grid>

        
    );
};

export default InstructorCourse;

