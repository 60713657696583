import React from 'react';
import { Container, Grid, Typography, Card, CardActionArea,CardContent} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    paper: {
        padding: theme.spacing(2),
        margin: '1%',
        maxWidth: "100%",
    },
    input: {
        display: 'none',
        },
    media: {
        height: 1,
    },
    CardContent:{
        textAlign: 'center',
    },
    card: {
        backgroundColor: theme.palette.primary.main,
    }
}));

export default function ContentType(props) {
    const classes = useStyles();
    return (
        <div>
            <Container>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Card className={classes.card} onClick={()=>{props.handleContentTypeVideo(props.sectionID);}}>
                        <CardActionArea style={{}}>
                            <CardContent>
                                <Typography variant="body2" style={{textAlign: 'center', color: '#fff'}}>
                                    {props.t("upload.2")}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.card} onClick={()=>{props.handleContentTypeVideoSlide(props.sectionID);}}>
                        <CardActionArea style={{}}>
                            <CardContent>
                                <Typography variant="body2" style={{textAlign: 'center', color: '#fff'}}>
                                    {props.t("upload.3")}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            </Container>
        </div>
    )
}