import React from 'react'
import Navbar from 'bt_modules/AdminNavbar/Navbar'

export default function SiteBar(props) {
    return (
        <div>
            <Navbar
            imgPath={window.$img+"/logo.png"}
            />
        </div>
    )
}
