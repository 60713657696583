import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Axios from "axios";
import Loading from 'bt_modules/Loading';
import jwt_decode from 'jwt-decode';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: "20%"
    },
}));

export default function Verify(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    }

    useEffect(() => {
        let isMounted = true;
        let decoded = jwt_decode(props.match.params.jwttoken);
        var today = new Date();
        if(decoded.exp * 1000 < today.getTime()){
            setOpen(true)
        }else{
            const options = {
                url: window.$aaa + "/verifyEmail",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "jwttoken": props.match.params.jwttoken
                }
            };
            Axios(options).then(response => {
                if(isMounted){
                    if(response.data.status){
                        setLoading(false)
                    }
                }
            })
            return () => { isMounted = false };
        }
    }, [props.match.params.jwttoken])

    return (
        <div className={classes.root}>
            {!loading ?
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.paper}>
                            <Typography variant="h2" component="h2" gutterBottom>
                                {props.t("success.1")}
                            </Typography>
                            <Button variant="contained" color="primary" onClick={()=> window.location = "/home"}>
                                {props.t("home.1")}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                :
                <div>
                    <AlertDialog open={open} handleClick={handleClick} text={props.t("This link is expired")} button={props.t("ok.1")}/>
                    <Loading/>
                </div>
            }
        </div>
    )
}
