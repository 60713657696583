import React, { useEffect, useState } from 'react';
import InfoCard from "bt_modules/Dashboard/InfoCard";
import GridItem from "bt_modules/Dashboard/Grid/GridItem.js";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import NumberFormat from 'react-number-format';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button'
import Axios from "axios";
import Loading from 'bt_modules/Loading';


const StyledTableCell = withStyles((theme) => ({
    head: {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.secondary.dark,
        //color: theme.palette.common.white,
        color: theme.palette.secondary.light
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginRight: "5%"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        fontSize: 26,
        marginTop: 20,
        marginBottom: 20
    },
    table: {
        minWidth: 700,
    },
    titleContent: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: 20,
        color: theme.palette.secondary.light,
        paddingLeft: '2%',
        padding: '0.5%'
    },
    bg: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
        width: '95%',
        paddingLeft: '2%'
    }
}));

export default function MonthySales(props) {
    const history = useHistory();
    const classes = useStyles();
    const [priceMonth, setPriceMonth] = useState([]);
    const [loading, setLoading] = useState(true)
    const [sumPriceMonth, setSumPriceMonth] = useState()

    useEffect(() => {
        if (props.accessToken) {
            let isMounted = true;
            const options = {
                url: window.$report + "/getAllInstructorStats",
                method: "POST",
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    token: props.accessToken
                }
            };
            Axios(options).then(response => {
                if (isMounted) {
                    //console.log(response.data.sumPriceTodayIncome)
                    setPriceMonth(response.data.monthIncome)
                    setSumPriceMonth(response.data.sumPriceMonth)
                    setLoading(false);
                }
            })
            return () => { isMounted = false };
        }
    }, [props.accessToken])

    return (
        <div>
        {!loading ? 
            <Grid className={classes.root}>
                <Button onClick={() => history.goBack()} variant="contained" color="primary">
                    <div style={{ width: '100%', borderRadius: 8,display: 'flex'}} >
                        <ArrowBackIcon style={{textAlign: 'center'}}/>ກັບຄືນ
                    </div>
                </Button><br/><br/>
                <div className={classes.titleContent}>
                    <Typography variant="h4"> ຍອດຂາຍເດືອນນີ້  </Typography>
                </div>
                <GridItem xs={12} sm={12} md={12}>
                    <InfoCard
                        withdraw="withdraw"
                        color="warning"
                        title={"ຍອດຂາຍມື້ວານນີ້"}
                        value={<NumberFormat value={sumPriceMonth} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("currencies.1")} renderText={value => <div>{value}</div>} />}
                        icon={<DateRangeIcon />}
                        link={"/instructor/yesterdaySales"}
                        t={props.t}
                    />
                </GridItem>
                <div className={classes.titleContent}>
                    <Typography variant="h4"> ລາຍລະອຽດ  </Typography>
                </div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">ຊື່ຫຼັກສູດ</StyledTableCell>
                                <StyledTableCell align="center">ເວລາສັ່ງຊື້</StyledTableCell>
                                <StyledTableCell align="center">ລາຄາ&nbsp;(ກີບ)</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {priceMonth.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                                    <StyledTableCell align="center">{row.time}</StyledTableCell>
                                    <StyledTableCell align="center">{Number(row.instructor_share).toLocaleString()}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            :
            <Loading />
        }
        </div>
    );
}