import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, TextField, Typography, MenuItem } from '@material-ui/core';
//import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Axios from "axios";
import CircleIcon from "bt_modules/CircleIcon/CircleIcon";
import { PersonAdd as PersonAddIcon } from '@material-ui/icons';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import LoadingAuth from './LoadingAuth';
import AlertTermAndCon from 'bt_modules/AlertDialog/AlertTermAndCon';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
    },
    space: {
        marginLeft: theme.spacing(1),
        width: "100%"
    },
}));

export default function SignupPopup(props) {
    const classes = useStyles();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [isFirstnameValidated, setFirstnameValidated] = useState(true);
    const [isLastnameValidated, setLastnameValidated] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [locale, setLocale] = useState("")
    const [isEmailValidated, setEmailValidated] = useState(true);
    const [isPassValidated, setPassValidated] = useState(true);
    const [isLocaleValidated, setLocaleValidated] = useState(true);
    const [message, setMessage] = useState("");
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [loading, setLoading] = useState(true);
    const [openTermDialog, setOpenTermDialog] = useState(false)

    const language = [
        {id: 0, language: "English", value: "en"},
        {id: 0, language: "ไทย", value: "th"},
        {id: 0, language: "Tiếng Việt", value: "vn"},
        {id: 0, language: "ລາວ", value: "la"},
    ]

    const handleClick = () => {
        setOpenAlert(false)
        props.setOpen(false);
    }

    const handleChangeFirstname = (e) => {
        setFirstname(e.target.value)
    }

    const handleChangeLastname = (e) => {
        setLastname(e.target.value)
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleChangeLocale = (e) => {
        setLocale(e.target.value)
    }

    const validateEmail = (email) => {
        let regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    };

    const validatePass = (pass) => {
        if (pass.length > 5) {
            return true;
        } else { return false; }
    };

    const validateEmpty = (somevar) => {
        if (somevar === "") {
            return false;
        } else { return true; }
    };

    const handleSignup = () => {
        setOpenTermDialog(false)
        setLoading(false)
        const options = {
            url: window.$aaa + "/signup",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8"
            },
            data: {
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "password": password,
                "locale": locale
            }
        };
        Axios(options).then(response => {
            if (response.data.signupStatus) {
                let $signupStatus = response.data.signupStatus;
                switch ($signupStatus) {
                    case 3:
                        setLoading(true);
                        setAlertMessage(props.t("error.1"));
                        setOpenAlert(true)
                        break;
                    case 2:
                        setLoading(true);
                        setAlertMessage(props.t("email.4"));
                        setOpenAlert(true)
                        break;
                    case 1:
                        setLoading(true);
                        setAlertMessage(props.t("register.4"));
                        setOpenAlert(true)
                        break;
                    case 0:
                        setLoading(true);
                        setAlertMessage(props.t("error.1"));
                        setOpenAlert(true)
                        break;
                    default:
                        break;
                }
            } else {
                setMessage(props.t("error.1"));
            }
        });
    }

    const handleCheck = () => {
        if (validateEmpty(firstname)) {
            setFirstnameValidated(true);
            if (validateEmpty(lastname)) {
                setLastnameValidated(true);
                if(validateEmpty(locale)) {
                    setLocaleValidated(true)
                    if (validateEmail(email)) {
                        setEmailValidated(true);
                        if (validatePass(password)) {
                            setPassValidated(true);
                            setOpenTermDialog(true)
                        } else {
                            setPassValidated(false);
                        }
                    } else {
                        setEmailValidated(false);
                    }
                }else{
                    setLocaleValidated(false)
                }
            } else {
                setLastnameValidated(false);
            }
        } else {
            setFirstnameValidated(false);
        }
    }

    const handlePress = (e) => {
        if (e.key === "Enter") {
            handleCheck()
        }
    }

    const onClose = () => {
        if(loading){
            props.setOpen(false)
        }
    }

    return (
        <div onKeyPress={handlePress}>
            <CircleIcon iconImg={<PersonAddIcon color="primary" />} setOpen={props.setOpen} tooltip={props.t("register.1")} />
            <Dialog open={props.open} onClose={onClose} aria-labelledby="form-dialog-title" scroll="body">
                <DialogTitle id="form-dialog-title" className={classes.center} style={{backgroundColor: '#36454F',}}><img src={props.logo} alt="props.logo" /></DialogTitle>
                <DialogContent style={{backgroundColor: '#C0C0C0'}}>
                    {!loading ?
                        <LoadingAuth />
                        :
                        null
                    }
                    <DialogContentText className={classes.center} style={{color: '#000'}}>
                        {props.t("register.2")}
                    </DialogContentText>
                    <Typography color="error">{message}</Typography>
                    <div >
                        <TextField
                            variant="outlined"
                            required
                            type="text"
                            label={props.t("name.1")}
                            margin="dense"
                            error={!isFirstnameValidated}
                            helperText={!isFirstnameValidated ? props.t("name.3") : ""}
                            className={classes.space}
                            onChange={handleChangeFirstname}
                            disabled={!loading}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            required
                            type="text"
                            label={props.t("name.2")}
                            margin="dense"
                            error={!isLastnameValidated}
                            helperText={!isLastnameValidated ? props.t("name.4") : ""}
                            className={classes.space}
                            onChange={handleChangeLastname}
                            disabled={!loading}
                        />
                    </div>

                    <div>
                        <TextField
                            label={props.t("info.2")+"/"+props.t("language.1")}
                            name='locale'
                            select
                            onChange={handleChangeLocale}
                            required
                            value={locale || ""}
                            error={!isLocaleValidated}
                            helperText={!isLocaleValidated ? "Please slect your locale" : ""}
                            className={classes.space}
                            variant="outlined"
                            disabled={!loading}
                        >
                            {language.map((row, index) => (
                                <MenuItem key={index} value={row.value}>
                                    {row.language}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>

                    <div>
                        <TextField
                            variant="outlined"
                            required
                            type="email"
                            label={props.t("email.1") + "(email@domain.com)"}
                            margin="dense"
                            fullWidth
                            error={!isEmailValidated}
                            helperText={!isEmailValidated ? props.t("email.2") : ""}
                            className={classes.space}
                            onChange={handleChangeEmail}
                            disabled={!loading}
                        />
                        <TextField
                            variant="outlined"
                            required
                            type="password"
                            label={props.t("password.4")}
                            margin="dense"
                            fullWidth
                            error={!isPassValidated}
                            helperText={!isPassValidated ? props.t("password.6") : ""}
                            className={classes.space}
                            onChange={handleChangePassword}
                            disabled={!loading}
                        />
                    </div>
                    <div><br /></div>
                    <div className={classes.center}>
                        <Button variant="contained" color="primary" disabled={!loading} onClick={handleCheck}>{props.t("register.1")}</Button>
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor: '#36454F',}}>
                    <Button disabled={!loading} style={{backgroundColor: '#36454F', color: '#ffff'}} onClick={() => { props.setOpen(false); props.setOpenLogin(true) }}>
                        {props.t("register.3")}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog open={openAlert} handleClick={handleClick} text={alertMessage} button={props.t("ok.1")} />
            <AlertTermAndCon logo={props.logo} i18n={props.i18n} open={openTermDialog} setOpen={setOpenTermDialog} onAgree={handleSignup}/>
        </div>
    )
}