import { Button, Container, TextField, Typography } from '@material-ui/core'
import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Axios from "axios";
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2%'
    },
    bg:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
        paddingLeft: '2%',
        padding: '4%'
    },
    bg2:{
        backgroundColor: theme.palette.secondary.light,
        padding: '5%',
        borderRadius: 8
    },
    bgText: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 8
    }
}));

export default function ContactUsPage(props) {
    const classes = useStyles();

    const [textTitle, setTextTitle] = useState("")
    const [textBody, setTextBody] = useState("")
    const [yrMail, setTextYrMail] = useState("")
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClick = () => {
        setOpen(false)
    }

    const handleTitle = (e) => {
        setTextTitle(e.target.value);
    }
    const handleBody = (e) => {
        const newText = e.target.value.replace(/'/g, "\\'")
        setTextBody(newText);
    }
    const handleMail = (e) => {
        const newText = e.target.value.replace(/'/g, "\\'")
        setTextYrMail(newText);
    
    }

    const Submit = () => {
        const options = {
            url: window.$report + "/requestcontent",
            method: "POST",
            withCredentials: true,
            headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                title: textTitle,
                body: textBody,
                mail: yrMail
            }
        };
        Axios(options).then(response => {
            //console.log(response.data)
        })
        setTextTitle("")
        setTextBody("")
        setTextYrMail("")
    }

  return (
    <Container className={classes.root}>
        <div className={classes.bg} style={{ marginBottom: '2%', padding: '0.5%', paddingLeft: '2%', }}>
            <Typography >ຕິດຕໍ່ພວກເຮົາ</Typography>
        </div>
        <div className={classes.bg2}>
            <TextField
                id="outlined-multiline-static"
                label="ຫົວຂໍ້"
                //multiline
                rows={12}
                variant="outlined"
                size="medium"
                style={{width: '40%'}}
                className={classes.bgText}
                onChange={handleTitle}
                value={textTitle || ""}
            /><br/><br/>
            <TextField
                id="outlined-multiline-static"
                label="ໃສ່ຂໍ້ມູນ"
                multiline
                rows={12}
                variant="outlined"
                size="medium"
                fullWidth
                className={classes.bgText}
                onChange={handleBody}
                value={textBody || ""}
            /><br/><br/>
            <TextField
                id="outlined-multiline-static"
                label="Your E-mail"
                //multiline
                rows={12}
                variant="outlined"
                size="medium"
                style={{width: '40%'}}
                className={classes.bgText}
                onChange={handleMail}
                value={yrMail || ""}
            />
            <div style={{textAlign: 'right'}}>
                <Button onClick={()=>{handleOpen();Submit();}} variant="contained" color="primary">ສົ່ງຂໍ້ມູນ</Button>
            </div>
        </div>
        <AlertDialog open={open} handleClick={handleClick} text={"ສຳເລັດແລ້ວ."} button={props.t('ok.1')}/>
    </Container>
  )
}
