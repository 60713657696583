import React, {useState} from 'react';
import Axios from "axios";
import {Grid, Typography, IconButton, TextField, Button, Tooltip} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import AlertDialog2 from 'bt_modules/AlertDialog/AlertDialog';
import SectionsContent from './SectionContent';
import AlertConfirm from 'bt_modules/AlertDialog/AlertConfirm';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    titleStyle: {
        padding: '0.5%',
        paddingLeft: '1%',
        maxWidth: '92%', 
        fontWeight: 'lighter', 
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 8
    },
    iconColor: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light
    }
}));

export default function CreateCourseContent(props) {
    const classes = useStyles();
    const [openSection, setOpenSection] = useState(false);
    const [sectionTitle, setSectionTitle] = useState("");
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [sectionID, setSectionID] = useState()
    const [promptText, setPromptText] = useState("")

    const handleClick = () => {
        setOpen(false)
    }
    const handleOk = () => {
        setOpenDialog(false)
    }

    const handleConfirm = () => {
        //if(props.videoList[0].cloud_folder_id.length > 0){
            const options = {
                url: window.$product + "/deleteInstructorSection",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "sectionID" : sectionID,
                    "videoArray" : props.videoList,
                    "fileArray" : props.fileList,
                    "token": props.accessToken
                }
            };
            Axios(options).then(response => {
                if(response.data.status){
                    let newArray = [...props.addNewSection];
                    const del = newArray.filter(update => update.id !== sectionID);
                    props.setAddNewSection(del);
                    setOpenConfirm(false)
                }
            })
        // }else{
        //     setOpen2(false)
        //     setOpen3(true)
        // }
        
    }

    const handleCancel = () => {
        setOpenConfirm(false)
    }

    const OpenNewSection = () => {
        setOpenSection(true);
    };

    const handleSectionTitle = (e) => {
        setSectionTitle(e.target.value);
    };

    const hasName = (arr, name) => arr.some(el => el.section_name === name);

    const AddNewSection = () =>{
        var num;
        if(props.addNewSection.length > 0){
            num = Number(props.addNewSection[props.addNewSection.length - 1].section_num)
        }else{
            num = 0;
        }
        
        if(sectionTitle){
            if(hasName(props.addNewSection, sectionTitle) === false){
                const options = {
                    url: window.$product + "/addnewsection",
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "content-Type": "application/json;charset-UTF-8"
                    },
                    data: {
                        "token": props.accessToken,
                        "section":  sectionTitle,
                        "courseID": props.courseID,
                        "section_num": Number(num) + 1
                    }
                };
                Axios(options).then(Response =>{
                    if(Response.data.id){
                        let newArray = [...props.addNewSection];
                        let tempData = {
                            id : Response.data.id,
                            section_name: sectionTitle,
                            section_num: Number(num) + 1,
                            openSec: false,
                            open: false,
                            openContentTypeVDO: false
                        }
                        newArray.push(tempData);
                        props.setAddNewSection(newArray);
                    }
                });
            }else{
                setMessage(props.t("name.7"));
                setOpen(true);
            }
            setSectionTitle("");
            setOpenSection(false);
        }else{
            setMessage(props.t("info.8"))
            setOpen(true);
        }
    }
    
    const handleDeteteSection = (id) => {
        setSectionID(id);
        const options = {
            url: window.$product + "/prepareDelInsSection",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "section_id": id
            }
        };
        Axios(options).then(response => {
            if (response.data) {
                switch(response.data.processLes) {
                    case 1:
                        setPromptText(props.t("delete.4"));
                        setOpenDialog(true);
                      break;
                    case 0:
                      switch(response.data.empty) {
                        case 1:
                            setPromptText(props.t("delete.2"));
                            setOpenConfirm(true);
                            break;
                        case 0:
                            setPromptText(props.t("delete.3"));
                            setOpenConfirm(true);
                            break;
                        default:
                            setPromptText("Unexpected Error");
                            setOpenDialog(true);
                      }
                      break;
                      default:
                        setPromptText("Unexpected Error");
                        setOpenDialog(true);
                  }
            }else{
                setPromptText("No response from server");
                setOpenDialog(true);
            }
        })
        //setOpen2(true);
    }

    return (
        <div className={classes.root}>
            {openSection === true ? (
            <div className={classes.titleStyle}>
                <Grid item container>
                    <Grid item xs={12}>
                        <Typography variant="h5" >
                            {props.t("lesson.1")}:
                        </Typography><br/>
                        <TextField 
                            label={props.t("lesson.2")}
                            variant="outlined"
                            onChange={handleSectionTitle}
                        /> 
                    </Grid>
                </Grid><br/>
                <Button variant="contained" color="primary" onClick={AddNewSection}>{props.t("add.1")}</Button>&nbsp;
                <Button variant="contained" color="secondary" onClick={()=>{setOpenSection(false);}}>{props.t("cancel.1")}</Button>
            </div>
            ):
            <div>
                {props.editable && 
                    <Tooltip title="Add Lession">
                        <IconButton onClick={OpenNewSection} className={classes.iconColor}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>
                }                           
            </div>            
            }
            <Grid>
                {props.addNewSection.map((section)=> (
                    <SectionsContent 
                        accessToken={props.accessToken}
                        sectionNum={section.section_num}
                        addNewSection={props.addNewSection} 
                        setAddNewSection={props.setAddNewSection} 
                        key={section.id} 
                        sectionID={section.id} 
                        section={section.section_name} 
                        courseID={props.courseID} 
                        handleDeteteSection={handleDeteteSection}
                        videoList={props.videoList} 
                        setVideoList={props.setVideoList} 
                        fileList={props.fileList} 
                        setFileList={props.setFileList} 
                        open={section.open} 
                        openSec={section.openSec} 
                        openContentTypeVDO={section.openContentTypeVDO} 
                        editable={props.editable}
                        t={props.t}
                    />
                ))}
            </Grid>
            <AlertDialog open={open} handleClick={handleClick} text={message} button={props.t("ok.1")}/>
            <AlertDialog2 open={openDialog} handleClick={handleOk} text={promptText} button={props.t("ok.1")}/>
            <AlertConfirm open={openConfirm} handleConfirm={handleConfirm} handleCancel={handleCancel} text={promptText} button1={props.t("ok.1")} button2={props.t("cancel.1")}/>
        </div>
    )
}