import React from 'react'
import {Dialog, Button, DialogActions,  Grid, Typography, makeStyles, DialogTitle, TextField} from '@material-ui/core';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        top: 50
    },
    loading: {
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: "100%"
    },
});

export default function AlertConfirm(props) {
    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={props.open}
                classes={{
                    paper: classes.dialog
                }}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title"/>
                    <Grid container>
                        {props.loading && 
                            <div className={classes.loading}>
                                {props.loadingImg}       
                            </div>
                        }
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" style={{marginBottom: '2%', textAlign: 'center'}}>
                                {props.text}
                            </Typography>
                        </Grid>

                        {props.useTextField && 
                            <Grid item xs={12} style={{marginBottom: '2%', textAlign: 'center'}}>
                                <TextField
                                    variant="outlined"
                                    label={props.label}
                                    value={props.value || ""}
                                    onChange={props.onChange}
                                />
                            </Grid>
                        }
                        
                        <Grid item xs={12}>
                            <DialogActions style={{ justifyContent: 'center'}}>
                                <Button onClick={props.handleConfirm} disabled={props.disableButton ? props.disableButton : false } variant="contained" color="primary" autoFocus style={{ marginBottom: '3%'}} >
                                    {props.button1}
                                </Button>
                                <Button onClick={props.handleCancel} disabled={props.disableButton ? props.disableButton : false } variant="contained" color="secondary" autoFocus style={{ marginBottom: '3%'}} >
                                    {props.button2}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
            </Dialog>
        </div>
    )
}
