import React from 'react';
import Navbar from "bt_modules/Navbar/Navbar";

export default function SiteBar(props) {

    return (
        <div>
            <Navbar 
            accessToken={props.accessToken} 
            setAccessToken={props.setAccessToken}
            imgPath={window.$img+"/logo.png"}
            cartData={props.cartData}
            setCartData={props.setCartData}
            userFirstname={props.userInfo.firstname}
            userImg={props.userInfo.img_id}
            userEmail={props.userInfo.email}
            setUserInfo={props.setUserInfo}
            wishList={props.wishList}
            setWishList={props.setWishList}
            setMyCourseData={props.setMyCourseData}
            locale={props.userInfo.locale}
            t={props.t}
            i18n={props.i18n}
            />
        </div>
    )
}
