import React, {useEffect, useState} from 'react';
import {Typography, Button, Container, Grid, makeStyles} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CardListRow from "bt_modules/CardListRow/CardListRow";
import Axios from "axios";
import Loading from 'bt_modules/Loading';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import AlertConfirm from 'bt_modules/AlertDialog/AlertConfirm';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "8vh",
        marginBottom: "2%",
        margin: "auto",
    },
    title: {
        backgroundColor: theme.palette.primary.main, 
        padding: '0.5%',
        fontWeight: 'lighter', 
        color: theme.palette.secondary.light
    },
    link: {
        color: theme.palette.secondary.light, 
        margin:20, 
        textDecoration: "none"
    }
}));

export default function WishPage(props) {
    const classes = useStyles();
    const [coursesInfo, setCoursesInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [courseID, setCourseID] = useState()

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    }

    const handleConfirm = () => {
        const options = {
            url: window.$product + "/delWishItem",
            method: "POST",
            withCredentials: true,
            headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "token": props.accessToken,
                "courseId": courseID
            }
        };
        Axios(options).then(response => {
            if(response.data.status){
                const newWishData = props.wishList.filter((wish) => wish.id !== courseID);
                props.setWishList(newWishData);
                //setOpen2(false)
            }
        })
    }

    const handleCancel = () => {
        setOpen2(false)
    }

    useEffect(()=> {
        window.scrollTo(0, 0)
        if(props.accessToken){
            let isMounted = true;
            const options = {
                url: window.$product + "/getWishDataDescription",
                method: "POST",
                headers:{
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data:{
                    "token": props.accessToken
                }
            };
            Axios(options).then(response => {
                if(isMounted){
                    if(response.data){  
                        let courseInfo = []
                        response.data.map(course => (
                            courseInfo.push({
                                "id": course.id,
                                "name": course.name,
                                "img": course.img_id,
                                "price": course.price,
                                "promoPrice": course.promo_price,
                                "discount": course.discount,
                                "description": course.description,
                                "currency": course.currency,
                                "sellerName": course.firstname+" "+course.lastname,
                            })
                        ));
                        setCoursesInfo(courseInfo);
                        setLoading(false);
                    }
                }
            })
            return () => { isMounted = false };
        }else{
            setOpen(true)
        }
    }, [props.accessToken]);

    const handleDelete = (id) => {
        setCourseID(id)
        setOpen2(true)
    }

    return (
        <div className={classes.root}>
            {!loading ? 
            <div>
            {coursesInfo.length > 0 ? 
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12} className={classes.title}>
                            <Typography variant="h5" component="h2" style={{paddingLeft: '3%'}}>
                                {props.t("have.1")} {coursesInfo.length} {props.t("wishlist.3")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                            <CardListRow cardsInfo={coursesInfo} cardLink="/course/" textLen={270} handleDelete={handleDelete} delLink="/wish" t={props.t}/>
                        </Grid>
                    </Grid>
                    <AlertConfirm open={open2} handleConfirm={handleConfirm} handleCancel={handleCancel} text={props.t("delete.2")} button1={props.t("ok.1")} button2={props.t("cancel.1")}/>
                </Container>
                :
                <Grid container>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <img src={window.$img+'/empty_cart.jpg'} alt=''/>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center', padding: 40}}>
                            <Typography variant="h4">{props.t("wishlist.5")}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <NavLink to={"/home"} className={classes.link}>
                                <Button variant="contained" color="primary" style={{width: 150}}>
                                    {props.t("home.1")}
                                </Button>
                            </NavLink>
                        </Grid>
                    </Grid>
            }
            </div>
            :
            <div>
            <AlertDialog open={open} handleClick={handleClick} text={props.t("login.4")} button={props.t("ok.1")}/>
            <Loading/>
            </div>
            }
        </div>
    )
}
