import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import ReactPlayer from 'react-player';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

    const handleClose = () => {
        props.setOpenDialogVDOUnpublish(false);
        props.setVideoFile("")
    };
     
    return (
    <div>

        <Dialog
            open={props.openDialogVDOUnpublish}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth={false}
            
        >
            {props.videoFile && <ReactPlayer 
            url={window.$playback + '/viewInstructorVideo/'+ props.accessToken + "/" + props.lesssonID + "/" + props.videoFile} 
            controls
            playing={props.openDialogVDOUnpublish}
            config={{ file: { 
                attributes: {
                    controlsList: 'nodownload',
                    onContextMenu: e => e.preventDefault()
                }    
            }}}
            />}

        </Dialog>
    </div>
    );
}