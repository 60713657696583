import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Cropper from "./Cropper"

export default function AlertDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Typography variant="h6" color="primary" onClick={handleClickOpen}>
                {props.buttonName}
            </Typography>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography style={{ textAlign: "center", fontSize: 22 }} >
                        {props.uploadTitle}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Cropper accessToken={props.accessToken} handleClose={handleClose} img_id={props.img_id} t={props.t} />
                </DialogContent>
            </Dialog>
        </div>
    );
}
