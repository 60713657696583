import React from 'react'
import Navbar from 'bt_modules/InstructorNavbar/Navbar'

export default function SiteBar(props) {
    return (
        <div>
            <Navbar
                firstname={props.userInfo.firstname} 
                email={props.userInfo.email} 
                imgID={props.userInfo.img_id}
                accessToken={props.accessToken}
                t={props.t}
            />
        </div>
    )
}
