import React, {useState, useEffect} from 'react'
import { makeStyles, Typography,} from '@material-ui/core';
import Axios from "axios";
import InstructorCourseCard from 'bt_modules/InstructorCourse/InstructorCourseCard';
import Loading from 'bt_modules/Loading';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginLeft: "5%"
    },
    textColor: {
        color: theme.palette.secondary.light,
        marginTop:'0.5%',
        textAlign: 'center'
    },
    titleStyle: {
        padding: '0.5%',
        paddingLeft: '1%',
        maxWidth: '92%', 
        fontWeight: 'lighter', 
        backgroundColor: theme.palette.primary.main
    },
    paper: {
        padding: '1%',
        spacing: 20,
         width: '92%',
         marginTop: '0.5%'
    },
}));

export default function PenddingCourse(props) {
    const classes = useStyles();
    const [courseInfo, setCourseInfo] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.accessToken) {
            let isMounted = true;
            const options = {
                url: window.$product + "/getInstructorCourse",
                method: "POST",
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "token": props.accessToken,
                    "status": 1
                }
            };
            Axios(options).then(response => {
                if (isMounted) {
                    if (response.data) {
                        setCourseInfo(response.data);
                        setLoading(false);
                    }
                }
            })
            return () => { isMounted = false };
        }
    }, [props.accessToken])


  return (
    <div className={classes.root}>
        {!loading ?
            <>
            {courseInfo.length > 0 ?
                <>
                    <div className={classes.titleStyle}>
                        <Typography variant="h5" gutterBottom className={classes.textColor}>{props.t("unpublish.1")}</Typography>
                    </div>
                    <div className={classes.paper}>
                    <InstructorCourseCard courseInfo={courseInfo} t={props.t} status={1}/>
                    </div>
                </>
                :
                <div style={{width: '90%', textAlign: 'center', justifyContent: 'center', marginTop: '20%'}}>
                    <Typography variant="h3" gutterBottom color="primary">
                        {props.t("course.10")}
                    </Typography>
                </div>
            }
            </>
        :
            <Loading/>
        }
    </div>
  )
}
