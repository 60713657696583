import React from 'react'
import {TextField} from '@material-ui/core';

export default function EditPassword(props) {
    return (
        <div>
            <TextField
                variant="outlined"
                required
                name="currentPassword"
                type="password"
                label={props.t("password.5")}
                fullWidth
                error={props.isCurrentPasswordValidated}
                helperText={props.isCurrentPasswordValidated ? props.t("password.6") : ""}
                onChange={props.onChange}
                margin="dense"
                value={props.value.currentPassword || ''}
                disabled={props.loading}
            />
            <TextField
                variant="outlined"
                required
                name="newPassword"
                type="password"
                label={props.t("password.7")}
                fullWidth
                margin="dense"
                error={props.isNewPasswordValidated}
                helperText={props.isNewPasswordValidated ? props.t("password.6") : ""}
                onChange={props.onChange}
                value={props.value.newPassword || ''}
                disabled={props.loading}
            />
            <TextField
                variant="outlined"
                required
                name="confirmPassword"
                type="password"
                label={props.t("password.8")}
                fullWidth
                margin="dense"
                error={props.isConfirmPasswordValidated}
                helperText={props.isConfirmPasswordValidated ? props.t("password.6") : ""}
                onChange={props.onChange}
                value={props.value.confirmPassword || ''}
                disabled={props.loading}
            />
        </div>
    )
}
