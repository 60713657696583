import React, {useEffect, useState} from 'react';
import InfoCardWithdraw from "bt_modules/Dashboard/InfoCardWithdraw";
import GridItem from "bt_modules/Dashboard/Grid/GridItem.js";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import NumberFormat from 'react-number-format';
import Axios from "axios";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button'
import AlertConfirm from 'bt_modules/AlertDialog/AlertConfirm';

const StyledTableCell = withStyles((theme) => ({
    head: {
        //backgroundColor: theme.palette.common.black,
        backgroundColor: theme.palette.secondary.dark,
        //color: theme.palette.common.white,
        color: theme.palette.secondary.light
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginRight: "5%",
        marginBottom: 30,
        padding: '2%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        fontSize: 26,
        marginTop: 20,
        marginBottom: 20
    },
    table: {
        minWidth: 700,
    },
    titleContent: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: 20,
        color: theme.palette.secondary.light,
        paddingLeft: '2%',
        padding: '0.5%'
    }
}));

export default function Withdraw(props) {
    const history = useHistory();
    const classes = useStyles();
    const [amount, setAmount] = useState(0)
    const [requestWithdraw, setRequestWithdraw] = useState([])
    const [completWithdraw, setCompletWithdraw] = useState([])
    const [open, setOpen] = React.useState(false);
    const [cancelWithdraw, setCancelWithdraw] = useState([])
    const [openUPStatusWDDialog, setOpenUPStatusWDDialog] = useState(false)
    const [getID, setGetID] = useState()

    useEffect(() => {
        if (props.accessToken) {
            let isMounted = true;
            const options = {
                url: window.$report + "/getAllInstructorStats",
                method: "POST",
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    token: props.accessToken
                }
            };
            Axios(options).then(response => {
                if (isMounted) {
                    //let timestamp = Math.floor(Date.now() / 1000);
                    //var date = new Date(timestamp * 1000);
                    //var hours = date.getHours();
                    //var minutes = date.getMinutes();
                    //var seconds = date.getSeconds();

                    if(response.data.withdraw){
                        let requestWithdraw = [];
                        let completWithdraw = [];
                        response.data.withdraw.forEach(el => {
                            if(el.status === 0){
                                requestWithdraw.push(el)
                            }else{
                                completWithdraw.push(el)
                            }
                        });
                        setRequestWithdraw(requestWithdraw)
                        setCompletWithdraw(completWithdraw)
                        setCancelWithdraw(response.data.withdrawCancl)
                    }
                }
            })
            return () => { isMounted = false };
        }
    }, [props.accessToken])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const withdraw = () => {
        if(amount > 0 && props.userInfo.wallet >= amount){
            const options = {
                url: window.$payment + "/withdraw",
                method: "POST",
                withCredentials: true,
                headers:{
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    balance: amount,
                    token: props.accessToken
                }
            };
            Axios(options).then(response => {
                if(response.data){
                    props.setUserInfo({...props.userInfo, wallet: props.userInfo.wallet - amount})
                    setOpen(false)
                    alert(response.data)
                }
            })
        }else{
            alert("ຍອດເງິນໃນບັນຊີບໍ່ພຽງພໍ")
        }
    }

    const OpenDilogTryAgain = (id) => {
        setGetID(id)
        setOpenUPStatusWDDialog(true)
    }

    const handleClose2 = () => {
        setOpenUPStatusWDDialog(false)
    }
    
    const UpdateStatusCancel = () => {
        const options = {
            url: window.$report + "/updatestatuscancel",
            method: "POST",
            withCredentials: true,
            headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                id: getID,
                token: props.accessToken
            }
        };
        Axios(options).then(response => {
            if(response.data.status === 1){
                let newArray = [...cancelWithdraw];
                let pushArray = [...cancelWithdraw];
                let newRequrseWithdrawArray  = [...requestWithdraw]
                const data = pushArray.filter(update => update.id === getID);
                newRequrseWithdrawArray.push(data[0]);
                setRequestWithdraw(newRequrseWithdrawArray);
                const del = newArray.filter(update => update.id !== getID);
                setCancelWithdraw(del)
                setOpenUPStatusWDDialog(false)
            }
        })

    }

    return (
        <Grid className={classes.root}>
            <Button onClick={() => history.goBack()} variant="contained" color="primary">
                <div style={{ width: '100%', borderRadius: 8,display: 'flex'}} >
                    <ArrowBackIcon style={{textAlign: 'center'}}/>ກັບຄືນ
                </div>
            </Button><br/><br/>
            <div className={classes.titleContent}>
                <Typography variant="h4"> ຍອດເງິນຄົງເຫຼືອ </Typography>
            </div>
            <GridItem xs={12} sm={12} md={12}>
                <InfoCardWithdraw
                    color="success"
                    title={"ຍອດເງິນຄົງເຫຼືອ"}
                    value={<NumberFormat value={props.userInfo.wallet} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t("currencies.1")} renderText={value => <div>{value}</div>} />}
                    icon={<AccountBalanceWalletIcon />}
                    amount={amount}
                    setAmount={setAmount}
                    withdraw={withdraw}
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    t={props.t}
                    userInfo={props.userInfo}
                />
            </GridItem><br/>

            <div style={{ marginTop: 20 }} className={classes.titleContent}>
                <Typography variant="h4"> ລໍຖ້າດຳເນີນການ </Typography>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">ວັນທີ ແລະ ເວລາຖອນເງິນ</StyledTableCell>
                            <StyledTableCell align="center">ລາຄາ&nbsp;(ກີບ)</StyledTableCell>
                            <StyledTableCell align="center">ສະຖານະ</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {completWithdraw.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell align="center">{row.date}</StyledTableCell>
                                <StyledTableCell align="center">{Number(row.balance).toLocaleString()}</StyledTableCell>
                                <StyledTableCell align="center">ຖອນແລ້ວ</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer><br/><br/>

            <div style={{ marginTop: 20 }} className={classes.titleContent}>
                <Typography variant="h4"> ປະຫວັດການຖອນ  </Typography>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">ວັນທີ ແລະ ເວລາຂໍຖອນເງິນ</StyledTableCell>
                            <StyledTableCell align="center">ລາຄາ&nbsp;(ກີບ)</StyledTableCell>
                            <StyledTableCell align="center">ສະຖານະ</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requestWithdraw.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell align="center">{row.date}</StyledTableCell>
                                <StyledTableCell align="center">{Number(row.balance).toLocaleString()}</StyledTableCell>
                                <StyledTableCell align="center">ລໍຖ້າການໂອນ</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer><br/><br/>

            <div style={{ marginTop: 20 }} className={classes.titleContent}>
                <Typography variant="h4"> ລາຍການຖອນທີຖືກປະຕີເສດ </Typography>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">ວັນທີ ແລະ ເວລາຖອນເງິນ</StyledTableCell>
                            <StyledTableCell align="center">ລາຄາ&nbsp;(ກີບ)</StyledTableCell>
                            <StyledTableCell align="center">ໝາຍເຫດ</StyledTableCell>
                            <StyledTableCell align="center">ສະຖານະ</StyledTableCell>
                            <StyledTableCell align="center">Acction</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cancelWithdraw.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell align="center">{row.date}</StyledTableCell>
                                <StyledTableCell align="center">{Number(row.balance).toLocaleString()}</StyledTableCell>
                                <StyledTableCell align="center">{row.remarks}</StyledTableCell>
                                <StyledTableCell align="center">ບໍ່ສຳເລັດ</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Button onClick={()=> {OpenDilogTryAgain(row.id)}} variant="contained" color="primary">ລອງໃຫມ່</Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <AlertConfirm open={openUPStatusWDDialog} handleConfirm={UpdateStatusCancel} handleCancel={handleClose2} text={"ຢືນຢັນການອະນຸມັດ"} button1={"ອະນຸມັດ"} button2={props.t("cancel.1")}/>
        </Grid>
    );
}