import React from "react";
import { makeStyles,CardActions,Typography,Grid,Tooltip,ButtonBase, Paper, IconButton} from "@material-ui/core";
import NumberFormat from 'react-number-format';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'; 
import ShowMoreText from 'react-show-more-text';
import ReactHtmlParser from "react-html-parser";
import { useHistory, NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: 20
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
      clickArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      },
      paper: {
        padding: 10,
        spacing: 10,
        margin: 10,
        display: 'flex'
    },
    img: {
        margin: 'auto',
        //display: 'block',
        maxWidth: '90%'
    },
    descArea: {
        fontSize: '120%',
        width: '100%'
    }
  }));

export default function CardRow(props) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container>
                    {props.cardInfo.img &&
                    <Grid item container xs={2} sm={2} md={2} xl={2} lg={2}>
                        <NavLink to={props.cardLink+props.cardInfo.id} style={{textDecoration: "none"}} >
                            <img className={classes.img} src={window.$product + "/getCourseImg/" + props.cardInfo.img} alt=""/>
                        </NavLink>
                    </Grid>
                    }
                    <Grid item container xs={6} sm={6} md={7} xl={7} lg={7} direction="column">
                        <ButtonBase onClick={()=> history.push(props.cardLink+props.cardInfo.id)} className={classes.clickArea}>
                            <Grid item>
                                <Typography component="h5" variant="h5" color="primary">
                                    {props.cardInfo.name}
                                </Typography>
                            </Grid>
                            <Grid item align="left" className={classes.descArea} >
                                <Typography component="h5" variant="subtitle2">
                                    <ShowMoreText lines={3} more={""} less={""}>
                                        
                                        {ReactHtmlParser(props.cardInfo.description)}
                                        
                                    </ShowMoreText>
                                </Typography>
                            </Grid><br/>
                            <Grid item>
                                {props.cardInfo.sellerName && 
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {props.t("publisher.1")}: {props.cardInfo.sellerName}
                                    </Typography>
                                }
                            </Grid>
                        </ButtonBase>
                    </Grid>
                    <Grid item container xs={4} sm={4} md={3} xl={3} lg={3} direction="column">
                    <NavLink to={props.cardLink+props.cardInfo.id} style={{textDecoration: "none"}} >
                        <Grid item container justifyContent="flex-end">
                            {props.cardInfo.promoPrice > 0 ?
                                <div>
                                    <CardActions style={{color: '#797D7F'}}>
                                        <Typography variant="subtitle1" style={{textDecoration: "line-through"}}>
                                            <NumberFormat value={props.cardInfo.price} displayType={'text'} thousandSeparator={true} suffix={' '+props.t(props.cardInfo.currency)} renderText={value => <div>{value}</div>} />
                                        </Typography>
                                    </CardActions>
                                    <CardActions style={{color: '#DE3163'}}>
                                        <LocalOfferIcon/>
                                        <Typography variant="subtitle1" >
                                            <NumberFormat value={props.cardInfo.promoPrice} displayType={'text'} thousandSeparator={true} suffix={' '+props.t(props.cardInfo.currency)} renderText={value => <div>{value}</div>} />
                                        </Typography>
                                    </CardActions>
                                </div>
                                :
                                <div>
                                    <CardActions >
                                        <LocalOfferIcon style={{color: '#fff'}}/>
                                        <Typography variant="subtitle1" style={{color: '#fff'}} >
                                            <NumberFormat value={props.cardInfo.price} displayType={'text'} thousandSeparator={true} suffix={' '+props.t(props.cardInfo.currency)} renderText={value => <div>{value}</div>} />
                                        </Typography>
                                    </CardActions>
                                </div>
                            }
                        </Grid><br/><br/>
                        </NavLink>
                        <Grid item container justifyContent="flex-end">
                            {props.handleDelete &&
                                <CardActions onClick={()=> props.handleDelete(props.cardInfo.id)}>
                                    <Tooltip title={<Typography>{props.t("delete.1")}</Typography>}>
                                        <IconButton><DeleteForeverIcon fontSize='large'/></IconButton>
                                    </Tooltip>
                                </CardActions>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
  );
};