import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, IconButton, Badge, Tooltip, Typography } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import {NewReleases} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
        margin: theme.spacing(0),
        },
        cursor: "pointer",
    },
    circle: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light,
    }
}));

export default function CircleIcon(props) {
    const classes = useStyles();
    const [iconImg, setIconImg] = useState(<NewReleases/>);

    useEffect(() => {
        let isMounted = true;
        if(isMounted){
            if(props.iconImg) {
                setIconImg(props.iconImg);
            }
        }
        return () => { isMounted = false };
    }, [props.iconImg])

    return (
        <div className={classes.root}>
            {props.tooltip ? 
                <Tooltip title={<Typography>{props.tooltip}</Typography>}>
                    {props.linkTo ?
                        <NavLink to={props.linkTo} style={{ textDecoration: "none"}}>
                            <IconButton color="inherit">
                                <Badge badgeContent={props.badge} color="error" overlap="rectangular">
                                    {props.open ? 
                                    <Avatar className={classes.circle} onClick={()=>props.setOpen(true)}>
                                        <>{iconImg}</>
                                    </Avatar>
                                    :
                                    <Avatar className={classes.circle}>
                                        <>{iconImg}</>
                                    </Avatar>
                                    }
                                </Badge>
                            </IconButton>
                        </NavLink>
                        :
                        <IconButton color="inherit">
                            <Badge badgeContent={props.badge} color="error" overlap="rectangular">
                                {props.setOpen ? 
                                <Avatar className={classes.circle} onClick={()=>props.setOpen(true)}>
                                    <>{iconImg}</>
                                </Avatar>
                                :
                                <Avatar className={classes.circle}>
                                    <>{iconImg}</>
                                </Avatar>
                                }
                            </Badge>
                        </IconButton>
                    }
                </Tooltip>
            :
                <div>
                {props.linkTo ?
                    <NavLink to={props.linkTo} style={{ textDecoration: "none"}}>
                    <IconButton color="inherit">
                        <Badge badgeContent={props.badge} color="error" overlap="rectangular">
                            {props.setOpen ? 
                            <Avatar className={classes.circle} onClick={()=>props.setOpen(true)}>
                                <>{iconImg}</>
                            </Avatar>
                            :
                            <Avatar className={classes.circle}>
                                <>{iconImg}</>
                            </Avatar>
                            }
                        </Badge>
                    </IconButton>
                </NavLink>
                :
                    <IconButton color="inherit">
                        <Badge badgeContent={props.badge} color="error" overlap="rectangular">
                            {props.setOpen ? 
                            <Avatar className={classes.circle} onClick={()=>props.setOpen(true)}>
                                <>{iconImg}</>
                            </Avatar>
                            :
                            <Avatar className={classes.circle}>
                                <>{iconImg}</>
                            </Avatar>
                            }
                        </Badge>
                    </IconButton>
                }
                </div>
                
            }
            
        </div>
    )
}
