import React from 'react';
import ReactDOM from 'react-dom';
import SkillgenerPublic from 'Apps/Public/index';
import SkillgenerInstructor from 'Apps/Instructor/index';
import SkillgenerAdmin from 'Apps/Admin/index'
import Test from 'Apps/Test/index'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline} from '@material-ui/core';
import { createTheme } from '@material-ui/core'
import './i18n'
import { Suspense } from 'react';
import Pg1successM from 'bt_modules/Purchase/PurchaseSuccessM';

//window.$api = 'https://rest.skillgener.com';
window.$streaming = process.env.REACT_APP_STREAM_URL;
window.$img = process.env.REACT_APP_IMG_URL;  
window.$aaa = process.env.REACT_APP_AAA_URL;
window.$payment = process.env.REACT_APP_PAYMENT_URL;
window.$product = process.env.REACT_APP_PRODUCT_URL;
window.$report = process.env.REACT_APP_REPORT_URL;
window.$playback = process.env.REACT_APP_PLAYBACK_URL;

// window.$api = 'https://srv.skillgener.com';
// window.$streaming = 'https://srv.skillgener.com/vdo-upload';
// window.$img = 'https://www.skillgener.com/img';
// window.$aaa = 'https://srv.skillgener.com/aaa';
// window.$payment = 'https://srv.skillgener.com/payment';
// window.$product = 'https://srv.skillgener.com/product';
// window.$report = 'https://srv.skillgener.com/report';
// window.$playback = 'https://srv.skillgener.com/vdo-playback';

const theme = createTheme({
  palette: {
    ///type: "dark",
    background: {
      default: "#C0C0C0"
    },
    primary: {
      light: "#145EB2",  //for icon vdo
      main: "#36454F" //app bar
    },
    secondary: {
      main: "#E2E2E2",
      light: '#fff', //paper
      dark: '#797D7F'
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
      'Phetsarath_OT',
    ].join(','),
  },
});

theme.typography.h3 = {
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.2rem',
  },
};

theme.typography.h4 = {
  [theme.breakpoints.up('md')]: {
    fontSize: '2.2rem',
  },
};

theme.typography.h5 = {
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

theme.typography.h6 = {
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
};

theme.typography.subtitle1 = {
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline/>
    <Suspense fallback=''> 
      <Router>
        <Switch>
          <Route path="/pg1success-m/:token" component={Pg1successM}/>
          <Route path="/instructor" component={SkillgenerInstructor}/>
          <Route path="/admin" component={SkillgenerAdmin}/>
          <Route path="/test" component={Test}/>
          <Route path="/" component={SkillgenerPublic}/>
        </Switch>
      </Router>
    </Suspense>
  </ThemeProvider> 
  ,
  document.getElementById('root')
);