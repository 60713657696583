import React from 'react';
import { Card, CardMedia, Typography } from "@material-ui/core";
import {makeStyles } from "@material-ui/core/styles";
import Search2 from 'bt_modules/Search/Search2.js';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
    },
    image: {
        //position: "absolute",
        top: "70%",
        marginLeft: 40,
        cursor: "pointer",
        flex: 1,
        display: 'flex',
        width: '50%',
        marginTop: '2%',
        [theme.breakpoints.down('md')]: {
            width: '30%'
        },
        // [theme.breakpoints.down('sm')]: {
        //     width: '20%'
        // },
    },
    button: {
        position: "absolute",
        top: "80%",
        marginLeft: 40,
        width: "50%"
    },
    buttonSpacing: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    text: {
        //position: "absolute",
        top: "8%",
        marginLeft: 40,
        cursor: "pointer",
        color: theme.palette.secondary.light,
        flex: 1,
    },
    search: {
        //position: "absolute",
        top: "42%",
        marginLeft: 40,
        display: 'none',
      [theme.breakpoints.up('lg')]: {
          display: 'block',
      },
        flex: 1,
        width: '30%'
    },
    space: {
        marginTop: '5%',
        marginLeft: 40,
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block',
        },
    },
    imgAppstore: {
        borderRadius: 13, 
        width: "80%", 
        marginTop: '1%',
    },
    imgPlaystore: {
        borderRadius: 13, 
        width: "100%", 
        marginTop: '-4%'
    },
}));

export default function Branner(props) {
    const classes = useStyles();
    
    return (
            <Card className={classes.root}>
                <CardMedia
                    component="img"
                    //image={'./img/SkillGennerbraner1.gif'}
                    //image={'./img/skillgener_banner_gift_old.png'}
                    image={'./img/skillgener_banner_gif.gif'}
                    style={{width: '100%', height: '100%'}}
                />
                <div style={{display: 'flex', flexDirection: 'column', position: "absolute", top: '10%'}}>
                    <div className={classes.text}>
                        <Typography variant='h4' >{props.t("banner.1")}</Typography>
                        <p></p>
                        <Typography variant='h5' className={classes.title2}>{props.t("banner.2")}</Typography>
                    </div>
                    <div className={classes.space}/>
                    <div className={classes.search}>
                        <Search2 placeHolder={props.t("Searching") + "..."} height={"10%"} width={"200%"} searchRoute={"/searchCourseName"} searchUrl="/searchCourse/" selectUrl="/course/" t={props.t} i18n={props.i18n}/>
                    </div>
                    <div className={classes.space}/>
                    <div className={classes.image} >
                        {/* <a
                        href="https://apps.apple.com/us/app/skillgener/id1632389908?itsct=apps_box_badge&itscg=30200"
                        target="_blank"
                        rel="noreferrer"
                        >
                        <img
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1657065600&h=b802714558771e55caf570af01d0bfe7"
                            alt="Download on the App Store"
                            style={{ borderRadius: 13, width: "22%", backgroundColor: 'red', }}
                        />
                        </a>

                        <a 
                        href='https://play.google.com/store/apps/details?id=com.skillgen&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                        target="_blank"
                        rel="noreferrer"
                        >
                            <img 
                                alt='Get it on Google Play' 
                                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                style={{ borderRadius: 13, width: "25%", backgroundColor: 'green', }}
                            />
                        </a> */}
                        <div style={{flex: 1}}>
                            <a
                                href="https://apps.apple.com/us/app/skillgener/id1632389908?itsct=apps_box_badge&itscg=30200"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1657065600&h=b802714558771e55caf570af01d0bfe7"
                                    alt="Download on the App Store"
                                    className={classes.imgAppstore}
                                />
                            </a>
                        </div>
                        <div style={{flex: 1}}>
                            <a 
                                href='https://play.google.com/store/apps/details?id=com.skillgen&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img 
                                    alt='Get it on Google Play' 
                                    src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                    className={classes.imgPlaystore}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                {/* <div className={classes.text}>
                    <Typography variant='h4' >{props.t("banner.1")}</Typography>
                    <p></p>
                    <Typography variant='h5' className={classes.title2}>{props.t("banner.2")}</Typography>
                </div>
                <div className={classes.search}>
                    <Search2 placeHolder={props.t("Searching") + "..."} height={"10%"} width={"200%"} searchRoute={"/searchCourseName"} searchUrl="/searchCourse/" selectUrl="/course/" t={props.t} i18n={props.i18n}/>
                </div>
                <div className={classes.image} >
                    <a
                      href="https://apps.apple.com/us/app/skillgener/id1632389908?itsct=apps_box_badge&itscg=30200"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1657065600&h=b802714558771e55caf570af01d0bfe7"
                        alt="Download on the App Store"
                        style={{ borderRadius: 13, width: "22%" }}
                      />
                    </a>

                    <a 
                    href='https://play.google.com/store/apps/details?id=com.skillgen&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                    target="_blank"
                    rel="noreferrer"
                    >
                        <img 
                            alt='Get it on Google Play' 
                            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                            style={{ borderRadius: 13, width: "25%" }}
                        />
                    </a>
                </div> */}
            </Card>

    )
}