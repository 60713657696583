import React, {useEffect, useState} from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import ProfileImage from 'bt_modules/ProfileImage/ProfileImage';
import ProfileInputField from 'bt_modules/ProfileInputField/ProfileInputField';
import Axios from 'axios';
import Loading from 'bt_modules/Loading';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import AlertDialog2 from 'bt_modules/AlertDialog/AlertDialog';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    }
}));

const Profile = (props) => {
    const classes = useStyles();
    const [values, setValues] = useState({});
    const [biography, setBiography] = useState();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [message, setMessage] = useState("")

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    }

    const handleClick2 = () => {
        setOpen2(false)
        window.location.reload()
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if(props.accessToken){
            let isMounted = true;
            if(isMounted){
                localStorage.setItem("lang", props.userInfo.locale)
                setValues({
                    firstName: props.userInfo.firstname,
                    lastName: props.userInfo.lastname,
                    phone: props.userInfo.phone,
                    state: props.userInfo.state,
                    twitter: props.userInfo.twitter,
                    website: props.userInfo.website,
                    facebook: props.userInfo.facebook,
                    linkedin: props.userInfo.linkedin,
                    youtube: props.userInfo.youtube,
                    biography: props.userInfo.biography,
                    locale: props.userInfo.locale,
                    //currency: props.userInfo.currency
                })
                setBiography(props.userInfo.biography)
                setLoading(false);
            }
            return () => { isMounted = false };
        }else{
            setOpen(true)
        }
    }, [props.userInfo, props.accessToken])

    const handleChange = (event) => {
        setValues({
        ...values,
        [event.target.name]: event.target.value
        });
    };
    
    const handleUpdate = () => {
        const options = {
            url: window.$aaa + "/updateUserInfo",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                token: props.accessToken,
                firstname: values.firstName,
                lastname: values.lastName,
                phone: values.phone,
                state: values.state,
                biography: values.biography,
                twitter: values.twitter,
                website: values.website,
                facebook: values.facebook,
                linkedin: values.linkedin,
                youtube: values.youtube,
                locale: values.locale,
            }
        };
        Axios(options).then(response => {
            if(response.data.status){
                setMessage(props.t("edit.5"))
                setOpen2(true)
            }else{
                setMessage(props.t("error.1"))
                setOpen2(true)
            }
        })
    }

    return (
        <div >
            {!loading ? 
            <div>
                <Container maxWidth={false}>
                    <Grid container spacing={3} className={classes.paper}>
                        <Grid item lg={4} md={6} xs={12} style={{marginTop: 20}}>
                            <ProfileImage 
                                img_id={props.userInfo.img_id}
                                firstname={props.userInfo.firstname}
                                accessToken={props.accessToken}
                                t={props.t}
                            />
                        </Grid>
                        <Grid item lg={8} md={6} xs={12}>
                            <ProfileInputField 
                                values={values}
                                setValues={setValues}
                                onChange={handleChange}
                                onUpdate={handleUpdate}
                                subheader={props.t("edit.1")}
                                title={props.t("account.1")}
                                textEditorDefaultValue={biography}
                                t={props.t}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <AlertDialog2 open={open2} handleClick={handleClick2} text={message} button={props.t("ok.1")}/>
            </div>
            :
            <div>
                <AlertDialog open={open} handleClick={handleClick} text={props.t("login.4")} button={props.t("ok.1")}/>
                <Loading/>
            </div>
            }
        </div>
    );
};

export default Profile;

