import React from 'react';
import {Typography, Link, Toolbar, AppBar, Grid, Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

//imgBCEL
import Onepay from '../../../../src/ImgLib/All Logo For Merchants/BCEL ONEPAY/onepay-color.png'

//imgChinese QR
import Airpay from '../../../../src/ImgLib/All Logo For Merchants/CHINESE QR/Alipay_logo_(2020).svg.png'
import UNIONPAY_logo from '../../../../src/ImgLib/All Logo For Merchants/CHINESE QR/UNIONPAY QR.png'
import Wechat_logo from '../../../../src/ImgLib/All Logo For Merchants/CHINESE QR/WECHAT.png'

//imgCYBERSOURCE
import AMEX_logo from '../../../../src/ImgLib/All Logo For Merchants/CYBERSOURCE/AMEX 3DS LOGO.png'
import CYBERSOURE_logo from '../../../../src/ImgLib/All Logo For Merchants/CYBERSOURCE/CYBERSOUR.png'
import JCB_logo from '../../../../src/ImgLib/All Logo For Merchants/CYBERSOURCE/JCB.png'
import MASTERCARD_logo from '../../../../src/ImgLib/All Logo For Merchants/CYBERSOURCE/MASTERCARD.png'
import VISA_logo from '../../../../src/ImgLib/All Logo For Merchants/CYBERSOURCE/VISA.png'

//imgUNIONPAY
import CUP_logo from '../../../../src/ImgLib/All Logo For Merchants/UNIONPAY/CUP LOGO.png'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //marginTop: '4%'
    },
    toolbar: {
        minHeight: 150,
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    title: {
        alignSelf: 'flex-end',
        marginTop: 15,
    },
    text: {
        marginBottom: 20,
    },
    textLink: {
        color: theme.palette.secondary.light, 
        textDecoration: "none"
    },
    bgcolor: {
        backgroundColor: theme.palette.background.default,
    },
    copyright: {
        color: theme.palette.background.default
    }
}));

export default function ProminentAppBar(props) {
    const classes = useStyles();

    function Copyright() {
        return (
            <Typography className={classes.copyright} variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://www.skillgener.com:3000">
                    skillgener.com
                </Link>{" "}
                {"2020."}
            </Typography>
            
            );
        }

    return (
        <div className={classes.root}>
        <AppBar position="static" color="primary">
            <Toolbar className={classes.toolbar}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Grid className={classes.title} >
                        <Grid className={classes.text}>
                            <NavLink to={"/instructor"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("Instructor.2")}</Typography>
                            </NavLink>
                            {/* <NavLink to={"about-us"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.1")}</Typography>
                            </NavLink>
                            <NavLink to={"/home"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.2")}</Typography>
                            </NavLink> */}
                             <NavLink to={"/terms_and_conditions"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.3")}</Typography>
                            </NavLink>
                            <NavLink to={"/contactus"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.2")}</Typography>
                            </NavLink>
                        </Grid>
                    </Grid>
                    <Grid className={classes.title} style={{marginBottom: ''}}>
                        <Grid className={classes.text}>
                            <NavLink to={"/terms_and_conditions"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.4")}</Typography>
                            </NavLink>

                            <NavLink to={"/home"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.5")}</Typography>
                            </NavLink>
                            <NavLink to={"#"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.6")}</Typography>
                            </NavLink>

                        </Grid>
                    </Grid>
                    <Grid className={classes.title} style={{marginBottom: ''}} >
                        <Grid className={classes.text}>
                            {/* <NavLink to={"/home"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.5")}</Typography>
                            </NavLink> */}
                            {/* <NavLink to={"#"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.6")}</Typography>
                            </NavLink> */}
                            {/* <NavLink to={"about-us"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.7")}</Typography>
                            </NavLink>
                            <NavLink to={"/home"} className={classes.textLink}>
                                <Typography variant="subtitle2">{props.t("footer.8")}</Typography>
                            </NavLink> */}
                        </Grid>
                    </Grid>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} >
                        <NavLink to={"/home"} style={{ textDecoration: "none"}}>
                            <img src={window.$img+"/logo.png"} alt="" />
                        </NavLink>
                        <Copyright />
                    </div>
                </Grid>
            </Toolbar>
            <Divider variant="middle" className={classes.bgcolor}/>
            <Grid item lg={12} className={classes.containerMerchants}>
                <Grid item container>
                    <Grid item xs={2} sm={2} md={12} xl={2} lg={4}/>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <div style={{textAlign: 'center', justifyContent: 'center', display: 'flex'}} >
                            <Grid item container>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}/>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={Onepay} alt="logo" style={{width: '55%'}} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={MASTERCARD_logo} alt="logo" style={{width: '70%'}} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={VISA_logo} alt="logo" style={{width: '60%'}} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={JCB_logo} alt="logo" style={{width: '40%'}} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={CUP_logo} alt="logo" style={{width: '40%'}} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={Airpay} alt="logo" style={{width: '80%'}} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={UNIONPAY_logo} alt="logo" style={{width: '30%'}} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={Wechat_logo} alt="logo" style={{width: '100%'}} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={AMEX_logo} alt="logo" style={{width: '80%'}} />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} xl={1} lg={1} style={{ alignSelf:'center'}}>
                                    <img src={CYBERSOURE_logo} alt="logo" style={{width: '100%'}} />
                                </Grid>

                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={2} sm={2} md={4} xl={2} lg={4}/>
                </Grid>
            </Grid>
        </AppBar>
        </div>
    );
}