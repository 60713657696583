import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Avatar, Button, Box, Card, CardActions, CardContent, Divider, Typography, makeStyles} from '@material-ui/core';
import ReactImageAppear from 'react-image-appear';

import ImageUpload from "bt_modules/ImageUpload"

const useStyles = makeStyles(() => ({
    root: {
        padding: 20
    },
    avatar: {
        height: 150,
        width: 150
    },
    img: {
        height: "100%",
        width: "100%"
    },
}));

const Profile = (props) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                >
                    <Avatar alt="Remy Sharp" className={classes.avatar}>
                        {props.img_id && 
                        <ReactImageAppear
                            className={classes.img}
                            src={window.$aaa + "/getUserImg/" + props.accessToken +'/'+  props.img_id}
                            animation="zoomIn"
                        />
                        }
                    </Avatar>
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                    >
                        {props.firstname}               
                    </Typography>

                    <Typography
                        className={classes.dateText}
                        color="textSecondary"
                        variant="body1"
                    >
                        {`${moment().format('hh:mm A')}`}
                    </Typography>
                </Box>
            </CardContent>
            <Divider />
            <CardActions>
                <Button
                    color="primary"
                    fullWidth
                    variant="text"
                >
                    <ImageUpload 
                        buttonName={props.t("profile.1")}
                        uploadTitle={props.t("profile.1")} 
                        accessToken={props.accessToken}
                        img_id={props.img_id}
                        t={props.t}
                    />
                </Button>
            </CardActions>
        </Card>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
