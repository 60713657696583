import React, {useState, useEffect} from 'react';
import { Paper, Grid, Typography, TableContainer, ButtonBase, Button, Tooltip} from "@material-ui/core";
import ReactPlayer from 'react-player';
import Axios from "axios";
import {ExpandLess, ExpandMore, Folder} from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import {makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light
    },
    textColor: {
        color: theme.palette.secondary.light
    },
    blue: {
        color: theme.palette.primary.main
    },
    setColor: {
        color: 'red'
    }
}));

export default function VideoPlayer(props) {
    const classes = useStyles();
    const [activeLesson, setActiveLesson] = useState();
    const [activeLessonNum, setActiveLessonNum] = useState();
    const [lessonID, setLessonID] = useState()
   
//"#3498DB"
    useEffect(() => {
        let isMounted = true;
        if(isMounted){
            props.lessonsInfo.map(lesson => {
                if(lesson.active) {
                    setActiveLesson(lesson.cloud_name);
                    setActiveLessonNum(lesson.lesson_num);
                    lesson.color = "green";
                    setLessonID(lesson.id)
                }else{
                    if(lesson.complete) {
                        lesson.color = "#3498DB";
                    }else{
                        lesson.color = "gray";
                    }
                }
                return lesson;
            })
        }
        return () => { isMounted = false };
        
    }, [props.lessonsInfo])

    const updateLessState = (token, CourseId, lessonId, route) => {
        const options ={
            url: window.$product + route,
            method: "POST",
            headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
                data: {"token": token, "courseID": CourseId, "lessonId": lessonId}
        } 
        Axios(options);
    }

    const updateLessons = (active, id) =>{
        setActiveLesson(active);
        let newArr = props.lessonsInfo.map((lesson) => {
            if(lesson.id === id){
                lesson.active = true;
            }else{
                lesson.active = false;
            }
            return lesson;
        })
        props.setLessonsInfo(newArr);

        updateLessState(props.accessToken, props.match.params.courseId, id, "/updateActiveLesson");
    }

    const HandleEnd = (activeNum) => {
        let newArr = props.lessonsInfo.map((lesson) => {
            if(Number(lesson.lesson_num) === Number(activeNum)+1){
                lesson.active = true;
                OpenSection(lesson.section_id);
                updateLessState(props.accessToken, props.match.params.courseId, lesson.id, "/updateActiveLesson");
                
            }else{
                lesson.active = false;
                if(Number(lesson.lesson_num) === Number(activeNum)) {
                    lesson.complete = true;
                    updateLessState(props.accessToken, props.match.params.courseId, lesson.id, "/updateCompleteLesson");
                }
            }
            return lesson;
        })
        props.setLessonsInfo(newArr);
    }

    const handleOpenSection = (id) => {
        let newArr = props.sectionsInfo.map((section) => {
            if(section.id === id){
                section.active = !section.active
            }            
            return section;
        })
        props.setSectionsInfo(newArr);
    };

    const OpenSection = (id) => {
        let newArr = props.sectionsInfo.map((section) => {
            if(section.id === id){
                section.active = true;
            }            
            return section;
        })
        props.setSectionsInfo(newArr);
    };

    const OpenCloseResource = (id) => {
        let newArr = props.sectionsInfo.map((section) => {
            if(section.id === id){
                section.openresource = !section.openresource;
            }            
            return section;
        })
        props.setSectionsInfo(newArr);
    };
    
    if(props.accessToken){
        return (
            <div>
                <Grid container className={classes.root}>
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10} container style={{ height: '100%'}}>
                        <ReactPlayer 
                            width="100%" 
                            height="100%" 
                            controls
                            playing
                            url={activeLesson && window.$playback + '/viewVideo/' + props.videoToken + '/' + lessonID + '/' + activeLesson}
                            onEnded={()=> {HandleEnd(activeLessonNum)}}
                            config={{
                                file: { 
                                    attributes: {
                                        controlsList: 'nodownload',                                        
                                        onContextMenu: e => e.preventDefault()
                                    }  
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2} >
                        <Paper style={{padding: '2%', maxHeight: '30%'}}>
                            <Typography variant='h6' style={{minWidth: '2%'}}>
                                {props.t("course.15")}
                            </Typography>
                        </Paper><br/>
                        <TableContainer style={{maxHeight: '500%', marginTop: '-3%'}}>
                            {props.sectionsInfo.map((section) => (
                                <Grid container key={section.id} style={{maxHeight: '150%', padding: '2%'}}>
                                    <Grid item  xs={3} sm={3} md={3} lg={4} xl={3} >
                                        <Typography variant="body2" >
                                            {props.t("content.4")} {section.section_num}:
                                        </Typography>   
                                    </Grid>
                                    <Grid item  xs={8} sm={8} md={8} lg={7} xl={8} >
                                        <ButtonBase onClick={()=> {handleOpenSection(section.id)}}>
                                            <Tooltip title={section.section_name}>
                                                <Typography variant="body2" style={{wordBreak: 'break-all'}}>
                                                    {`${String(section.section_name).substring(0,27)}`}{String(section.section_name).length > 27 && '...'}
                                                </Typography>
                                            </Tooltip>
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item  xs={1} sm={1} md={1} lg={1} xl={1} >
                                        <ButtonBase style={{width: '100%'}} onClick={()=> {handleOpenSection(section.id)}}>
                                            {section.active ? <ExpandLess/> : <ExpandMore/>}
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Collapse in={section.active} timeout="auto">
                                            {props.lessonsInfo.map((lessonInfo) => (
                                                section.id === lessonInfo.section_id && 
                                                    <div key={lessonInfo.id} style={{marginLeft: '5%',}}>
                                                        <Tooltip title={lessonInfo.name}>
                                                            <ButtonBase  onClick={()=> {updateLessons(lessonInfo.cloud_name, lessonInfo.id);}}>
                                                                <PlayCircleFilledIcon style={{color: lessonInfo.color}}/>&nbsp;
                                                                <Typography variant="caption">
                                                                    {`${String(lessonInfo.name).substring(0,30)}`}{String(lessonInfo.name).length > 30 && '...'}
                                                                </Typography>
                                                            </ButtonBase>
                                                        </Tooltip>
                                                    </div>                                                
                                                )
                                            )}
                                            <br/>
                                            {props.resources.length > 0 &&
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginLeft: '30%'}}>
                                                    {props.resources.some((el) => {return el.section_id === section.id}) &&
                                                        <>
                                                            <Button variant="contained" color="secondary" style={{textTransform: 'none', marginLeft: '-35%', height: '30%', marginTop: '-2%'}} onClick={() => OpenCloseResource(section.id)}>
                                                                <Folder color="primary" style={{marginLeft: '-14%'}}/> &nbsp; <Typography style={{marginLeft: '-1%'}} variant="body2">{props.t("doc.4")} ({props.resources.length})</Typography>
                                                            </Button>
                                                            <Collapse in={section.openresource} timeout="auto">
                                                                {props.resources.map((resourse)=>(
                                                                    section.id === resourse.section_id &&
                                                                    <div key={resourse.id}>
                                                                        <Tooltip title={resourse.orig_filename}>
                                                                        <Button style={{textTransform: 'none', marginLeft: '-25%'}} href={window.$product + "/getLessonResource/" + props.accessToken + "/" + resourse.id + "/" + resourse.cloud_id}>
                                                                            <Typography variant="caption" className={classes.textColor}>
                                                                                {`${String(resourse.orig_filename).substring(0,25)}`}{String(resourse.orig_filename).length > 25 && '...'}
                                                                            </Typography>
                                                                        </Button>
                                                                        </Tooltip>
                                                                    </div>
                                                                ))}
                                                            </Collapse>
                                                        </>
                                                    }
                                                </Grid>
                                            }
                                        </Collapse>
                                    </Grid>
                                <hr width= '99%' />
                                </Grid>
                            ))}
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        )
    }
}