import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, makeStyles, TextField, MenuItem} from '@material-ui/core';
import TextInput from "bt_modules/TextInput/TextInput"
import TextEditor from "../TextEditor/TextEditor"

const useStyles = makeStyles(() => ({
    root: {
        padding: 20
    }
}));

const ProfileDetails = (props, ...rest) => {
    const classes = useStyles();

    const language = [
        { id: 0, language: "English", value: "en" },
        { id: 1, language: "ไทย", value: "th" },
        { id: 2, language: "Tiếng Việt", value: "vn" },
        { id: 3, language: "ລາວ", value: "la" }
    ]

    return (
        <Card>
            <CardHeader
                style={{ textAlign: 'center'}}
                subheader={props.subheader}
                title={props.title}
            />
            <Divider />

            <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item md={6} xs={12}>
                        <TextInput label={props.t("name.1")} name={"firstName"} onChange={props.onChange} value={props.values.firstName}/>
                    </Grid>
                        <Grid item md={6} xs={12}>                
                            <TextInput label={props.t("name.2")} name={"lastName"} onChange={props.onChange} value={props.values.lastName}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput label={props.t("info.1")} name={"phone"} onChange={props.onChange} value={props.values.phone}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput label={props.t("info.3")} name={"state"} onChange={props.onChange} value={props.values.state}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput label={"Twitter"} name={"twitter"} onChange={props.onChange} value={props.values.twitter}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput label={props.t("info.4")} name={"website"} onChange={props.onChange} value={props.values.website}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput label={"Facebook"} name={"facebook"} onChange={props.onChange} value={props.values.facebook}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput label={"LinkedIn"} name={"linkedin"} onChange={props.onChange} value={props.values.linkedin}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextInput label={"Youtube"} name={"youtube"} onChange={props.onChange} value={props.values.youtube}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={props.t("info.2")+"/"+props.t("language.1")}
                                name='locale'
                                select
                                onChange={props.onChange}
                                required
                                value={props.values.locale || ""}
                                variant="outlined"
                            >
                                {language.map((row, index) => (
                                    <MenuItem key={index} value={row.value}>
                                        {row.language}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {/*<Grid item md={6} xs={12}>
                            <TextInput label={"Currency"} name={"currency"} value={props.t(props.values.currency)} disabled={true}/>
                                </Grid>*/}
                    </Grid>

                    <Divider style={{marginTop: 20}}/>
                    <Grid className={classes.root} >
                        <TextEditor values={props.values} setValues={props.setValues} defaultValue={props.textEditorDefaultValue} t={props.t}/>
                    </Grid>
                </CardContent>
            <Divider />

            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                        color="primary"
                        variant="contained"
                        onClick={props.onUpdate}
                >
                    {props.t("update.1")}
                </Button>
            </Box>
        </Card>
    );
};

ProfileDetails.propTypes = {
    className: PropTypes.string
};

export default ProfileDetails;