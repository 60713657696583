import React from 'react';
import { Dialog, Button, IconButton, TextField, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Edit, Add } from '@material-ui/icons';

export default function SignupPopup(props) {

    const onCloseEdit = () => {
        props.setOpenEdit(false)
        props.setAddrInfo(props.defaultAddr)
    }

    const onChange = (e) => {
        props.setAddrInfo({
            ...props.addrInfo,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div >
            <IconButton onClick={()=>props.setOpenEdit(true)} >
                {props.status ?
                <Edit style={{color: '#fff'}}/>
                :
                <Add style={{color: '#fff'}}/>
                }
            </IconButton>
            <Dialog open={props.openEdit} onClose={onCloseEdit} aria-labelledby="form-dialog-title" scroll="body">
            <DialogTitle id="alert-dialog-title">{props.t("address.2")}</DialogTitle>
                <DialogContent>
                    <TextField 
                        name="addr"
                        id="outlined-basic" 
                        label={props.t("info.16")} 
                        variant="outlined" 
                        fullWidth
                        margin="dense"
                        value={props.addrInfo.addr || ""}
                        onChange={onChange}
                    />
                    <TextField 
                        name="city"
                        id="outlined-basic" 
                        label={props.t("info.17")} 
                        variant="outlined" 
                        fullWidth
                        margin="dense"
                        value={props.addrInfo.city || ""}
                        onChange={onChange}
                    />
                    <TextField 
                        name="state"
                        id="outlined-basic" 
                        label={props.t("info.3")} 
                        variant="outlined" 
                        fullWidth
                        margin="dense"
                        value={props.addrInfo.state || ""}
                        onChange={onChange}
                    />
                    <TextField 
                        name="phone"
                        id="outlined-basic" 
                        label={props.t("info.1")}
                        variant="outlined" 
                        fullWidth
                        margin="dense"
                        value={props.addrInfo.phone || ""}
                        onChange={onChange}
                    />
                    <TextField 
                        name="zip"
                        id="outlined-basic" 
                        label={props.t("info.13")} 
                        variant="outlined" 
                        fullWidth
                        margin="dense"
                        value={props.addrInfo.zip || ""}
                        onChange={onChange}
                    />
                </DialogContent>
                <DialogActions >
                    <Button onClick={props.handleUpdateAddress}>
                        {props.t("ok.1")}
                    </Button>
                    <Button onClick={onCloseEdit}>
                        {props.t("cancel.1")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
