import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/NavigateNext';
import BackIcon from '@material-ui/icons/NavigateBefore';
import styles from './Carousel.module.scss';

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.secondary.light
  }
}));

const ArrowNav = ({ handleClick, backward, className }) => {
  const classes = useStyles();
  return (
    <div className={className}>
      
        {backward ? (
          <IconButton className={styles.button} onClick={handleClick}>
          <BackIcon  fontSize="large" className={classes.textColor}/>
          </IconButton>
        ) : (
          <IconButton className={styles.button} onClick={handleClick}>
          <ForwardIcon  fontSize="large" className={classes.textColor}/>
          </IconButton>
        )}
      
    </div>
  );
};

ArrowNav.propTypes = {
  handleClick: PropTypes.func.isRequired,
  backward: PropTypes.bool,
  className: PropTypes.string.isRequired,
};

ArrowNav.defaultProps = {
  backward: false,
};

export default ArrowNav;
