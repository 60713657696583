import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
//import GridItem from "bt_modules/Dashboard/Grid/GridItem.js";
//import GridContainer from "bt_modules/Dashboard/Grid/GridContainer.js";
import { Button, Typography } from '@material-ui/core'
import Card from "bt_modules/Dashboard/Card/Card.js";
import CardHeader from "bt_modules/Dashboard/Card/CardHeader.js";
import CardIcon from "bt_modules/Dashboard/Card/CardIcon.js";
import CardFooter from "bt_modules/Dashboard/Card/CardFooter.js";
import styles from "bt_modules/Dashboard/assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Danger from "bt_modules/Dashboard/Typography/Danger.js";
import Refresh from '@material-ui/icons/Refresh';
import { NavLink } from 'react-router-dom';
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles(styles);


export default function InfoCard(props) {
    const classes = useStyles();
    function refreshPage() {
      window.location.reload(false);
    }
    return (
        <div>
            <Card >
                <NavLink to={props.link} style={{color:"#fff", textDecoration: "none"}}>
                  <CardHeader color={props.color} stats icon>
                    <CardIcon color={props.color}>
                      {props.icon}
                    </CardIcon>
                    {props.withdraw || props.approvewithdraw ?
                    <CardIcon >
                    <div style={{ width: '55%'}}>
                      <p className={classes.cardCategory} style={{marginRight: '1%', color: 'black'}}>
                        {props.title}
                      </p>
                      <Typography variant='h4' className={classes.cardTitle} style={{color: 'red'}}>
                        {props.value}
                      </Typography>
                    </div>
                    </CardIcon>
                    :
                    <div>
                    <p className={classes.cardCategory}>
                      {props.title}
                    </p>
                    <h3 className={classes.cardTitle}>
                      {props.value}
                    </h3>
                  </div>
                    }
                    
                  </CardHeader>
                  </NavLink>
                  <CardFooter stats>
                    <div onClick={refreshPage} style={{cursor: "pointer"}} className={classes.stats}>
                      <Danger>
                        <Refresh />{/**xs={6} md={7} xl={9} */}
                      </Danger>
                      {props.t("reload.1")}
                    </div>
                    {props.withdraw  &&
                      <CardActions>
                        <NavLink to={"/instructor/withdraw"} style={{ textDecoration: "none" }}>
                          <Button variant="contained" color="primary" style={{textTransform: 'none', fontSize: 10}} >
                            ຖອນເງິນ
                          </Button>
                        </NavLink>

                        <NavLink to={"/instructor/detail"}>
                          <Button variant="contained" color="primary" style={{textTransform: 'none', fontSize: 10}} >
                            ລາຍລະອຽດ
                          </Button>
                        </NavLink>
                      </CardActions>
                    }
                    {props.approvewithdraw &&
                      <CardActions>
                          <Button onClick={props.Test} variant="contained" color="primary" style={{textTransform: 'none', fontSize: 10}} >
                            ອະນຸມັດການຖອນ
                          </Button>
                      </CardActions>
                    }
                  </CardFooter>
                </Card>
        </div>
    )
}