import React, {useState, useEffect} from 'react';
import SiteBar from "Apps/Instructor/SiteBar/SiteBar"
import { makeStyles} from '@material-ui/core';
import { Route } from "react-router-dom";
import Axios from "axios";
import Dashboard from "Apps/Instructor/Dashboard/Dashboard"
import CreateCourse from "Apps/Instructor/CreateCourse/CreateCourse"
import PublishedCourse from "Apps/Instructor/PublishedCourse/Course"
import UnpublishedCourse from "Apps/Instructor/UnpublishedCourse/Course"
import ManageCourse from "Apps/Instructor/ManageCourse/ManageCourse"
import { useTranslation } from 'react-i18next';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import Register from './Register/Register';
import Loading from 'bt_modules/Loading';
import PenddingCourse from 'bt_modules/PenddingCourse/PenddingCourse';
import Withdraw from './Payment/Withdraw';
import Detail from './Details/Detail';
import YesterdaySales from 'bt_modules/InstructorDetails/YesterdaySales';
import TodaySales from 'bt_modules/InstructorDetails/TodaySales';
import MonthySales from 'bt_modules/InstructorDetails/MonthySales';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: "20%",
        minHeight: 768,
        [theme.breakpoints.up('md')]: {
            marginLeft: "10%",
            minHeight: 768,
        },
    },
}));

export default function Instructor() {
    const classes = useStyles();
    const [accessToken, setAccessToken] = useState("");
    const [userInfo, setUserInfo] = useState([]);
    const [loading, setLoading] = useState(true)
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    } 

    useEffect(()=> {
        let isMounted = true;
        const options = {
            url: window.$aaa + "/refresh1",
            method: "POST",
            withCredentials: true,
            headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
        };
        Axios(options).then(response => {
            if (isMounted){
                let lang = localStorage.getItem("lang")
                if(lang){
                    localStorage.setItem("lang", i18n.language)
                }
                if(response.data.status){
                    if(response.data.jid) { 
                        setAccessToken(response.data.jid);
                    }
                    if(response.data.userInfo) {
                        setUserInfo(response.data.userInfo);
                    }
                    setLoading(false);
                }else{
                    setOpen(true)
                }
            }
        })
        return () => { isMounted = false };
    }, [i18n]);

    return (
        <div>
            {!loading ?
                <div>
                    {userInfo.teacher === 1 ? 
                        <div>
                            <SiteBar userInfo={userInfo} accessToken={accessToken} setAccessToken={setAccessToken} t={t} i18n={i18n}/>
                            <div className={classes.root}>

                                <Route path="/instructor/monthySales" component={(props)=> <MonthySales {...props} accessToken={accessToken} userInfo={userInfo} t={t} /> }/>
                                <Route path="/instructor/todaySales" component={(props)=> <TodaySales {...props} accessToken={accessToken} userInfo={userInfo} t={t} /> }/>
                                <Route path="/instructor/yesterdaySales" component={(props)=> <YesterdaySales {...props} accessToken={accessToken} userInfo={userInfo} t={t} /> }/>
                                <Route path="/instructor/detail" component={(props)=> <Detail {...props} accessToken={accessToken} userInfo={userInfo} t={t} /> }/>
                                <Route path="/instructor/withdraw" component={(props)=> <Withdraw {...props} accessToken={accessToken} userInfo={userInfo} setUserInfo={setUserInfo} t={t} /> }/>
                                <Route path="/instructor/penddingcourse" component={(props)=> <PenddingCourse {...props} accessToken={accessToken} t={t} /> }/>
                                <Route path="/instructor/course/:id" component={(props)=> <ManageCourse {...props} accessToken={accessToken} t={t} /> }/>
                                <Route path="/instructor/createcourse" exact component={()=> <CreateCourse accessToken={accessToken} userInfo={userInfo} t={t}/>} />
                                <Route path="/instructor/unpublished_course" exact component={()=> <UnpublishedCourse accessToken={accessToken} t={t} /> }/>
                                <Route path="/instructor/published_course" exact component={()=> <PublishedCourse accessToken={accessToken} t={t} /> }/>
                                <Route path="/instructor" exact component={()=> <Dashboard accessToken={accessToken} t={t} userInfo={userInfo}/>}/>
                            </div>
                        </div>
                        :
                        <div>
                            <Register accessToken={accessToken} t={t}/>
                        </div>
                    }
                </div>
                :
                <div>
                    <Loading/>
                    <AlertDialog open={open} handleClick={handleClick} text={t("login.4")} button={t("ok.1")}/>
                </div>
            }
        </div>
    )
}
