import React, {useState, useEffect} from 'react';
import CardListGridCategory from "bt_modules/CardListGrid/CardListGridCategory";
import Axios from "axios";
import Loading from 'bt_modules/Loading';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

export default function MyCoursePage(props) {
    const [myCoursesData, setMyCoursesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    }

    useEffect(()=>{
        window.scrollTo(0, 0)
        if(props.accessToken) {
            let isMounted = true;
            const options = {
                url: window.$product + "/getMyCourses",
                method: "POST",
                withCredentials: true,
                headers:{
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data:{
                    "token": props.accessToken
                }
            };
            Axios(options).then(response => {
                if (isMounted){
                    if(response.data){
                        let courseData = []
                        response.data.map(course => (
                            courseData.push({
                                "id":course.id,
                                "name":course.name,
                                "img":course.img_id,
                                "price":course.price,
                                "promoPrice": course.promo_price,
                                "discount": course.discount,
                                "sellerName": course.firstname+" "+course.lastname,
                                "currency": course.currency
                            })
                        ));
                        setMyCoursesData(courseData);
                        setLoading(false);
                    }
                }
            })
            return () => { isMounted = false };
        }else{
            setOpen(true)
        }
    },[props.accessToken]);

    return (
        <div>
            {!loading ? 
            <CardListGridCategory listTitle={props.t("Mycourse.2")} t={props.t} cardsData={myCoursesData} cardLink="/learn/" />
            :
            <div>
                <AlertDialog open={open} handleClick={handleClick} text={props.t("login.4")} button={props.t("ok.1")}/>
                <Loading/>
            </div>
            }
        </div>
    )
}
