import React, { createRef, useState } from 'react';
import Axios from "axios";
import AvatarEditor from 'react-avatar-editor';
import { Avatar, makeStyles, Button, Typography, Box, Grid } from '@material-ui/core';
import LoadingImg from './Loading_img'

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 350,
        minHeight: 350
    },
    avatar: {
        height: 150,
        width: 150,
    },
    text: {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 16
    },
    button: {
        paddingTop: 20,
        paddingBottom: 20
    },
    loading: {
        backgroundColor: 'gray',
        position: 'absolute', 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, 
        justifyContent: 'center', 
        alignItems: 'center',
        opacity: 0.8
    },
    logo: {
        marginTop: '55%',
    },
}));

export default function Cropper(props) {
    const classes = useStyles();
    const cropper = createRef();
    const [image, setImage] = useState("");
    const [scale, setScale] = useState(1);
    const [file, setFile] = useState("");
    const [showPreviewe, setShowPreviewe] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleScale = e => {
        const scale = parseFloat(e.target.value);
        setScale(scale);
    };

    const handleNewImage = (e) => {
        setImage(e.target.files[0])
    }

    const handleRef = (editor) => {
        cropper.current = editor;
    }

    const handleCrop = () => {
        const img = cropper.current.getImageScaledToCanvas().toDataURL("image/*", 1.0).replace("image/*", 1.0);
        setFile(img);
        setShowPreviewe(true)
    }

    const handleSave = () => {
        setLoading(false);
        let formData = new FormData();
        formData.append("file_img", file);
        formData.append("token", props.accessToken);
        const options = {
            url: window.$aaa + "/userimageupload",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: formData,
        };
        Axios(options).then(response => {
            if(response.data.status){
                if(props.img_id){
                    const options2 = {
                        url: window.$aaa + "/delUserProfile",
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "content-Type": "application/json;charset-UTF-8"
                        },
                        data: {
                            "file_id": props.img_id,
                            "token": props.accessToken
                        }
                    };
                    Axios(options2).then(response2 => {
                        if(response2.data){
                            window.location.reload()
                        }
                    })
                }else{
                    window.location.reload()
                }
            }
        })
        .catch(e =>{
            console.log(e)
        })
    }

    return (
        <div className={classes.root}>
            <div>
                <Box alignItems="center" display="flex" flexDirection="column">
                    <AvatarEditor
                        ref={handleRef}
                        scale={parseFloat(scale)}
                        image={image}
                        width={150}
                        height={150}
                        border={5}
                    />
                </Box>
                <br />
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <input id="image" name="image" type="file" style={{ display: 'none' }} onChange={handleNewImage} />
                    <label htmlFor="image">
                        <Button variant="contained" component="span" style={{ height: "40px", width: "160px" }}>
                            <Typography className={classes.text}> {props.t("image.1")}</Typography>
                        </Button>
                    </label>
                </Grid>
                <br />
                <Typography className={classes.text}> {props.t("image.3")}: </Typography>
                <input
                    name="scale"
                    type="range"
                    onChange={handleScale}
                    min={scale ? "0.1" : "1"}
                    max="2"
                    step="0.01"
                    defaultValue="1"
                />
                <br />
                <br />
                <Button variant="contained" onClick={handleCrop}>{props.t("image.2")}</Button>
                <br />
                <br />
                {showPreviewe ?
                    <div>
                        <Typography variant="h5" className={classes.text}> {props.t("image.4")} </Typography>
                        <Box alignItems="center" display="flex" flexDirection="column">
                            <Avatar className={classes.avatar}>
                                <img src={file} alt="img" />
                            </Avatar>
                        </Box>
                        <div className={classes.button}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Button variant="contained" onClick={() => { props.handleClose() }} >{props.t("cancel.1")}</Button>
                                <Button variant="contained" onClick={() => { handleSave(); }} >{props.t("upload.1")}</Button>
                            </Grid>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            {!loading &&
                <div className={classes.loading}>
                    <div className={classes.logo}>
                    <LoadingImg/>
                    </div>
                </div>
            }
        </div>
    )
}