import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from "bt_modules/Dashboard/Card/Card.js";
import CardHeader from "bt_modules/Dashboard/Card/CardHeader.js";
import CardIcon from "bt_modules/Dashboard/Card/CardIcon.js";
import CardFooter from "bt_modules/Dashboard/Card/CardFooter.js";
import styles from "bt_modules/Dashboard/assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Danger from "bt_modules/Dashboard/Typography/Danger.js";
import Refresh from '@material-ui/icons/Refresh';
import PaymentDialog from '../PaymentDialog/PaymentDialog';
import CardActions from '@material-ui/core/CardActions';
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(styles);

export default function InfoCard(props) {
  const classes = useStyles();

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div>
      <Card >
        <CardHeader color={props.color} stats icon>
          <CardIcon color={props.color}>
            {props.icon}
          </CardIcon>
            <div style={{ width: '55%'}}>
              <Typography className={classes.cardCategory} style={{marginRight: '-2%',color: 'black'}}>
                {props.title}
              </Typography>
              <Typography variant='h4' className={classes.cardTitle} style={{color: 'red'}}>
                {props.userInfo.wallet} ກີບ
              </Typography>
            </div>
          {/* <div style={{ width: '55%'}}>
          <p className={classes.cardCategory} style={{marginRight: '-5%',color: 'black'}}>{props.title}</p>
          <h3 className={classes.cardTitle} style={{color: 'red', fontSize: 25}}>
            {props.userInfo.wallet}
          </h3>
          </div> */}
          {/* <div style={{marginTop: 15}}>
          <PaymentDialog 
            amount={props.amount} 
            setAmount={props.setAmount} 
            withdraw={props.withdraw} 
            open={props.open}
            handleClickOpen={props.handleClickOpen}
            handleClose={props.handleClose}/>
          </div> */}
        </CardHeader>
        <CardFooter stats>
          <div onClick={refreshPage} style={{ cursor: "pointer" }} className={classes.stats}>
            <Danger>
              <Refresh />
            </Danger>
            {props.t("reload.1")}
          </div>
          <CardActions>
            <PaymentDialog 
              amount={props.amount} 
              setAmount={props.setAmount} 
              withdraw={props.withdraw} 
              open={props.open}
              handleClickOpen={props.handleClickOpen}
              handleClose={props.handleClose}
            />
          </CardActions>
        </CardFooter>
      </Card>
    </div>
  )
}