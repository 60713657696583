import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import LogoLoarding from 'ImgLib/GIF.gif';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.secondary.dark,
        //opacity: 0.8
    },
}));

export default function Loading() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <img src={LogoLoarding} alt="" style={{ width: "25%", marginTop: "30%"}} />
            </Grid>
        </div>
    )
}