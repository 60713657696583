import ContactUsPage from 'bt_modules/ContactUsPage/ContactUsPage'
import React from 'react'

export default function ContactUS(props) {
  return (
    <div>
        <ContactUsPage t={props.t}/>
    </div>
  )
}
