import React, { useEffect, useState }from 'react';
import { useHistory } from 'react-router-dom';
import {makeStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Axios from "axios";
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.default,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main ,
        },
        marginRight: theme.spacing(-9),
        marginLeft: 0,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
        height: 60,
        fontSize: 22,
        marginLeft: '2%'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.down('md')]: {
            width: '21ch',
        },

        [theme.breakpoints.down('sm')]: {
            width: '15ch',
        },
    },

}));

export default function Search(props) {
    const classes = useStyles();
    const [courseList, setList] = useState([]);
    const [search, setSearch] = useState();
    const [width, setWidth] = useState("10%");
    const [placeHolder, setPlaceHolder] = useState("ຄົ້ນຫາ…");
    const [searchRoute, setSearchRoute] = useState("");
    const [selectUrl, setSelectUrl] = useState("/home");
    const [searchUrl, setSearchUrl] = useState("/home");
    const history = useHistory();
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(false)
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted){
            if(props.width) {
                setWidth(props.width);
            }
            if(props.placeHolder) {
                setPlaceHolder(props.placeHolder);
            }
            if(props.searchRoute) {
                setSearchRoute(props.searchRoute);
            }
            if(props.selectUrl) {
                setSelectUrl(props.selectUrl);
            }
            if(props.searchUrl) {
                setSearchUrl(props.searchUrl);
            }
        }
        return () => { isMounted = false };
    }, [props])

    const handleType = (e) => {
        let text = e.target.value;
        if(text){
            const options = {
                url: window.$product + searchRoute,
                method: "POST",
                withCredentials: true,
                headers:{
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    key: e.target.value,
                    language: props.i18n.language
                }
            };
            Axios(options).then(response => {
                setList(response.data);
            })
        }else{
            setList([]);
        }
    };

    const handleSearch = (e) => {
        if(e.key === 'Enter'){
            if(search){
                history.push(searchUrl+search)
            }else{
                setOpen(true);
            }
        }
    };
    const handleSelect = (itemId) => {
        if(itemId){
            history.push(selectUrl+itemId);
        }
    };

    return (
        <div className={classes.search} style={{width: width}}>
            <div className={classes.searchIcon}>
                <SearchIcon fontSize="large"/>
            </div>
            <Autocomplete
                freeSolo
                options={courseList}
                getOptionLabel={option => (option.name ? option.name: "")}
                onChange={(event, newValue) => {
                    newValue && handleSelect(newValue.id);
                }}
                renderInput={(params) => (
                    <InputBase
                        ref={params.InputProps.ref}
                        inputProps={{ ...params.inputProps, type: 'search' }}
                        placeholder={placeHolder}
                        onChange={(event) => {handleType(event); setSearch(event.target.value)}}
                        classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                        }}
                        onKeyPress={handleSearch}
                    />
                )}
            />
            <AlertDialog open={open} handleClick={handleClick} text={props.t("info.5")} button={props.t("ok.1")}/>
        </div>
    )
}