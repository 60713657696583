import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Axios from "axios";
import jwt_decode from 'jwt-decode';
import CircleIcon from "bt_modules/CircleIcon/CircleIcon";
import { LockOpen } from '@material-ui/icons';
import LoadingAuth from './LoadingAuth';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.primary.main
    },
    bgcolor: {
        backgroundColor: theme.palette.background.default
    },
    bgTitleContent: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light
    }
}));

export default function LoginPopup(props) {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [isUnameValidated, setUnameValidated] = useState(true);
    const [isPassValidated, setPassValidated] = useState(true);
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    }

    const validateEmail = (email) => {
        let regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    };

    const validatePass = (pass) => {
        if (pass.length >= 6) {
            return true;
        } else { return false; }
    };

    const handleLogin = () => {
        if (validateEmail(email)) {
            setUnameValidated(true);
            if (validatePass(password)) {
                setPassValidated(true);
                setLoading(false)
                let isMounted = true;
                const options = {
                    url: window.$aaa + "/login1",
                    method: "POST",
                    withCredentials: true,
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8"
                    },
                    data: {
                        "email": email,
                        "password": password
                    }
                };
                Axios(options).then(response => {
                    if (isMounted){
                    if (response.data.status) {
                        switch (response.data.status) {
                            case 1:
                                setLoading(true);
                                if (response.data.jid) {
                                    let decoded = jwt_decode(response.data.jid);
                                    if (decoded.id) {
                                        props.setAccessToken(response.data.jid);
                                    }
                                }
                                if(response.data.userInfo) {
                                    props.setUserInfo(response.data.userInfo);
                                    if(response.data.userInfo.locale){
                                        props.i18n.changeLanguage(response.data.userInfo.locale)
                                        localStorage.setItem("lang", response.data.userInfo.locale)
                                    }
                                }
                                if (response.data.cartInfo) {
                                    props.setCartData(response.data.cartInfo);
                                }
                                if (response.data.wishInfo) {
                                    props.setWishList(response.data.wishInfo);
                                }
                                if (response.data.myCourseInfo) {
                                    props.setMyCourseData(response.data.myCourseInfo)
                                }
                                break;
                            case 2:
                                setLoading(true);
                                setMessage(props.t("account.4"));
                                break;
                            case 3:
                                setLoading(true);
                                setMessage(props.t("password.2"));
                                break;
                            case 4:
                                setLoading(true);
                                setMessage(props.t("email.5"));
                                break;
                            default:
                                setLoading(true);
                                setMessage(props.t("error.1"));
                                break;
                        }
                    }
                    }
                });
                return () => { isMounted = false };
            } else {
                setPassValidated(false);
            }
        } else {
            setUnameValidated(false);
        }
    };

    const handlePress = (e) => {
        if (e.key === "Enter") {
            handleLogin()
        }
    }

    const onClose = () =>  {
        if(loading){
            props.setOpen(false)
        }
    }

    return (
        <div onKeyPress={handlePress}>
            <CircleIcon iconImg={<LockOpen color="primary" />} setOpen={props.setOpen} tooltip={props.t("login.1")} />
            <Dialog open={props.open} onClose={onClose} aria-labelledby="form-dialog-title" scroll="body">
                <DialogTitle id="form-dialog-title" className={classes.bgTitleContent}>
                    <Grid className={classes.center}>
                        <img src={props.logo} alt="props.logo" />
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.bgcolor}>
                    {!loading ?
                        <LoadingAuth/>
                        :
                        null
                    }
                    <DialogContentText className={classes.center} >
                        {props.t("login.2")}
                    </DialogContentText>
                    <Typography color="error">{message}</Typography>
                    <TextField
                        variant="outlined"
                        required
                        type="email"
                        label={props.t("email.1") + "(email@domain.com)"}
                        fullWidth
                        margin="dense"
                        error={!isUnameValidated}
                        helperText={!isUnameValidated ? props.t("email.2") : ""}
                        onChange={handleChangeEmail}
                        disabled={!loading}
                    />
                    <TextField
                        variant="outlined"
                        required
                        type="password"
                        label={props.t("password.1")}
                        fullWidth
                        margin="dense"
                        error={!isPassValidated}
                        helperText={!isPassValidated ? props.t("password.6") : ""}
                        onChange={handleChangePassword}
                        disabled={!loading}
                    />
                    <div><br /></div>
                    <div className={classes.center}>
                        <Button variant="contained" color="primary" disabled={!loading} onClick={handleLogin}>{props.t("login.1")}</Button>
                    </div>
                </DialogContent>
                <DialogActions className={classes.bgTitleContent}>
                    <Button disabled={!loading} className={classes.bgTitleContent} onClick={() => { props.setOpen(false); props.setOpenSignup(true) }}>
                        {props.t("register.1")}
                    </Button>
                    <Button disabled={!loading} className={classes.bgTitleContent} onClick={() => window.location = '/find_account'}>
                        {props.t("password.3")}?
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}