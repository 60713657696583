import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { NavLink } from 'react-router-dom';
import CardActions from '@material-ui/core/CardActions';
import {Button} from '@material-ui/core';
import Axios from "axios";

const useStyles = makeStyles((theme)=>({
    card: {
        maxWidth: 300,
        borderRadius: 5,
        boxShadow: "none",
        },
    media: {
        height: 140,
        objectFit: "contain",
        width: "100%",
        backgroundColor: theme.palette.secondary.light,
        paddingBottom: 10,
        paddingTop: 10,
        }
    }));

export default function MyCourseCard(props) {
    const classes = useStyles();

    const HandleAccept = (course_id) => {
        const options = {
            url: window.$product + "/acceptCourse",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "course_id": course_id,
                "token": props.accessToken
            }
        };
        Axios(options).then(response => {
            if(response.data.status){
                let newArray = [...props.courseList];
                const del = newArray.filter(update => update.id !== course_id);
                props.setCourseList(del);
            }
        })
    }

    return (
        <div >
            <Card className={classes.card} >
                <CardActionArea>
                    <NavLink to={"/admin/course/" + props.course_id } style={{color:"#fff", textDecoration: "none"}}>
                        {props.img_id &&
                        <CardMedia
                            component="img"
                            alt="Contemplative Reptile"
                            className={classes.media}
                            image={window.$product + "/getCourseImg/" + props.img_id} 
                            title="Contemplative Reptile"
                        />}
                    </NavLink>
                </CardActionArea>
                <div style={{}}>
                    <CardContent style={{height: 80}}>
                        {props.name.length > 45 ? 
                            <Typography gutterBottom variant="h6">
                                {props.name.substring(0,45) + "..."}
                            </Typography>
                                :
                            <Typography gutterBottom variant="h6">
                                {props.name}
                            </Typography>
                        }
                    </CardContent>
                    <CardContent  style={{height: 6,}}>
                        {props.publisher? 
                            <Typography>
                                {props.publisher.substring(0,25) + "..."}
                            </Typography>
                            :
                            <Typography>
                                {props.publisher}
                            </Typography>
                        }
                    </CardContent>
                    <CardContent  style={{height: 6}}>
                            <Typography>
                                {props.price}
                            </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary" onClick={()=> HandleAccept(props.course_id)}>{props.t("accept.1")}</Button>
                    </CardActions>
                </div>
            </Card>
        </div>
    )
}
