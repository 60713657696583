import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {TextField} from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        },
    },
    }))(TableRow);


    const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    });

    export default function UploadFileVDOSection(props) {
    const classes = useStyles();

    const handleFile = (e) => {
        props.setFileName(e.target.value);
      };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table" size="small">
                    <TableHead>

                        <TableRow>
                            <StyledTableCell align="left">{props.t("doc.3")}</StyledTableCell>
                            <StyledTableCell align="center">{props.t("doc.2")}</StyledTableCell>
                            <StyledTableCell align="center">{props.t("status.1")}</StyledTableCell>
                            <StyledTableCell align="center">{props.t("date.1")}</StyledTableCell>
                            <StyledTableCell align="center">{props.t("cancel.1")}</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {props.arrayDataFile.map((file)=>(
                        <StyledTableRow key={file.filename}>
                            <StyledTableCell align="left">
                                <TextField
                                    style={{width:"70%"}}
                                    variant="outlined"
                                    value={props.fileName}
                                    onChange={handleFile}
                                />
                            </StyledTableCell>
                            <StyledTableCell align="center">{file.typeFile}</StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center">{file.dateFile}</StyledTableCell>
                            <StyledTableCell align="center">
                            <IconButton onClick={()=>props.DeleteRowFile(file.filename)}>
                                <DeleteIcon/>
                            </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </TableBody>
                    
                </Table>
            </TableContainer>
        </div>
    );
}