import React from "react";
import {Grid, Typography, makeStyles} from "@material-ui/core";
import CardRow from "./CardRow";

const useStyles = makeStyles((theme) => ({
  titleStyleForInstructor: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '0.5%'
  },
}));

export default function CardListRow(props) {
  const classes = useStyles();
  
  return (
    <Grid container spacing={5}>
      {props.title && 
        <Grid container direction="column" justifyContent="center" alignItems="center" color="primary" className={classes.titleStyleForInstructor}>
          <Typography variant="h4" gutterBottom > {props.title}</Typography>
        </Grid>
      }
      {props.handleDelete ? 
        <Grid item xs={12}>
          {props.cardsInfo.map((cardInfo)=>
            <CardRow cardInfo={cardInfo} key={cardInfo.id} handleDelete={props.handleDelete} t={props.t} {...props}/>
          )}
        </Grid>
        :
        <Grid item xs={12}>
          {props.cardsInfo.map((cardInfo)=>
            <CardRow cardInfo={cardInfo} key={cardInfo.id} t={props.t} {...props}/>
          )}
        </Grid>
      }        
      
    </Grid>
  );
};