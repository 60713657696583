import React, { useState, useEffect } from 'react';
import Axios from "axios";
import CreateNewCourse from "bt_modules/CreateCourse/CreateCourse"
import Loading from 'bt_modules/Loading';
import Loading1 from 'bt_modules/Loading1';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import AlertDialog2 from 'bt_modules/AlertDialog/AlertDialog';

export default function CreateCourse(props) {
    const [categories, setCategories] = useState([]);
    const [value, setValue] = useState({});
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [InsertedID, setInsertedID] = useState()

    const handleClick = () => {
        setOpen(false)
        window.location = "/instructor/course/" + InsertedID
    }

    const handleClick2 = () => {
        setOpen2(false)
    }

    useEffect(() => {
        let isMounted = true;
        const options = {
            url: window.$product + "/getAllCatInfoCountCourse",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                start: 0,
                stop: 100,
                language: ""
            }
        };
        Axios(options).then(response => {
            if (isMounted) {
                setCategories(response.data.course);
                setLoading(false);
            }
        })
        return () => { isMounted = false };
    }, []);

    const HandleCreateData = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value
        });
    }

    const handleEditorChange = (content) => {
        setValue({ ...value, description: content })
    };

    const HandleCreateCourse = () => {
        if (value.name && value.cat_id && value.description) {
            setLoading1(false)
            const options = {
                url: window.$product + "/createcourse",
                method: "POST",
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    name: value.name,
                    cat_id: value.cat_id,
                    description: value.description,
                    language: props.userInfo.locale,
                    currency: props.userInfo.currency,
                    token: props.accessToken
                }
            };
            Axios(options).then(response => {
                if (response.data.id) {
                    setInsertedID(response.data.id)
                    setOpen(true)
                    setLoading1(true)
                }
            })
        } else {
            setOpen2(true)
        }
    }

    return (
        <div >
            {!loading ?
                <div>
                    <CreateNewCourse
                        title={props.t("course.4")}
                        textEditorTitle={props.t("course.7")}
                        button={props.t("course.4")}
                        categories={categories}
                        value={value}
                        setValue={setValue}
                        onChange={HandleCreateData}
                        onEditorChange={handleEditorChange}
                        onSubmit={HandleCreateCourse}
                        userInfo={props.userInfo}
                        t={props.t}
                        loading1={loading1}
                    />
                    <AlertDialog open={open} handleClick={handleClick} text={props.t("success.1")} button={props.t("ok.1")} />
                    <AlertDialog2 open={open2} handleClick={handleClick2} text={props.t("info.6")} button={props.t("ok.1")} />
                    {!loading1 ?
                        <Loading1/>
                        :
                        null
                    }
                </div>
                :
                <Loading />
            }
        </div>
    )
}
