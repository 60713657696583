import React from 'react'
import { Button, Container, Typography, makeStyles } from '@material-ui/core'
import CheckImg from "../../ImgLib/check.png"
import Axios from 'axios';
import NumberFormat from 'react-number-format';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PurchaseSuccess(props) {

    const classes = useStyles();
    const [txnInfo, setTxnInfo] = React.useState([]);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        //let isMounted = true;
        const options = {
            url: window.$payment + "/CheckStatusReqSrcM",
            method: "POST",
            withCredentials: true,
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "token": props.match.params.token
            }
        };
        Axios(options).then(response => {
            if (response.data){
                setTxnInfo(response.data);
                let data = response.data;
                data.map((row)=>(
                    i18n.changeLanguage(row.locale)
                ))
            }
        })
        //return () => { isMounted = false };
    }, [props.match.params.token, i18n])


        return (
            // <div>
            //     {txnInfo.map((data)=>(
            //     <div key={data.id}>
            //         <div><Typography variant="h6">{data.locale}</Typography></div>
            //     </div>
            // ))}
            // </div>
            <Container>
                {txnInfo.map((data)=> (

                    <div key={data.id} className={classes.root}>

                    <div className={classes.contentTittle}>
                        <img src={CheckImg} alt="" className={classes.imgCheck}/>
                        <div className={classes.imgCheckPosition}/>
                        <Typography variant="h3" className={classes.typoH3}>{t("success.3")}</Typography>
                    </div>

                    <div className={classes.content}>
                        <Typography variant="h6" className={classes.typoH6}>{t("success.4")}</Typography>
                        <div className={classes.typoH6Position1}/>
                        <Typography variant="h6" className={classes.typoH6}>
                            <NumberFormat value={data.amount} displayType={'text'} thousandSeparator={true} suffix={' ' + t("currencies.1")} renderText={value => <>{value}</>}/>
                        </Typography>
                    </div>

                    <div className={classes.content}>
                        <Typography variant="h6" className={classes.typoH6}>{t("receipt.14")}</Typography>
                        <div className={classes.typoH6Position2}/>
                        <Typography variant="h6" className={classes.typoH6}>{data.merchant_ref}</Typography>
                    </div>

                    <div style={{marginTop: '2%'}}/>

                        {data.req_src === 1 ?
                        <div className={classes.content}>
                            {/* <Button style={{textTransform: 'none'}} variant="contained">{props.t("info.14")}</Button> */}
                            <div className={classes.typoH6Position2}/>
                            <NavLink to={"/home"} style={{textDecoration: "none"}}>
                                <Button style={{textTransform: 'none'}} variant="contained">{t("info.15")}</Button>
                            </NavLink>
                        </div>
                        :
                        null
                        }

                    <div style={{marginTop: '5%'}}/>
                </div>
                ))}
            </Container>
        )
    }


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.light,  
        marginTop: '2%',
         padding: '0.2%',
         width: '70%',
         borderRadius: 8,
         marginLeft: '15%'
    },
    contentTittle: {
        display: 'flex', 
        justifyContent: 'center', 
        marginTop: '5%'
    },
    imgCheck: {
        width: '6%', 
        height: '2%'
    },
    imgCheckPosition: {
        marginLeft: '0.5%'
    },
    typoH3: {
        color: theme.palette.success.main,
        marginTop: '0.5%'
    },
    content: {
        display: 'flex', 
        justifyContent: 'center'
    },
    typoH6: {
        color: theme.palette.primary.main
    },
    typoH6Position1: {
        marginLeft: '1%'
    },
    typoH6Position2: {
        marginLeft: '2%'
    },


}));