import React from "react";
import { makeStyles, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Button } from "@material-ui/core";
import NumberFormat from 'react-number-format';
import ReactImageAppear from 'react-image-appear';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {NavLink} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 260,
    minWidth: 260,
    borderRadius: 5,
    boxShadow: "none",
    [theme.breakpoints.down('md')]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  media: {
    height: 140,
    objectFit: "contain",
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    [theme.breakpoints.down('md')]: {
      height: 100,
    },
  },
  root: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

export default function ProductCardCategory(props) {
  const classes = useStyles();
  //console.log(props.card);

  return (
    <Card className={`card ${classes.card} ${props.card.id ? props.card.id : ""}`}>
        <NavLink to={props.cardLink + props.card.id} key={props.card.id} style={{textDecoration: "none"}}>
          <Card className={classes.card}>
            <CardActionArea>
              {props.card.img && 
                <CardMedia>
                  <ReactImageAppear 
                    className={classes.media}
                    src={window.$product + "/getCourseImg/" + props.card.img}
                    animation="zoomIn"
                  />
                </CardMedia>
              }
              <div >
              <CardContent style={{height: 6}}>
                  <Typography color="primary" gutterBottom variant="h6">{`${String(props.card.name).substring(0, 45)}`}{String(props.card.name).length > 45 && '...'}</Typography>
                </CardContent>
                <CardContent  style={{height: '1%', color: "#D5D8DC", minWidth: '900px'}}>
                  <Typography color="primary">{`${String(props.card.sellerName).substring(0, 25)}`}{String(props.card.sellerName).length > 25 && '...'}</Typography>
                </CardContent>
              </div>
            </CardActionArea >
            <CardActions >
              {props.card.promoPrice > 0 ?
                 <>
                 <Button color="primary" style={{textDecoration: "line-through"}} className={classes.sectionDesktop}>
                 <LocalOfferIcon />
                   <NumberFormat value={props.card.price} displayType={'text'} thousandSeparator={true} renderText={value => <div>{value}xxx</div>}/>
                 </Button>
                 <Button color="primary" >
                   <LocalOfferIcon className={classes.sectionDesktop}/>
                   <NumberFormat value={props.card.promoPrice} displayType={'text'} thousandSeparator={true} suffix={' '+props.t(props.card.currency)} renderText={value => <div>{value}</div>} />
                 </Button>
                 </>
                 :
                 <>
                 <Button color="primary">
                   <LocalOfferIcon className={classes.sectionDesktop}/>
                   <NumberFormat value={props.card.price} displayType={'text'} thousandSeparator={true} suffix={' '+props.t(props.card.currency)} renderText={value => <div>{value}</div>} />
                 </Button>
                 </>
              }
            </CardActions>
          </Card>
        </NavLink>
    </Card>
  );
}