import React, {createRef, useState} from 'react';
import Axios from "axios";
import AvatarEditor from 'react-avatar-editor';
import { makeStyles, Button, Typography, Box, Grid } from '@material-ui/core';
import LoadingImg from './Loading_img';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 350,
        minHeight: 350
    },

    avatar: {
        height: 150,
        width: 150,
    },
    text: {
        paddingTop: 10,
        paddingBottom: 10
    },
    button: {
        paddingTop: 20,
        paddingBottom: 20
    },
    loading: {
        backgroundColor: 'gray',
        position: 'absolute', 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, 
        justifyContent: 'center', 
        alignItems: 'center',
        opacity: 0.8
    },
    logo: {
        marginTop: '55%',
    },
}));

export default function FileUpload(props) {
    const classes = useStyles();
    const cropper = createRef();
    const [image, setImage] = useState("");
    const [scale, setScale] = useState(1);
    const [file, setFile] = useState("");
    const [showPreviewe, setShowPreviewe] = useState(false);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")

    const handleScale = e => {
        const scale = parseFloat(e.target.value);
        setScale(scale);
    };

    const handleClick = () => {
        setOpen(false)
    }

    const handleNewImage = (e) => {
        setImage(e.target.files[0])
    }
    const handleRef = (editor) => {
        cropper.current = editor;
    }

    const handleCrop = () => {        
        const img = cropper.current.getImageScaledToCanvas().toDataURL("image/*", 1.0).replace("image/*", 1.0);
        setFile(img);
        setShowPreviewe(true)
    }

    const handleSave = () => {
        setLoading(false);
        let formData = new FormData();
        formData.append("file_img", file);
        formData.append("courseID", props.courseID);
        formData.append("token", props.accessToken);
        const options = {
            url: window.$product + "/courseImageupload",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: formData,
        };
        Axios(options).then(response => {
            if(props.img_id){
                if(response.data.status){
                    const options2 = {
                        url: window.$product + "/delCourseImg",
                        method: "POST",
                        headers:{
                            "Accept": "application/json",
                            "content-Type": "application/json;charset-UTF-8"
                            },
                            data: {
                                "file_id": props.img_id,
                                "token": props.accessToken
                            }
                    };
                    Axios(options2).then(response2 =>{
                        if(response2.data.status){
                            window.location.reload()
                        }
                    })
                }else{
                    setMessage(props.t("upload.4"));
                    setOpen(true);
                }
            }else{
                window.location.reload()
            }
        })
    }

    return (
        <div className={classes.root}>
            <div>
                <Box alignItems="center" display="flex" flexDirection="column">
                    <AvatarEditor
                    ref={handleRef}
                    scale={parseFloat(scale)}
                    image={image}
                    width={260}
                    height={140}
                    border={5}
                    />
                </Box>
                <br />
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <input id="image" name="image" type="file" style={{ display: 'none' }} onChange={handleNewImage} />
                    <label htmlFor="image">
                        <Button variant="contained" component="span" style={{ height: "40px", width: "160px" }}>
                            <Typography variant="h6" className={classes.text}> {props.t("image.1")}</Typography>
                        </Button>
                    </label>
                </Grid>
                <br />
                <Typography variant="h6" className={classes.text}> {props.t("image.3")}: </Typography>
                <input
                    name="scale"
                    type="range"
                    onChange={handleScale}
                    min={scale ? "0.1" : "1"}
                    max="2"
                    step="0.01"
                    defaultValue="1"
                />
                <br />
                <br />
                <Button variant="contained" onClick={handleCrop}>{props.t("image.2")}</Button>
                <br />
                <br />
                {showPreviewe ?
                    <div>
                        <Typography variant="h5" className={classes.text}> {props.t("image.4")} </Typography>
                        <Box alignItems="center" display="flex" flexDirection="column">
                            <img src={file} alt="img" />
                        </Box>
                        <div className={classes.button}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Button variant="contained" onClick={() => { props.handleClose() }} >{props.t("cancel.1")}</Button>
                                <Button variant="contained" onClick={() => { handleSave(); }} >{props.t("upload.1")}</Button>
                            </Grid>
                        </div>
                    </div>
                    :
                    null
                }
                <AlertDialog open={open} handleClick={handleClick} text={message} button={props.t("ok.1")}/>
            </div>
            {!loading &&
                <div className={classes.loading}>
                    <div className={classes.logo}>
                    <LoadingImg/>
                    </div>
                </div>
            }
        </div>
    )
}




