import React, {useState, useEffect} from 'react'
import { Dialog, Button, IconButton, DialogTitle, DialogContent, DialogActions, FormControlLabel, FormControl, Radio, Grid, Typography } from '@material-ui/core';
import { Edit, Add, CheckCircle } from '@material-ui/icons';
import Axios from 'axios';

//imgLogo
import BCELOnePayLogo from '../../ImgLib/bcel_onepay.png'
import Master from '../../ImgLib/1_w.png'
import Visa from '../../ImgLib/2.png'
import JCB from '../../ImgLib/3_w.png'
import BCELLogo from '../../ImgLib/BCEL_Logo.png'
import IBogo from '../../ImgLib/IB.png'
import LDBLogo from '../../ImgLib/ldb.png'
import JDBLogo from '../../ImgLib/jdb.png'

export default function PopupEditPaymentMethod(props) {
    const [open, setOpen] = useState(false)
    const [payMethod, setPayMethod] = useState("")
    const [defaultMethod, setDefaultMethod] = useState("")
    const [bankEdit, setBankEdit] = useState("")
    const [defaultBank, setDefaultBank] = useState("")

    useEffect(() => {
        let isMounted = true;
        if (isMounted){
            setDefaultMethod(props.paymentMethod)
            setPayMethod(props.paymentMethod)
            setBankEdit(props.bank)
            setDefaultBank(props.bank)
        }
        return () => { isMounted = false };
    }, [props.paymentMethod, props.bank])

    const onClose = () => {
        setPayMethod(defaultMethod)
        setBankEdit(defaultBank)
        setOpen(false)
    }

    const onChangePayMethod = (e) => {
        setPayMethod(e.target.value)
        setBankEdit("") 
    }

    const validatePayMethod = (pay_method, bank) => {
        if(pay_method){
            if(pay_method === "bank_acc"){
                if(bank){
                    handleUpdatePayMethod()
                }else{
                    alert("ກະລຸນາເລືອກທະນາຄານ")
                }
            }else{
                handleUpdatePayMethod()
            }
        }else{
            alert("ກະລຸນາເລືອກຊ່ອງທາງຊຳລະຫ")
        }
    }

    const handleUpdatePayMethod = () => {
        const options = {
            url: window.$aaa + "/updatePayMethod",
            method: "POST",
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data:{
                "token": props.accessToken,
                "pay_method": bankEdit ? bankEdit : payMethod
            }
        };
        Axios(options).then(response => {
            props.setPaymentMethod(payMethod)
            props.setBank(bankEdit)
            setOpen(false)
        })
    }

    const onClickLogo = (bank) => {
        setBankEdit(bank)
    }

    return (
        <div>
            <IconButton onClick={()=> setOpen(true)}>
                {props.status ? 
                <Edit style={{color: '#fff'}}/>
                    :
                <Add style={{color: '#fff'}}/>
                }
            </IconButton>
            <Dialog 
                open={open} 
                onClose={onClose} 
                aria-labelledby="form-dialog-title" 
                scroll="body"
                maxWidth="sm"
                fullWidth={true}
            >
            <DialogTitle id="alert-dialog-title">{props.t("pay.12")}</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset">
                        <Grid container style={{width: '100%'}}>
                            <Grid container>
                                <Grid item xs={9} sm={10} md={10} xl={10} lg={10}>
                                    <FormControlLabel 
                                        value="debit" 
                                        control={<Radio checked={payMethod === "debit"} onChange={onChangePayMethod}/>} 
                                        label={
                                            <Grid container>
                                                <Grid item xs={5} sm={4} md={4} xl={4} lg={4} style={{display: 'flex'}}>
                                                    <img src={Master} alt="logo" style={{width: '30%'}} />
                                                    <img src={JCB} alt="logo" style={{width: '30%', marginLeft: '3%'}}/>
                                                    <img src={Visa} alt="logo" style={{width: '30%', marginLeft: '3%'}}/>
                                                </Grid>
                                                    <Grid item xs={7} sm={8} md={8} xl={8} lg={8}>
                                                        <Typography style={{marginLeft: '5%', marginTop: '2%'}}>
                                                            {props.t("pay.5")}
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {props.i18n.language === "la" &&
                                <Grid container>
                                    <Grid item xs={9} sm={10} md={10} xl={10} lg={10} style={{marginTop: '5%'}}>
                                        <FormControlLabel 
                                            value="bank_acc" 
                                            control={<Radio checked={payMethod === "bank_acc"} onChange={onChangePayMethod} style={{marginBottom: '15%'}}/>} 
                                            label={
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                                        <Typography>
                                                            {props.t("pay.6")}
                                                        </Typography>
                                                    </Grid>                                            
                                                </Grid>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            }

                            {payMethod === "bank_acc" &&
                                <Grid container style={{width: '100%'}}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12} >   
                                        <img src={BCELLogo} alt="logo" style={{width: '12%', cursor: 'pointer'}} onClick={()=> onClickLogo("BCEL")}/>
                                        {bankEdit === 'BCEL' && <CheckCircle/>}
                                        <img src={IBogo} alt="logo" style={{width: '11%', marginLeft: '3%', cursor: 'pointer'}} onClick={()=> onClickLogo("IB")}/>
                                        {bankEdit === 'IB' && <CheckCircle/>}
                                        <img src={LDBLogo} alt="logo" style={{width: '12%', marginLeft: '3%', cursor: 'pointer'}} onClick={()=> onClickLogo("LDB")}/>
                                        {bankEdit === 'LDB' && <CheckCircle/>}
                                        <img src={JDBLogo} alt="logo" style={{width: '12%', marginLeft: '3%', cursor: 'pointer'}} onClick={()=> onClickLogo("JDB")}/>
                                        {bankEdit === 'JDB' && <CheckCircle/>}
                                    </Grid>
                                    <Typography>{props.t("bank.1")}</Typography>
                                </Grid>
                            }

                            
                                <Grid container>
                                    <Grid item xs={9} sm={10} md={10} xl={10} lg={10}>
                                        <FormControlLabel 
                                        value="BCEL_one" 
                                        control={<Radio checked={payMethod === "BCEL_one"} onChange={onChangePayMethod}/>} 
                                        label={
                                            <Grid container>
                                                <Grid item xs={4} sm={4} md={4} xl={4} lg={4} style={{display: 'flex'}}>
                                                    <img src={BCELOnePayLogo} alt="logo" style={{width: '50%'}}/>
                                                </Grid>
                                                    <Grid item xs={8} sm={8} md={8} xl={8} lg={8}>
                                                        <Typography style={{marginLeft: '1%', marginTop: '8%'}}>
                                                            {props.t("pay.8")}
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        />
                                    </Grid>
                                </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions >
                    <Button onClick={()=> validatePayMethod(payMethod, bankEdit)}>
                        {props.t("ok.1")}
                    </Button>
                    <Button onClick={onClose}>
                        {props.t("cancel.1")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
