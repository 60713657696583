import React from "react";
import { NavLink } from "react-router-dom";
import { Grid, makeStyles, Typography, Container } from "@material-ui/core";
import IconGrid from "bt_modules/IconGrid/IconGrid";

const useStyles = makeStyles((theme) => ({
    contain: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        borderRadius: 10,
        padding: "2%",
        marginTop: '1%'
    }, 
    grid: {
        padding: "2%",
    },
    title: {
        // paddingTop: "3%",
        // paddingBottom: "3%",
        backgroundColor: theme.palette.primary.main,
        padding: '0.5%',
        paddingLeft: '3%',
    },
    textLink: {
        color: theme.palette.secondary.light, 
        textDecoration: "none",  
        fontWeight: 'lighter'
    }
}));

export default function IconGridList(props){
    const classes = useStyles();
    return (
        <Container style={{marginTop: '4%'}}>
            <Grid className={classes.title} >
                <Typography variant="h5"> 
                    <NavLink to={"/category"} className={classes.textLink}>
                        {props.titleText} 
                    </NavLink>    
                </Typography>
            </Grid>
            <Grid className={classes.contain}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {props.iconsInfo.map(iconInfo => (
                        <Grid item xs={6} sm={4} md={3} xl={2} className={classes.grid} key={iconInfo.id}>
                            <IconGrid iconInfo={iconInfo} t={props.t}/> 
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
};