import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SiteLogo from 'ImgLib/GIF.gif';

const useStyles = makeStyles((theme) => ({
    SiteLogo: {
        display: "flex",
        flexDirection: "row"
    },
}));

export default function Logo() {
    const classes = useStyles();
    return (
        <div className={classes.SiteLogo}>
            <img src={SiteLogo} alt="" style={{ width: "105%" }} />
        </div>
    )
}