import React from 'react'
import PaymentFail from 'bt_modules/Purchase/PaymentFail'

export default function Pg1failPage(props) {
    return (
        <div>
            <PaymentFail {...props}/>
        </div>
    )
}
