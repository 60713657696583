import React, {useState} from 'react';
import {Container, Typography, Grid, ButtonBase, makeStyles} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess } from '@material-ui/icons';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import VideoTrailer from '../ProductPage/SampleVideo';

const useStyles = makeStyles((theme) => ({
    containerTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light, 
        padding: '0.5%',
        paddingLeft: '3%', 
        fontWeight: 'lighter'
    },
    containerContent: {
        backgroundColor: theme.palette.secondary.light, 
        //color: 'black', 
        width: '100%', 
        borderRadius: 5
    },
    videoFree: {
        color: theme.palette.secondary.dark, 
        marginBottom: '-1%'
    },
    textVideoFree: {
        color: theme.palette.secondary.dark, 
        display: 'flex'
    },
    iconVideo: {
        color: theme.palette.primary.light, 
        marginBottom: '-1%'
    },
    videoFee: {
        color: theme.palette.primary.light, 
        display: 'flex'
    }
}));

export default function ProductContent(props) {
    const classes = useStyles();
    const [expand, setExpand] = useState(false);
    const [videoTrailer, setVideoTrailer] = useState();
    const [openDialogVideoTrailer, setOpenDialogVideoTrailer] = useState(false);
    const [lessonID, setLessonID] = useState()
    

    const handleOpenSection = (id) => {
        let newSectionsInfo = props.productSectionsInfo.map((sectionInfo) => {
            if(sectionInfo.sectionId === id){
                sectionInfo.open = !sectionInfo.open
            }
            return sectionInfo;
        })
        if(newSectionsInfo.every(value => value.open === true)){
            setExpand(true)
        }else{
            setExpand(false)
        }
        props.setProductSectionsInfo(newSectionsInfo);
    }

    const handleExampleVideo = (filename, isFree, lesson_id) =>{
        if(isFree === 1){
            setVideoTrailer(filename);
            setLessonID(lesson_id)
            setOpenDialogVideoTrailer(true);
        }
    };

    const handleExpandAll = () => {
        props.productSectionsInfo.map((obj) => {
            obj.open = true;
            return obj;
        })
        setExpand(true);
    }
    
    const handleCollapseAll = () => {
        props.productSectionsInfo.map((obj) => {
            obj.open = false;
            return obj;
        })
        setExpand(false);
    }

    return (
        <div>
            <Container>
                
                <Grid container className={classes.containerTitle}>
                    <Grid item xs={12}>
                        <Typography variant="h4">{props.t("content.1")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h5">
                            {props.sectionsCount} {props.t("content.2")} , {props.lessonsCount} {props.t("content.3")} 
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <ButtonBase style={{marginLeft: '45%'}}>
                            {expand ?
                                <Typography variant="h5" onClick={handleCollapseAll}>
                                    {props.t("collapse.1")}
                                </Typography>
                                :
                                <Typography variant="h5" onClick={handleExpandAll}>
                                    {props.t("collapse.2")}
                                </Typography>
                            }
                        </ButtonBase>
                    </Grid>
                </Grid><br/>

                <div className={classes.containerContent}><br/>
                    {props.productSectionsInfo.map((courseCTVDO) => (
                        <Grid container key={courseCTVDO.sectionId}>
                            <Grid item xs={1} >
                                <ButtonBase onClick={() => {handleOpenSection(courseCTVDO.sectionId); }} style={{marginLeft: "30%"}}>
                                    { courseCTVDO.open ? <ExpandLess style={{marginLeft: '50%'}}/> : <ExpandMore style={{marginLeft: '50%'}}/> } 
                                </ButtonBase>
                            </Grid>
                            <Grid item xs={11}>
                                <ButtonBase onClick={() => {handleOpenSection(courseCTVDO.sectionId); }} style={{marginLeft: '5%'}}>
                                    <Typography variant="h6">
                                        {courseCTVDO.sectionName}
                                    </Typography>
                                </ButtonBase><hr style={{marginLeft: '-6%', width: '103%'}}/>
                                <Collapse in={courseCTVDO.open} timeout="auto">
                                    {props.productLessonsInfo.map((lesson) => {
                                        return(
                                            courseCTVDO.sectionId === lesson.subSectionParentID &&
                                            <Grid container key={lesson.subSectionId} >
                                                <Grid item xs={11} >
                                                    {lesson.subSectionFreeItem === 0 ?
                                                        <ButtonBase style={{marginLeft: '5%'}}><Typography variant="subtitle1" className={classes.textVideoFree}>
                                                                <PlayCircleFilledIcon className={classes.videoFree}/> &nbsp; {lesson.subSectionName}
                                                            </Typography>
                                                        </ButtonBase>
                                                        :
                                                        <ButtonBase onClick={()=> { handleExampleVideo(lesson.subSectionCloudName, lesson.subSectionFreeItem, lesson.subSectionId)}} style={{marginLeft: '5%'}}>
                                                            <Typography variant="subtitle1" className={classes.videoFee}>
                                                                <PlayCircleFilledIcon className={classes.iconVideo}/> &nbsp; {lesson.subSectionName}
                                                            </Typography>
                                                        </ButtonBase>
                                                    }
                                                </Grid>
                                                <Grid item xs={1} >
                                                    {lesson.subSectionDuration}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}<hr style={{marginLeft: '-6%', width: '102%'}}/>
                                </Collapse>
                            </Grid>
                        </Grid>
                    ))}<br/>
                </div>
            </Container><br/>
            <VideoTrailer openDialogVideoTrailer={openDialogVideoTrailer} setOpenDialogVideoTrailer={setOpenDialogVideoTrailer} videoTrailer={videoTrailer} lessonID={lessonID} setVideoTrailer={setVideoTrailer}/>
        </div>
    )
}