import React from 'react'
import {Dialog, Button, DialogActions,  DialogContent, makeStyles, DialogTitle} from '@material-ui/core';
import TermsAndConditions from 'Apps/Public/Terms/TermsAndConditions';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#36454F',
    },
    dialog: {
        backgroundColor: '#36454F',
    },
    text: {
        color: '#fff',
    }
}));

export default function AlertTermAndCon(props) {
    const classes = useStyles();

    return (
        <div>
            <Dialog 
                open={props.open} 
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description" 
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title" className={classes.center}><img src={props.logo} alt="props.logo" /></DialogTitle>
                <DialogContent>
                    <TermsAndConditions i18n={props.i18n}/>
                </DialogContent>
                <DialogActions className={classes.dialog}>
                    <Button className={classes.text} onClick={props.onAgree}>Agree</Button>
                    <Button className={classes.text} onClick={()=> props.setOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
