import React from 'react';
import { Grid } from '@material-ui/core';
import LogoLoarding from 'ImgLib/GIF.gif';

export default function Loading() {

    return (
        <div style={{ marginTop: "20%" }}>
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <img src={LogoLoarding} alt="" style={{ width: "10%" }} />
        </Grid>
        </div>
    )
}