import React, {useEffect, useState} from 'react'
import Axios from "axios";
import ManageProduct from "bt_modules/ManageProduct/ManageProduct"
import Loading from 'bt_modules/Loading';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import AlertDialog2 from 'bt_modules/AlertDialog/AlertDialog';

export default function ManageCourse(props) {
    const [item, setItem] = useState([]);
    const [edit, setEdit] = useState({})
    const [addNewSection, setAddNewSection] = useState([]);
    const [videoList, setVideoList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [message, setMessage] = useState("");

    const handleClick2 = () => {
        setOpen2(false)
    }

    const handleClick3 = () => {
        setOpen3(false)
    }

    useEffect(() => {
        if(props.accessToken){
            let isMounted = true;
            const options = {
                url: window.$product + "/getManageCourseInfo",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "course_id": props.match.params.id,
                    "token": props.accessToken
                }
            };
            Axios(options).then(response => {
                if (isMounted){
                    if(response.data.courseInfo.length > 0){
                        setItem(response.data.courseInfo);
                        setEdit({ 
                            name: response.data.courseInfo[0].name,
                            price: response.data.courseInfo[0].price,
                            description: response.data.courseInfo[0].description,
                            currency: response.data.courseInfo[0].currency
                        })
                    }
                    if(response.data.sectionInfo){
                        let arr = [...response.data.sectionInfo];
                        arr.map((data)=>{
                            data.openSec = false;
                            data.open = false;
                            data.openContentTypeVDO = false;
                            return data;
                        })
                        setAddNewSection(arr);
                    }
                    if(response.data.lessonsInfo){
                        setVideoList(response.data.lessonsInfo)
                    }
                    if(response.data.resourceInfo){
                        setFileList(response.data.resourceInfo)
                    }
                    setLoading(false);
                }          
            })
            return () => { isMounted = false };
        }
    }, [props]);

    const handleEdit = () => {
        if(edit.price && edit.name && edit.description){
            if(!isNaN(edit.price)){
                const options = {
                    url: window.$product + "/updateCourseData",
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "content-Type": "application/json;charset-UTF-8"
                    },
                    data: {
                        "course_id": props.match.params.id,
                        "token": props.accessToken,
                        "editData": edit
                    }
                };
                Axios(options).then(response => {
                    if(response.data.status){
                        let newArray = [...item];
                        newArray.map((obj) =>{
                            obj.name = edit.name;
                            obj.price = edit.price;
                            obj.description = edit.description;
                            return obj;
                        })
                        setItem(newArray)
                        setOpen(false)
                        setOpen2(true)
                    }
                })
            }else{
                setMessage(props.t("info.7"))
                setOpen3(true)
            }
        }else{
            setMessage(props.t("info.6"))
            setOpen3(true)
        }
    }

    // const handleSubmit = () => {
    //     const options = {
    //         url: window.$product + "/submitCourse",
    //         method: "POST",
    //         headers: {
    //             "Accept": "application/json",
    //             "content-Type": "application/json;charset-UTF-8"
    //         },
    //         data: {
    //             "course_id": props.match.params.id,
    //             "token": props.accessToken
    //         }
    //     };
    //     Axios(options).then(response => {
    //         if(response.data.status){
    //             setItem([])
    //             setAddNewSection([]);
    //             setMessage(props.t("success.2"))
    //             setOpen3(true)
    //         }
    //     })
    // }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setEdit({
            ...edit,
            [e.target.name] : e.target.value
        })
    }

    return (
        <div>
            {!loading ? 
            <div>
                <ManageProduct 
                    setItem={setItem}
                    item={item}
                    title={props.t("course.11")}
                    submitButton={props.t("finish.2")}
                    edit={edit}
                    setEdit={setEdit}
                    handleEdit={handleEdit}
                    //handleSubmit={handleSubmit}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    addNewSection={addNewSection}
                    setAddNewSection={setAddNewSection}
                    open={open}
                    setOpen={setOpen}
                    handleChange={handleChange}
                    videoList={videoList}
                    setVideoList={setVideoList}
                    fileList={fileList}
                    setFileList={setFileList}
                    editable={true}
                    accessToken={props.accessToken}
                    t={props.t}
                />
                <AlertDialog open={open2} handleClick={handleClick2} text={props.t("success.1")} button={props.t("ok.1")}/>
                <AlertDialog2 open={open3} handleClick={handleClick3} text={message} button={props.t("ok.1")}/>
            </div>
            :
            <Loading/>
            }
        </div>
    )
}
