import React from 'react';
import {Container, Typography, makeStyles} from '@material-ui/core';
import ShowMoreText from 'react-show-more-text';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
    },
    title: {
        padding: '0.5%',
        paddingLeft: '3%', 
        fontWeight: 'lighter', 
        color: theme.palette.secondary.light
    },
    boxDescription: {
        backgroundColor: theme.palette.secondary.light, 
        width: '100%', 
        borderRadius: 5, 
        padding: '2%'
    },
    descArea: {
        fontSize: '120%'
    },
    description: {
        textDecoration: 'none', 
        display:'inlineblock',
        color: theme.palette.primary.main,
    }

}));

export default function CourseDescription(props) {
    const classes = useStyles();

    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }
    
    return (
        <div>
            <Container>
                <div className={classes.root}>
                <Typography variant="h5" className={classes.title}>
                    {props.t("description.1")}
                </Typography>
                </div><br/>
                <div className={classes.boxDescription}>
                    <ShowMoreText
                        lines={3}
                        more={<div className={classes.description}>Show more<ExpandMore style={{marginBottom: '-5'}}/></div>}
                        less={<div className={classes.description}>Show less<ExpandLess style={{marginBottom: '-5'}}/></div>}
                        className={classes.descArea}
                        anchorClass='wrapper-class'
                        onClick={executeOnClick}
                        expanded={false}
                        width={2000}
                        style={{ textDecoration: 'none', display:'block'}}
                    >
                        {ReactHtmlParser(props.productDescription)}
                    </ShowMoreText>
                </div>
            </Container><br/>
        </div>
    )
}