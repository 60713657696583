import React, {useState, useEffect} from 'react';
import {Typography, Button, Container, Grid, makeStyles, CardHeader, Divider} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import CardListRow from "bt_modules/CardListRow/CardListRow";
import CheckoutForm from "bt_modules/Checkout/CheckoutForm";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2%",
        marginBottom: "2%"
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light
    },
}));

export default function CartPage(props) {
    const classes = useStyles();
    const [renderComp, setRenderComp] = useState("main");
    const [amount, setAmount] = useState(0);

    const handlePayment = () => {
        setRenderComp("pay");
    }

    useEffect(() => {
        let isMounted = true;
        if(isMounted){
            let Amount = 0;
            props.cardsInfo.map((cardInfo)=>
                cardInfo.promoPrice > 0 ?
                    Amount = Number(Amount) + Number(cardInfo.promoPrice)
                    :
                    Amount = Number(Amount) + Number(cardInfo.price)
            )
            setAmount(Amount);
        }
        return () => { isMounted = false };
    }, [props.cardsInfo])

    const Discount = props.cardsInfo.reduce((prev,next) => Number(prev) + Number(next.discount), 0);

    return (
    <Container className={classes.root}>
        {props.cardsInfo.length > 0 ?
            <>
            {renderComp==="main" &&
            <>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={8} xl={8} lg={8} >
                        <CardHeader
                            title={props.t("have.1") + "  " +  props.cardsInfo.length + "  "  +  props.t("cart.6")}
                            className={classes.cardHeader}
                        >
                        </CardHeader>
                        <Grid item lg={12}>
                            <CardListRow cardsInfo={props.cardsInfo} cardLink="/course/" textLen={270} handleDelete={props.handleDelete} delLink="/cart" t={props.t}/>
                        </Grid>
                    </Grid>

                    <Grid className={classes.sidebar} item container direction="column" xs={12} sm={12} md={4} xl={4} lg={4}>
                        <Grid item container direction="column">
                            <CardHeader
                                title={props.t("pay.11")}
                                className={classes.cardHeader}
                            />
                        </Grid>
                        <br/>

                        <div style={{display: 'flex', justifyContent: 'space-between', padding: '1%'}}>
                            <Grid item>
                                <Typography variant="h6">
                                    {props.t("price.4")}:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">
                                    <NumberFormat value={amount - Discount} displayType={'text'} thousandSeparator={true} suffix={' '+props.t(props.cardsInfo[0].currency)} renderText={value => <div>{value}</div>} />
                                </Typography>
                            </Grid>
                        </div>

                        <Divider />
                        <br/>

                        <Grid item>
                            <Button variant="contained" color="primary" fullWidth onClick={handlePayment}>
                                {props.t("pay.1")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
            }

            {renderComp==="pay" && 
                <CheckoutForm
                setRenderComp={setRenderComp}
                payAmount={amount}
                discount={Discount}
                productCurrency = {props.cardsInfo[0].currency}
                userInfo={props.userInfo}
                //productId={false}
                {...props}
                />
            }
            </>
            :
            <Grid >
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <img src={window.$img+'/empty_cart.png'} alt=''/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center', padding: 40}}>
                    <Typography variant="h4">{props.t("wishlist.5")}</Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <NavLink to={"/home"} style={{color:"#fff", margin:20, textDecoration: "none"}}>
                        <Button variant="contained" color="primary" style={{ width: 150}}>
                            {props.t("home.1")}
                        </Button>
                    </NavLink>
                </Grid>
            </Grid>
        }
    </Container>
    )
}