import React from 'react'
import { makeStyles, MenuItem, Button, Paper, Typography, TextField, Grid } from '@material-ui/core';
import TextEditor from './TextEditor'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        marginLeft: "10%"
    }, 
    resetContainer: {
        padding: theme.spacing(3),
        borderRadius: 8
    },
}));

export default function CreateCourse(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper square elevation={0} className={classes.resetContainer}>
                <Typography variant="h3" gutterBottom color="primary">{props.title}</Typography>
                <TextField
                    style={{width:"100%"}}
                    label={props.t("course.8")}
                    name='name'
                    value={props.value.name || ""}
                    onChange={props.onChange}
                    variant="outlined"
                />
                <br/><br/>
                <TextField
                    style={{width:"100%"}}
                    name='cat_id'
                    label={props.t("course.9")}
                    value={props.value.cat_id ||""}
                    onChange={props.onChange}
                    select
                    variant="outlined"
                >
                    {props.categories.map((row, index) => (
                        <MenuItem key={index} value={row.id}>
                            {props.t(row.cat_name+".1")}
                        </MenuItem>
                    ))}
                </TextField>
                <br/><br/>
                <TextField
                    style={{width:"100%"}}
                    name='language'
                    label={props.t("language.2")}
                    value={props.t("locale."+props.userInfo.locale) ||""}
                    disabled={true}
                    variant="outlined"
                />
                <br/><br/>
                <TextField
                    style={{width:"100%"}}
                    name='currency'
                    label={props.t("currency.1")}
                    value={props.t(props.userInfo.currency) ||""}
                    disabled={true}
                    variant="outlined"
                />
                <br/><br/><br/>
                <Typography color="primary">{props.textEditorTitle}</Typography>
                <br/>
                <TextEditor value={props.value} setValue={props.setValue} onEditorChange={props.onEditorChange}/>
                <br/><br/>
                <Grid>
                    <Button variant="contained" color="primary" onClick={props.onSubmit}>
                        {props.button}
                    </Button>
                </Grid>
            </Paper>
        </div>
    )
}
