import React, {useEffect, useState, useRef} from 'react';
import { Typography,Grid,Container,Button,makeStyles,CardHeader,Divider, CardMedia} from '@material-ui/core';
import Axios from 'axios';
import NumberFormat from 'react-number-format';
import AddressBook from "Apps/Public/AddressBook";
import PopupEditPaymentMethod from 'bt_modules/Popup/PopupEditPaymentMethod';
import Loading from 'bt_modules/Loading';
import { NavLink } from 'react-router-dom';

//imgLogo
import BCELOnePayLogo from '../../ImgLib/bcel_onepay.png'
import Master from '../../ImgLib/1_w.png'
import Visa from '../../ImgLib/2.png'
import JCB from '../../ImgLib/3_w.png'
import IBLogo from '../../ImgLib/IB.png'
import BCELLogo from '../../ImgLib/BCEL_Logo.png'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "1%",
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 8
    },
    content: {
        padding: "1%"
    },
    sidebar: {
        padding: "1%",
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light
    },
    cardContent: {
        padding: "2%"
    },
    cardMedia: {
        margin: "1%"
    },
    textBox: {
        padding: "2%",
    },
    textBox1: {
        padding: "2%"
    },
    textColor: {
        color: theme.palette.error.main
    },
    termColor: {
        color: theme.palette.error.main
    }
}));

export default function CheckoutForm(props) {
    const classes = useStyles();
    const ref = useRef(null);
    const [referenceNumber, setReferenceNumber] = useState("");
    const [productData, setProductData] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState("")
    const [bank, setBank] = useState("")
    const [addrData, setAddrData] = useState([]);
    const [loading, setLoading] = useState(false)
   
    useEffect(() => {
        let isMounted = true;
        setLoading(true)
        const options = {
            url: window.$aaa + "/getCheckOutInfo",
            method: "POST",
            withCredentials: true,
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "token":props.accessToken,
                "productId": props.productId ? props.productId : null,
                "amount":props.payAmount
                //"locale": props.i18n.language
            }
        };
        Axios(options).then(response => {
            if (isMounted){
                //console.log(response.data)
                if(response.data.payMethod){
                    if(response.data.payMethod === "IB" || response.data.payMethod === "BCEL" ){
                        setPaymentMethod("bank_acc")
                        setBank(response.data.payMethod)
                    }else{
                        setPaymentMethod(response.data.payMethod)
                    }
                }
                setProductData(response.data.productInfo);
                setReferenceNumber(response.data.reference_number);
                setAddrData(response.data.billAddr);
                setLoading(false)
            }
        })
        return () => { isMounted = false };
    }, [props.accessToken, props.productId, props.payAmount])

    const getCurrency = (value) => {
        var curr = ""
        if(value === "la"){
            curr = "418"
        }else if(value === "th"){
            curr = "764"
        }else if(value === "en"){
            curr = "840"
        }
        return curr;
    }

    const hiddenButton = (addr, payMethod, bank) => {
        if(payMethod && checkAddress(addr)){
            if(payMethod !== "bank_acc"){
                return false
            }else{
                if(bank){
                    return false
                }else{
                    return true
                }
            }
        }else{
            return true
        }
    }

    const hiddenPaymethod = (payMethod, bank) => {
        if(payMethod){
            if(payMethod !== "bank_acc"){
                return false
            }else{
                if(bank){
                    return false
                }else{
                    return true
                }
            }
        }else{
            return true
        }
    }

    const checkAddress = (data) => {
        if(data.addr && data.city && data.phone && data.zip && data.state){
                return true
            }else{
                return false
            }
    }

    const PaymentOption = () => {
        if(paymentMethod === "bank_acc" || paymentMethod === "debit"){
            if(bank === "IB" || paymentMethod === "debit"){
                const options = {
                    url: window.$payment + "/IBencrypt",
                    method: "POST",
                    headers:{
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                    },
                    data:{
                        "token": props.accessToken,
                        "amount": props.payAmount,
                        "ccy": getCurrency(props.i18n.language),
                        "mobile": props.userInfo.phone ? props.userInfo.phone : "",
                        "name": (props.userInfo.firstname && props.userInfo.lastname)  ? props.userInfo.firstname + " " + props.userInfo.lastname : "",
                        "address": props.userInfo.state ? props.userInfo.state : "",
                        "email": props.userInfo.email ? props.userInfo.email : "",
                        "course_id": props.productId,
                        "merchant_ref": referenceNumber,
                        "req_src": "1"
                    }
                };
                Axios(options).then((res)=>{
                    if(res.data){
                        switch(res.data.respcode){
                            case '50':
                                window.location.href = res.data.redirectionURL;
                                break
                            default:
                                //console.log(res.data)
                                break
                        }
                    }else{
                        console.log("Error occurred")
                    }
                });
            }else{
                ref.current.click();
            }
        }else{
            ref.current.click();
        }
        //ref.current.click();
    }
    
    const PaymentButton = () => {
        if(props.i18n.language === "la"){
            return (
                <div>
                    <div style={{display: "none"}}>
                        <form action={window.$payment + '/bcelEncrypt'} method='post'>
                            <input type="hidden" name="locale" value={props.i18n.language}/>
                            <input type="hidden" name="amount" value={props.payAmount}/>
                            <input type="hidden" name="token" value={props.accessToken}/>
                            <input type="hidden" name="reference_number" value={referenceNumber}/>
                            <input type="hidden" name="req_src" value="1"/>
                            <Button ref={ref} type='submit' variant="contained" color="primary" fullWidth>{props.t("pay.1")}</Button>
                        </form>
                    </div>
                    <Button disabled={hiddenButton(addrData, paymentMethod, bank)} variant="contained" color="primary" fullWidth onClick={PaymentOption}>{props.t("pay.1")}</Button>
                </div>
            )
        }else{
            return (
                <div>
                    <form action={window.$payment + '/bcelEncrypt'}  method='post'>
                            <input type="hidden" name="locale" value={props.i18n.language}/>
                            <input type="hidden" name="amount" value={props.payAmount}/>
                            <input type="hidden" name="userid" value={props.accessToken}/>
                            <input type="hidden" name="reference_number" value={referenceNumber}/>
                            <input type="hidden" name="req_src" value="1"/>
                        <Button disabled={hiddenButton(addrData, paymentMethod, bank)} type='submit' variant="contained" color="primary" fullWidth>{props.t("pay.1")}</Button>
                    </form>
                </div>
            )
        }
    }

    const handleCancel = () => {
        props.setRenderComp("main");
    }

    if(!loading){
        return (
            <div>
                <br/>
                <Container className={classes.root} >
                    <Grid container >
                        <Grid className={classes.content} item container direction="column" xs={12} sm={12} md={8} xl={8} lg={8}>
                            <Grid item container direction="column">
                                <CardHeader
                                    title={props.t("pay.2")}
                                    className={classes.cardHeader}
                                    action={<PopupEditPaymentMethod 
                                        paymentMethod={paymentMethod}
                                        setPaymentMethod={setPaymentMethod}
                                        bank={bank} 
                                        setBank={setBank}
                                        //banks={banks}
                                        accessToken={props.accessToken}
                                        status={!hiddenPaymethod(paymentMethod, bank)}
                                        i18n={props.i18n}
                                        t={props.t}/>
                                    }
                                />
                                <Grid item container className={classes.textBox1}>
                                    <Grid style={{ width:'100%'}}>
                                    {!hiddenPaymethod(paymentMethod, bank) ?
                                    <div>
                                    {paymentMethod === "debit" &&
                                        <Grid container>
                                            <Grid item xs={3} sm={2} md={2} xl={2} lg={3}>
                                                <img src={Master} alt="logo" style={{width: '30%'}}/>
                                                <img src={JCB} alt="logo" style={{width: '30%', marginLeft: '3%'}}/>
                                                <img src={Visa} alt="logo" style={{width: '30%', marginLeft: '3%'}}/>
                                            </Grid>
                                            <Grid item xs={9} sm={10} md={10} xl={10} lg={9}>
                                                <Typography style={{marginLeft: '5%', marginTop: '1%'}}>{props.t("pay.5")}</Typography>
                                            </Grid>
                                        </Grid> 
                                    }

                                    {paymentMethod === "bank_acc" && props.i18n.language === "la" ?
                                        <div>
                                            {bank === "IB" && bank  ? 
                                            <Grid container>
                                                 <Grid item xs={3} sm={2} md={2} xl={2} lg={2}>
                                                    <img src={IBLogo} alt="logo" style={{width: '80%',}}/>
                                                </Grid>                                               
                                                <Grid item xs={9} sm={10} md={10} xl={10} lg={10}>
                                                    <Typography style={{marginLeft: '5%', marginTop: '1%'}}>{props.t("pay.7")}</Typography> 
                                                </Grid>
                                            </Grid>
                                            : null}
                                            
                                            {bank === "BCEL" && bank ? 
                                                <Grid container>
                                                    <Grid item xs={3} sm={2} md={2} xl={2} lg={2}>
                                                        <img src={BCELLogo} alt="logo" style={{width: '80%'}} />
                                                    </Grid>
                                                    <Grid item xs={9} sm={10} md={10} xl={10} lg={10}>
                                                        <Typography style={{marginLeft: '5%', marginTop: '1%'}}>{props.t("pay.9")}</Typography>
                                                    </Grid>
                                                </Grid>  
                                            : null}
                                        </div>
                                        :null
                                    }

                                    {props.i18n.language === "la" && paymentMethod === "BCEL_one" ?
                                        <Grid container>
                                            <Grid item xs={3} sm={2} md={2} xl={2} lg={2}>
                                                <img src={BCELOnePayLogo} alt="logo" style={{width: '100%'}}/>
                                            </Grid>
                                            <Grid item xs={9} sm={10} md={10} xl={10} lg={10}>
                                                <Typography style={{marginLeft: '5%', marginTop: '1%'}}>{props.t("pay.8")}</Typography>
                                            </Grid>
                                        </Grid>
                                        : null
                                    }
                                    </div>
                                    :
                                    <div style={{display: 'flex'}}>
                                    <Typography className={classes.textColor}>{props.t("pay.10")}</Typography>
                                        <div style={{marginTop: '-2%'}}>
                                            <PopupEditPaymentMethod 
                                                paymentMethod={paymentMethod}
                                                setPaymentMethod={setPaymentMethod}
                                                bank={bank} 
                                                setBank={setBank}
                                                //banks={banks}
                                                accessToken={props.accessToken}
                                                status={!hiddenPaymethod(paymentMethod, bank)}
                                                i18n={props.i18n}
                                                t={props.t}
                                            />
                                        </div>
                                    </div>
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" className={classes.card}>
                                <CardHeader
                                    title={props.t("bill.1")}
                                    action={
                                        <AddressBook 
                                            accessToken={props.accessToken}
                                            addrData={addrData} 
                                            setAddrData={setAddrData}
                                            i18n={props.i18n}
                                            payAmount={props.payAmount}
                                            productId={props.productId}
                                            setProductData={setProductData}
                                            status={checkAddress(addrData)}
                                            t={props.t}
                                    />
                                    }
                                    className={classes.cardHeader}
                                />
                                <Grid item container className={classes.textBox}>
                                    {checkAddress(addrData) ? 
                                        <Grid item>
                                            <Typography>{props.t("address.1")}: {addrData.addr}, {addrData.city}, {addrData.state}</Typography>
                                            <Typography>{props.t("info.1")}: {addrData.phone}</Typography>
                                            <Typography>{props.t("info.13")}: {addrData.zip}</Typography>                     
                                        </Grid>
                                        :
                                        <Grid item style={{display: 'flex'}}>
                                            <Typography className={classes.textColor}>{props.t("bill.2")}</Typography>
                                            <div style={{marginTop: '-5%'}}>
                                                <AddressBook 
                                                    accessToken={props.accessToken}
                                                    addrData={addrData} 
                                                    setAddrData={setAddrData}
                                                    i18n={props.i18n}
                                                    payAmount={props.payAmount}
                                                    productId={props.productId}
                                                    setProductData={setProductData}
                                                    status={checkAddress(addrData)}
                                                    t={props.t}
                                                />
                                            </div>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" className={classes.card} >
                                <CardHeader
                                    title={props.t("course.19")}
                                    className={classes.cardHeader}
                                /><br/>
                                {productData.map((data)=> (
                                <Grid container key={data.id}>
                                    <Grid item xs={2} sm={1} md={1} xl={1} lg={1} container justifyContent="center">
                                        {data.img_id &&
                                            <CardMedia
                                            component="img"
                                            width="20%"
                                            image={window.$product + "/getCourseImg/" + data.img_id}
                                        />
                                        }
                                    </Grid>
                                    <Grid item xs={8} sm={9} md={9} xl={9} lg={9} className={classes.cardContent} container justifyContent="flex-start">
                                        <Typography>{data.name}</Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} xl={2} lg={2} className={classes.cardContent}  container justifyContent="center" >
                                        <Typography><NumberFormat value={data.price} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t(props.productCurrency)} renderText={value => <>{value}</>}/></Typography>
                                    </Grid>
                                    
                                </Grid>))}
                                <br/>
                            </Grid>
                        </Grid>
                        <Grid className={classes.sidebar} item container direction="column" xs={12} sm={12} md={4} xl={4} lg={4}>
                            <Grid item container direction="column">
                                <CardHeader
                                    title={props.t("pay.11")}
                                    className={classes.cardHeader}
                                /><br/>
                                <Grid item container className={classes.textBox}>
                                    <Grid item xs={6}>
                                        <Typography>{props.t("price.4")}</Typography>
                                    </Grid>
                                    <Grid item xs={6} container justifyContent="flex-end">
                                    <Typography><NumberFormat value={props.payAmount} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t(props.productCurrency)} renderText={value => <>{value}</>}/></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.textBox}>
                                    {props.discount ?
                                    <Grid item container className={classes.textBox}>
                                        <Grid item xs={6}>
                                            <Typography>{props.t("discount.1")}</Typography>
                                        </Grid>
                                        <Grid item xs={6} container justifyContent="flex-end">
                                            <Typography><NumberFormat value={props.discount} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t(props.productCurrency)} renderText={value => <>{value}</>}/></Typography>
                                        </Grid>
                                    </Grid>
                                    :null}
                                </Grid>
                                <Divider />
                                <Grid item container className={classes.textBox} >
                                    <Grid item xs={6}>
                                        <Typography>{props.t("price.1")}</Typography>
                                    </Grid>
                                    <Grid item xs={6} container justifyContent="flex-end">
                                    {props.discount ? 
                                        <Typography><NumberFormat value={Number(props.amount) - Number(props.payAmount)} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t(props.productCurrency)} renderText={value => <>{value}</>}/></Typography>
                                        :
                                        <Typography><NumberFormat value={props.payAmount} displayType={'text'} thousandSeparator={true} suffix={' ' + props.t(props.productCurrency)} renderText={value => <>{value}</>}/></Typography>
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid item>
                                <div style={{marginTop: '-2%'}}>
                                    <Typography variant="subtitle2"> {props.t("term.1")} <NavLink to={"/terms_and_conditions"} className={classes.termColor}> {props.t("term.2")} </NavLink> {props.t("term.3")} </Typography>

                                </div>
                                <div style={{marginTop: '5%'}}/>
                                <PaymentButton/>
                            </Grid>
                            <br/>
                            <Grid item>
                                <Button variant="contained" fullWidth onClick={handleCancel} >{props.t("cancel.1")}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }else{
        return ( 
            <div>
                <Loading/>
            </div>
        )
    }
}