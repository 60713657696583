import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from "bt_modules/Dashboard/Card/Card.js";
import CardHeader from "bt_modules/Dashboard/Card/CardHeader.js";
import CardIcon from "bt_modules/Dashboard/Card/CardIcon.js";
import CardFooter from "bt_modules/Dashboard/Card/CardFooter.js";
import styles from "bt_modules/Dashboard/assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Danger from "bt_modules/Dashboard/Typography/Danger.js";
import Refresh from '@material-ui/icons/Refresh';
import { NavLink } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core'
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles(styles);

export default function InfoCard(props) {
  const classes = useStyles();
  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <div>
      <Card >
        <CardHeader color={props.color} stats icon>
          <CardIcon color={props.color}>
            {props.icon}
          </CardIcon>
          {props.withdraw ?
            <CardIcon >
              <div style={{ width: '55%'}}>
                <p className={classes.cardCategory} style={{marginRight: '-5%',color: 'black'}}>
                  {props.title}
                </p>
                <Typography variant='h4' className={classes.cardTitle} style={{color: 'red'}}>
                  {props.value}
                </Typography>
              </div>
              </CardIcon>
              :
              <div>
              <p className={classes.cardCategory}>
                {props.title}
              </p>
              <h3 className={classes.cardTitle}>
                {props.value}
              </h3>
            </div>
          }
        </CardHeader>
        <CardFooter stats>
          <div onClick={refreshPage} style={{ cursor: "pointer" }} className={classes.stats}>
            <Danger>
              <Refresh />
            </Danger>
            {props.t("reload.1")}
          </div>
          <CardActions>
            <NavLink to={props.link} style={{ color: "#fff", textDecoration: "none" }}>
              <Button variant="contained" color="primary" style={{ marginTop: 20, height: 40, width: 120 }} > ຂໍຖອນເງິນ </Button>
            </NavLink>
          </CardActions>
        </CardFooter>
      </Card>
    </div>
  )
}