import React, {useState} from 'react';
import { Typography } from '@material-ui/core';
import Axios from "axios";
import { ExitToApp } from "@material-ui/icons";
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

export default function Logout(props) {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(false)
    }

    const handleLogout = () => {
        const options = {
            url: window.$aaa + "/logout",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8"
            }
        };
        Axios(options).then(response => {
            if(response.data.status){
                if(response.data.status === 1){
                    window.location = "/home";
                }else{
                    setOpen(true)
                }
            }else{
                setOpen(true)
            }
        });
    }

    return (
        <div style={{display: "flex"}} onClick={()=> {handleLogout(); props.popupState.close()}} >
            <ExitToApp style={{marginRight: "20", color: "red"}} />
            <Typography style={{color: "red"}} variant="h6">{props.t("logout.1")}</Typography>
            <AlertDialog open={open} handleClick={handleClick} text={props.t("logout.2")} button={props.t("ok.1")}/>
        </div>
    )
}
