import React, { useState, useEffect } from "react";
import Axios from 'axios';
import { Container, Grid, Typography } from "@material-ui/core";
import CardListRow from "bt_modules/CardListRow/CardListRow";
import Loading from "bt_modules/Loading";

export default function SearchPage(props) {
    const [coursesInfo, setCoursesInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(()=>{
        window.scrollTo(0, 0)
        let isMounted = true;
        const options = {
            url: window.$product + "/getCourseInfoByKeyword",
            method: "POST",
            withCredentials: true,
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "keyWord": props.match.params.keyWord,
                "language": props.i18n.language
            }
        };
        Axios(options).then(response => {
            if(isMounted){
                let courseInfo = []
                response.data.map(course => (
                    courseInfo.push({
                        "id": course.id,
                        "name": course.name,
                        "img": course.img_id,
                        "price": course.price,
                        "description": course.description,
                        "sellerName": course.firstname+" "+course.lastname,
                    })
                ));
                setCoursesInfo(courseInfo);
                setLoading(false);
            }
        })
        return () => { isMounted = false };
    },[props.match.params.keyWord, props.i18n.language]);

    return (
        <div>
            {!loading ? 
                <div style={{marginTop:'2%', marginBottom:'2%'}}>
                    <Container>
                        <Grid container direction='row'>
                            {coursesInfo.length > 0 ?
                            <Typography variant="h5" >
                                {coursesInfo.length} {props.t("result.1")} "{`${props.match.params.keyWord.substring(0, 40)}`}{props.match.params.keyWord.length > 40 && '...'}"
                            </Typography>
                            :
                            <Typography variant="h5" >
                                {props.t("result.2")}    "{`${props.match.params.keyWord.substring(0, 40)}`}{props.match.params.keyWord.length > 40 && '...'}"
                            </Typography>
                            }
                        </Grid><hr/>
                        <CardListRow cardsInfo={coursesInfo} cardLink="/course/" textLen={270} t={props.t}/>
                    </Container>
                </div>
                :
                <Loading/>
            }
        </div>
    )
}
