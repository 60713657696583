import React from 'react'
import {Grid, Typography, makeStyles, Container} from "@material-ui/core";
import AdminCourseCard from './AdminCourseCard'

const useStyles = makeStyles((theme)=>({
    root: {
        marginTop: '2%',
        [theme.breakpoints.down('md')]: {
            marginLeft: "15%",
        },
    },
    title: {
        marginBottom: '3%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
        paddingLeft: '2%'
    },
}));

export default function MyCourseListGrid(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container >
                <div className={classes.title}>
                    <Typography variant="h4"> {props.t("course.13")}</Typography>
                </div>
                <Grid>
                    <Grid spacing={3} container direction="row" alignItems="center">
                        {props.courseList.map(row => (
                            <Grid key={row.id} item xs={12} md={6} lg={3}>
                                <AdminCourseCard accessToken={props.accessToken} name={row.name} publisher={row.publisher} img_id={row.img_id} price={row.price} course_id={row.id} t={props.t} setCourseList={props.setCourseList} courseList={props.courseList} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
