import React from 'react';
import {Container, Typography, Grid, Avatar, makeStyles} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ShowMoreText from 'react-show-more-text';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        padding: '0.5%',
        paddingLeft: '3%', 
        fontWeight: 'lighter', 
        color: theme.palette.secondary.light
    },
    containesller: {
        backgroundColor: theme.palette.secondary.light, 
        //color: 'black', 
        marginTop: '8px',
        borderRadius: 8
    },
    iconColor: {
        color: theme.palette.primary.main,
        width: '20'
    },
    boxDescription: {
        backgroundColor: theme.palette.secondary.light, 
        width: '100%', 
        borderRadius: 5, 
        padding: '2%'
    },
    description: {
        textDecoration: 'none', 
        display:'inlineblock',
        color: theme.palette.primary.main,
    }

}));

export default function InstructorDetail(props) {
    const classes = useStyles();
    
    return (
        <div>
            <Container>
                <div className={classes.root}>
                    <Typography variant="h5">
                        {props.t("publisher.2")}
                    </Typography>
                </div><br/>
                <div className={classes.containesller}>
                
                    <div style={{marginLeft: '1%'}}><br/>
                        <Typography variant="h4">
                            {props.productSellerName}
                        </Typography>
                        <Grid container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            {props.productSellerImg &&
                            <Grid >
                                <Avatar src={window.$aaa + "/getUserImg/" + props.userImgToken + "/" + props.productSellerImg} style={{width: 150, height: 150}}/><br/>
                            </Grid>
                            }
                            <Grid  style={{padding: 20}}>
                                <Typography variant="caption" style={{display: 'flex'}}>
                                    <StarIcon className={classes.iconColor}/>&nbsp;&nbsp; 4.5 {props.t("star.1")}
                                </Typography>
                                <Typography variant="caption" style={{display: 'flex'}}>
                                    <LocalActivityIcon className={classes.iconColor}/>&nbsp;&nbsp; 127,317 {props.t("review.1")}
                                </Typography>
                                <Typography variant="caption" style={{display: 'flex'}}>
                                    <PeopleAltIcon className={classes.iconColor}/>&nbsp;&nbsp; 411,092 {props.t("student.1")}
                                </Typography>
                                <Typography variant="caption" style={{display: 'flex'}}>
                                    <PlayCircleFilledIcon className={classes.iconColor}/>&nbsp;&nbsp; 32 {props.t("course.3")}
                                </Typography>
                            </Grid><br/>
                        </Grid>

                        <ShowMoreText
                            lines={3}
                            more={<div className={classes.description}>Show more<ExpandMore style={{marginBottom: '-5'}}/></div>}
                            less={<div className={classes.description}>Show less<ExpandLess style={{marginBottom: '-5'}}/></div>}
                            className='content-css'
                            anchorClass='wrapper-class'
                            expanded={false}
                            style={{ textDecoration: 'none', display:'block'}}
                        >
                            <Typography variant="caption">
                                {ReactHtmlParser(props.productSellerBio)}
                            </Typography>
                        </ShowMoreText>
                        <br/>
                    </div>
                </div>
            </Container><br/><br/>
        </div>
    )
}