import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid, FormControl, InputLabel, FilledInput, InputAdornment, IconButton } from '@material-ui/core';
import Dialog from './AccountSettingDialog'
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) =>({
    root: {
        minWidth: 275,
        paddingTop: 10
    },
}));

export default function AccountSetting(props) {
    const classes = useStyles();
    const [editPassword, setEditPassword] = useState(false)

    return (
        <div>
        <Card className={classes.root}>
            <CardHeader
                style={{ textAlign: 'center'}}
                title={props.title}
            />
            <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item md={6} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel >{props.t("password.1")}</InputLabel>
                            <FilledInput
                                type='password'
                                value={123456}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    onClick={()=> setEditPassword(true)}
                                    edge="end"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            
        </Card>
        <Dialog 
            open={editPassword} 
            message={props.message} 
            setOpen={setEditPassword} 
            value={props.values} 
            onChange={props.onChange} 
            DialogContentText={props.t("edit.4")} 
            id={"password"} 
            handleEdit={props.handleEditPassword}
            isCurrentPasswordValidated={props.isCurrentPasswordValidated}
            isNewPasswordValidated={props.isNewPasswordValidated}
            isConfirmPasswordValidated={props.isConfirmPasswordValidated}
            loading={props.loading}
            t={props.t}
        />
        </div>
    );
}