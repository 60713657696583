import React, {useState, useEffect} from 'react'
import PopupWindowFromIcon from "bt_modules/Popup/PopupWindowFromIcon"
import Axios from 'axios'

export default function AddressBook(props) {

    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false)
    const [addrInfo, setAddrInfo] = useState({});
    const [defaultAddr, setdefaultAddr] = useState({})

    useEffect(() => {
        if(props.accessToken){
            let isMounted = true;
            const options = {
                url: window.$aaa + "/getBillAddr",
                method: "POST",
                headers:{
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
                },
                data:{
                    "token": props.accessToken
                }
            };
            Axios(options).then(response => {
                if (isMounted){
                    if(response.data.length > 0){
                        setAddrInfo({
                            addr: response.data[0].addr,
                            city: response.data[0].city,
                            phone: response.data[0].phone,
                            zip: response.data[0].zip,
                            state: response.data[0].state
                        });
                        setdefaultAddr({
                            addr: response.data[0].addr,
                            city: response.data[0].city,
                            phone: response.data[0].phone,
                            zip: response.data[0].zip,
                            state: response.data[0].state
                        })
                    }
                }
            })
            return () => { isMounted = false };
        }
    }, [props.accessToken])

    const handleUpdateAddress = () => {
        const options = {
            url: window.$aaa + "/updateBillAddr",
            method: "POST",
            headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
            },
            data:{
                token: props.accessToken,
                addr: addrInfo.addr,
                city: addrInfo.city,
                phone: addrInfo.phone,
                zip: addrInfo.zip,
                state: addrInfo.state
            }
        };
        Axios(options).then(res => {
            props.setAddrData({
                ...props.addrData,
                addr: addrInfo.addr,
                city: addrInfo.city,
                phone: addrInfo.phone,
                zip: addrInfo.zip,
                state: addrInfo.state
            })
            setdefaultAddr({
                addr: addrInfo.addr,
                city: addrInfo.city,
                phone: addrInfo.phone,
                zip: addrInfo.zip,
                state: addrInfo.state
            })
            setOpenEdit(false)
        })
    }

    return (
        <div>
            <PopupWindowFromIcon 
                defaultAddr={defaultAddr}
                addrInfo={addrInfo} 
                setAddrInfo={setAddrInfo}
                openEdit={openEdit} 
                setOpenEdit={setOpenEdit} 
                openAdd={openAdd}
                setOpenAdd={setOpenAdd}
                routeAdd="/addradd" 
                routeEdit="/addrEdit"
                handleUpdateAddress={handleUpdateAddress} 
                status={props.status}
                t={props.t}
            />
        </div>
    )
}
