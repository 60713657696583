import React, {useState, useEffect} from 'react';
import Axios from "axios";
import ManageProduct from 'bt_modules/ManageProduct/ManageProduct'
import Loading from 'bt_modules/Loading';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';

export default function CoursePage(props) {
    const [item, setItem] = useState([]);
    const [addNewSection, setAddNewSection] = useState([]);
    const [videoList, setVideoList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setItem([])
        setAddNewSection([]);
        setOpen(false);
        window.location = "/admin/approvecourse"
    }

    useEffect(() => {
        if(props.accessToken){
            let isMounted = true;
            const options = {
                url: window.$product + "/getAdminCourseInfo",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    "course_id": props.match.params.id,
                    "token": props.accessToken
                }
            };
            Axios(options).then(response => {
                if (isMounted){
                    if(response.data.courseInfo.length > 0){
                        setItem(response.data.courseInfo);
                    }
                    if(response.data.sectionInfo){
                        let arr = [...response.data.sectionInfo];
                        arr.map((data)=>{
                            data.openSec = false;
                            data.open = false;
                            data.openContentTypeVDO = false;
                            return data;
                        })
                        setAddNewSection(arr);
                    }
                    if(response.data.lessonsInfo){
                        setVideoList(response.data.lessonsInfo)
                    }
                    if(response.data.resourceInfo){
                        setFileList(response.data.resourceInfo)
                    }
                    setLoading(false);
                }          
            })
            return () => { isMounted = false };
        }
    }, [props.match.params.id, props.accessToken]);

    const handleSubmit = () => {
        const options = {
            url: window.$product + "/acceptCourse",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                "course_id": props.match.params.id,
                "token": props.accessToken
            }
        };
        Axios(options).then(response => {
            if(response.data.status){
                setOpen(true)
            }
        })
    }

    return (
        <div>
            {!loading ?
            <div>
                <ManageProduct 
                    item={item}
                    title={props.t("course.14")}
                    submitButton={props.t("accept.1")}
                    handleSubmit={handleSubmit}
                    addNewSection={addNewSection}
                    setAddNewSection={setAddNewSection}
                    videoList={videoList}
                    fileList={fileList}
                    t={props.t}
                />
                <AlertDialog open={open} handleClick={handleClick} text={props.t("success.1")} button={props.t("ok.1")}/>
            </div>
            :
            <Loading/>
            }
        </div>
    )
}
