import React from 'react';
import Popover from 'material-ui-popup-state/HoverPopover';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import Logout from "bt_modules/Authen/logout";
import { MenuItem, Avatar, Box, Typography, Divider, ListItemIcon } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ReactImageAppear from 'react-image-appear';
import CircleIcon from "bt_modules/CircleIcon/CircleIcon";
import { Person as PersonIcon, Favorite, ShopTwoOutlined, ShoppingCartOutlined, VideoCallOutlined, Settings} from '@material-ui/icons';
//import { History} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex',
    },
    img: {
        height: "100%",
        width: "100%"
    },
    lightFornt: {
        fontWeight: 'lighter'
    },
    textLink: {
        color: theme.palette.secondary.light,
        textDecoration: "none"
    },
    textLink2: {
        color: theme.palette.secondary.light,
        textDecoration: "none",
        minWidth: 150
    },
    bgcolor: {
        backgroundColor: theme.palette.secondary.main
    }
}));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        minWidth: 280,
        '&:focus': {
            backgroundColor: theme.palette.secondary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function ProfileMenu(props) {
    const classes = useStyles();

    return (
        <PopupState variant="popover" popupId="demoPopover">
        {popupState => (
            <div>
                <div className={classes.flex} {...bindHover(popupState)}> 
                    {props.userImg ?
                    <div>
                        <CircleIcon iconImg={<ReactImageAppear className={classes.img} src={window.$aaa + "/getUserImg/" + props.accessToken +'/'+ props.userImg} animation="zoomIn"/>} />
                    </div>
                    
                    :
                        <CircleIcon iconImg={<PersonIcon color="primary"/>} />
                    } 
                </div>
                <Popover {...bindPopover(popupState)} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} transformOrigin={{vertical: 'top', horizontal: 'center'}} disableRestoreFocus>
                    <div style={{ margin: 10, maxWidth: '100%', maxHeight: '50%'}}>
                        <NavLink onClick={()=> {popupState.close()}} to={"/profile"} className={classes.textLink2}>
                            <StyledMenuItem style={{maxHeight:100}}>
                                <ListItemIcon>
                                    {props.userImg ?
                                        <div>
                                            <Avatar alt="#" src={window.$aaa + "/getUserImg/"+ props.accessToken +'/'+ props.userImg} />
                                        </div>
                                        :
                                        <div>
                                            {props.userFirstname &&
                                                <Avatar>
                                                    {props.userFirstname.charAt(0)}
                                                </Avatar>
                                            }
                                        </div>
                                    }
                                </ListItemIcon>
                                <Box container="true"  alignItems="center" justifyContent="flex-start" direction="row">
                                    <Typography color="primary" gutterBottom variant="subtitle1">
                                        {props.userFirstname}
                                    </Typography>
                                    <Typography color="primary" gutterBottom variant="body2" className={classes.lightFornt}> 
                                        {props.userEmail}
                                    </Typography>
                                </Box>
                            </StyledMenuItem>
                        </NavLink>
                        <NavLink to={"/mycourse"}  className={classes.textLink} onClick={()=> {popupState.close()}}>
                            <StyledMenuItem>
                                <ShopTwoOutlined color="primary" style={{marginRight: "20"}} />
                                <Typography color="primary" variant="body2" className={classes.lightFornt}>{props.t("Mycourse.1")}</Typography>
                            </StyledMenuItem>
                        </NavLink>
                        <Divider />
                        <NavLink to={"/cart"}  className={classes.textLink} onClick={()=> {popupState.close()}}>
                            <StyledMenuItem>
                                <ShoppingCartOutlined color="primary" style={{marginRight: "20"}} />
                                <Typography color="primary" variant="body2" className={classes.lightFornt}>{props.t("cart.3")}</Typography>
                            </StyledMenuItem>
                        </NavLink>
                        <Divider />
                        <NavLink to={"/wish"}  className={classes.textLink} onClick={()=> {popupState.close()}}>
                            <StyledMenuItem style={{display: "flex"}}>
                                <Favorite color="primary" style={{marginRight: "20"}} />
                                <Typography color="primary" variant="body2" className={classes.lightFornt}>{props.t("wishlist.3")}</Typography>
                            </StyledMenuItem>
                        </NavLink>
                        <Divider />
                        <NavLink to={"/instructor"}  className={classes.textLink} onClick={()=> {popupState.close()}}>
                            <StyledMenuItem>
                                <VideoCallOutlined color="primary" style={{marginRight: "20"}} />
                                <Typography color="primary" variant="body2" className={classes.lightFornt}>{props.t("Instructor.1")}</Typography>
                            </StyledMenuItem>
                        </NavLink>
                        <Divider />
                        <NavLink to={"/settings"}  className={classes.textLink} onClick={()=> {popupState.close()}}>
                            <StyledMenuItem>
                                <Settings color="primary" style={{marginRight: "20"}} />
                                <Typography color="primary" variant="body2" className={classes.lightFornt}>{props.t("setting.1")}</Typography>
                            </StyledMenuItem>
                        </NavLink>
                        <Divider />
                        <StyledMenuItem variant="contained" color="primary" style={{ width: '85%', height: '50%' }} >
                            <Logout t={props.t} popupState={popupState} />
                        </StyledMenuItem>
                    </div>
                </Popover>
            </div>
        )}
    </PopupState>
    )
}


