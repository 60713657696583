import React from 'react';
import { Typography } from '@material-ui/core';
import Axios from "axios";
import { useHistory } from 'react-router-dom';
import { ExitToApp } from "@material-ui/icons";

export default function Logout(props) {
    const history = useHistory();

    const handleLogout = () => {
        const options = {
            url: window.$aaa + "/logout",
            method: "POST",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8"
            }
        };
        Axios(options).then(response => {
            if(response.data.status) {
                history.push('/home');
            }
        });
    }
    return (
        <div style={{display: "flex"}} onClick={()=> {handleLogout(); props.popupState.close()}}>
            <ExitToApp color="error" style={{marginRight: "20"}} />
            <Typography variant="body2" color="error" >{props.t("logout.1")}</Typography>
        </div>
    )
}
