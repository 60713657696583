import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core';
import { Route } from "react-router-dom";
import Axios from "axios";
import Dashboard from 'Apps/Admin/Dashboard/Dashboard'
import SiteBar from 'Apps/Admin/SiteBar/SiteBar'
import CoursePage from 'Apps/Admin/Course/CoursePage'
import ApproveCourse from 'Apps/Admin/ApproveCourse/ApproveCourse'
import { useTranslation } from 'react-i18next';
import AlertDialog from 'bt_modules/AlertDialog/AlertDialog';
import Loading from 'bt_modules/Loading';
import ApproveWithdraw from './ApproveWithdraw/ApproveWithdraw';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 768,
        padding: 40
    },
}));

export default function Admin() {
    const classes = useStyles();
    const [accessToken, setAccessToken] = useState("");
    const [userInfo, setUserInfo] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [wishList, setWishList] = useState([]);
    const { t, i18n } = useTranslation();
    const [authing, setAuthing] = useState(false);
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(false)
        window.location = "/home"
    } 

    useEffect(()=> {
        let isMounted = true;
        const options = {
        url: window.$aaa + "/refresh1",
        method: "POST",
        withCredentials: true,
        headers:{
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
        },
        };
        Axios(options).then(response => {
            if(isMounted){
                let lang = localStorage.getItem("lang")
                if(lang){
                    localStorage.setItem("lang", i18n.language)
                }
                if(response.data.status){
                    if(response.data.jid) {
                        setAccessToken(response.data.jid)
                    }
                    if(response.data.userInfo) {
                        setUserInfo(response.data.userInfo);
                        if(response.data.userInfo.status === 0){
                            setOpen(true)
                        }
                    }
                    setAuthing(true)
                }else{
                    setOpen(true)
                    setAuthing(false)
                }
            }
        })
        return () => { isMounted = false };
    }, [i18n]);

    if(authing){
        return (
            <div>
                {userInfo.status === 1 ?
                <div>
                    <SiteBar userInfo={userInfo} setUserInfo={setUserInfo} t={t} i18n={i18n}/>
                    <div className={classes.root}>
                        <Route path="/admin/approvewithdraw" exact component={()=> <ApproveWithdraw accessToken={accessToken} userInfo={userInfo} t={t}/>}/>
                        <Route path="/admin/course/:id" component={(props)=> <CoursePage accessToken={accessToken} cartData={cartData} setCartData={setCartData} wishList={wishList} setWishList={setWishList} t={t} {...props}/>} />
                        <Route path="/admin/approvecourse" exact component={()=> <ApproveCourse accessToken={accessToken} userInfo={userInfo} t={t}/>}/>
                        <Route path="/admin" exact component={()=> <Dashboard accessToken={accessToken} userInfo={userInfo} t={t}/>}/>
                    </div>
                </div>
                :
                <div>
                    <Loading/>
                    <AlertDialog open={open} handleClick={handleClick} text={t("admin.2")} button={t("ok.1")}/>
                </div>
                }
            </div>
        )
    }else{
        return (
            <div >
                <Loading/>
                <AlertDialog open={open} handleClick={handleClick} text={t("login.4")} button={t("ok.1")}/>
            </div>
        )
    }
}
